import jwt from "jsonwebtoken";
import * as types from "./actionTypes";
import API from "../../services/UserService";
import {JWT_PUBLIC_KEY} from "../../components/common/Const";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function signInSuccess(user) {
  return { type: types.SIGN_IN_SUCCESS, user };
}

export function signIn(userCredentials) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('signIn'));
    return API.signIn(userCredentials)
      .then((userLoggedIn) => {
        dispatch(signInSuccess(userLoggedIn));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function signOut() {
  localStorage.removeItem('token');
  return { type: types.SIGN_OUT };
}

export function checkToken() {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (token) {
      const secret = JWT_PUBLIC_KEY;
      jwt.verify(token, secret, {algorithms: ['RS256']}, (err, decoded) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.log('token error', err);
          dispatch(signOut());
        }

        if (decoded) {
          // console.log('token found and verified', decoded);
          dispatch(signInSuccess({id: decoded.id, role: decoded.roles}));
        }
      });
    }
  };
}
