import React from 'react';
import PropTypes from 'prop-types';
import Answer from './Answer';

const Question = ({question, answers, correctAnswer, onAnswerSelected, selectedAnswer, readOnly}) => (
  <li className="question">
    <h2 className="question-title">
      {question}
    </h2>
    <ul className="question-answers">
      {answers.map((answer, index) => {
        const isCorrect = !!selectedAnswer && selectedAnswer === index + 1 && selectedAnswer === correctAnswer;
        const isWrong = !!selectedAnswer && selectedAnswer === index + 1 && selectedAnswer !== correctAnswer;
        return (
          <Answer
            key={answer}
            answer={answer}
            answerIndex={index}
            isCorrect={isCorrect}
            isWrong={isWrong}
            onAnswerSelected={onAnswerSelected}
            readOnly={readOnly}
          />
        );
      })}
    </ul>
  </li>
);

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
  correctAnswer: PropTypes.number.isRequired,
  selectedAnswer: PropTypes.number,
  onAnswerSelected: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

Question.defaultProps = {
  selectedAnswer: null,
  readOnly: false
};


export default Question;
