import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import 'react-toastify/dist/ReactToastify.css';
import { history } from "../../redux/store/configureStore";
import ClientsList from "./ClientsList";
import * as clientActions from "../../redux/actions/clientActions";
import * as studentActions from "../../redux/actions/studentActions";
import * as settingsActions from "../../redux/actions/settingsActions";
import {filterArray, getStudentsByClientId} from "../common/Helpers"; 
import Checkbox from "../common/Checkbox";

function Clients({clients, loadClients, showArchivedClients, toggleClientList, students, loadStudents}) {
  const [search, setSearch] = useState('');
  const [clientsEx, setClientsEx] = useState([]);

  useEffect(() => {
    if (clients.length === 0) {
      loadClients()
        .catch((error) => {
          alert(`Loading clients failed${error}`);
        });
    }

    if (students.length === 0) {
      loadStudents()
        .catch((error) => {
          alert(`Loading students failed${error}`);
        });
    }
  }, []);

  useEffect(() => {
    setClientsEx(clients.map(client => ({...client, expanded: false, students: getStudentsByClientId(students, client.id)})))
  }, [clients, students]);

  const handleEdit = (client) => {
    history.push(`/manage/client/${client.id}`);
  };

  const handleExpand = (client) => {
    setClientsEx(clientsEx.map(c => (c.id === client.id ? {...client, expanded: !client.expanded} : c)));
  };

  const handleAdd = () => {
    history.push(`/manage/client/new`);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const filteredClients = filterArray(search, clientsEx);

  const handleArchivedClients = () => {
    toggleClientList();
  }

  return (
    <div className="mb-5">
      <h2 className="manage-title col-block mb-0">Lista klientów</h2>
      <div className="row d-flex align-items-center mx-0">
        <input name="search" className="form-control form-group col-md-5 col-8 pr-2" value={search} onChange={handleSearchChange} placeholder="szukaj"/>
        <div className="col-md-3 col-4 ">
          <Checkbox label="Archiwum" onChange={handleArchivedClients} name="showArchivedClients" isChecked={showArchivedClients} textPosition="rightOnLarge"/>
        </div>
        <div className="col-md-4 px-0">
          {!showArchivedClients && (
            <button
              type="button"
              className="btn manage-button btn-success btn-block form-group"
              onClick={handleAdd}
            >
              Dodaj nowego klienta
            </button>
          )}
        </div>
      </div>
        <ClientsList
          clients={filteredClients}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onExpand={handleExpand}
          showArchivedClients= {showArchivedClients}
        />
    </div>
  );
}


Clients.propTypes = {
  clients: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  loadStudents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  
  const showArchivedClients = state.settings.showArchivedClients
  const itemState = showArchivedClients
    ? state.clients.filter(client => client.archived && !client.deleted)
    : state.clients.filter(client => !client.archived && !client.deleted)

  return({
    clients: itemState,
    showArchivedClients,
    students: state.students,
  })
};

const mapDispatchToProps = dispatch => ({
  loadClients: () => dispatch(clientActions.loadClients()),
  loadStudents: () => dispatch(studentActions.loadStudents()),
  toggleClientList: () => dispatch(settingsActions.toggleClientList())
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
