import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupForm from "./SignupForm";
import { timeRanges, daysOfWeek } from "./SignupData";
import {history} from "../../redux/store/configureStore";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getInitialState() {
    return {
      formData: {
        email: "",
        name: "",
        surname: "",
        phone: "",
        childname: "",
        age: "",
        level: "",
        leveldesc: "",
        typei: false,
        type2: false,
        typeg: false,
        days: {},
        times: {},
        desc: "",
        permission: false,
      },
      errors: {},
      disabled: false,
    };
  }

  onChange(event) {
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    const { name, value } = event.target;

    if (daysOfWeek.includes(name)) {
      updatedFormData.days[name] = value;
    } else if (timeRanges.includes(name)) {
      updatedFormData.times[name] = value;
    } else {
      updatedFormData[name] = value;
    }

    this.setState({ formData: updatedFormData });
  }

  onSubmit(event) {
    event.preventDefault();
    const { formData } = this.state;

    if (!this.formIsValid()) {
      return;
    }

    const body = this.formatSignupEmail(formData);
    const data = {email: formData.email, body, subject: `Zapiszenie na zajęcia ${formData.name} ${formData.surname}`};

    axios({
      method: "post",
      url:
        "https://8uyipnjdk3.execute-api.eu-west-1.amazonaws.com/v1/contact-us",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data,
    })
      .then(() => {
        toast(
          `Wiadomość została wysłana.`
        );
        this.setState(this.getInitialState());
        setTimeout(() => {
          history.goBack();
        }, 5000);
      })
      .catch(error => toast.error(`Wystąpił błąd ${error}`));
  }

  formIsValid() {
    const errors = {};

    const {
      email,
      name,
      surname,
      phone,
      childname,
      level,
      leveldesc,
      typei,
      type2,
      typeg,
      desc,
      permission,
    } = this.state.formData;
    if (name == "") {
      errors.name = "Pole imię nie może być puste";
    }
    if (surname == "") {
      errors.surname = "Pole nazwisko nie może być puste";
    }
    if (childname == "") {
      errors.childname = "Pole Imię nie może być puste";
    }
    if (typei == false && type2 == false && typeg == false) {
      errors.typei = "Proszę wybrać przynajmniej jeden typ lekcji";
    }
    if (desc == "") {
      errors.desc = "Proszę dodać szczegóły dot. preferowanych godzin";
    }
    if (level == "") {
      errors.level = "Należy wybrać jedną z opcji";
    } else if (level == "Tak" && leveldesc == "") {
      errors.leveldesc = "Proszę dodać krótki opis poziomu ucznia";
    }
    if (permission == false) {
      errors.permission =
        "Nie można zapisać się bez zgody na przetwarzanie danych";
    }
    if (phone === "") {
      errors.phone = "Numer telefonu nie może być pusty";
    } else if (
      !phone.match(/^[+]?[(]?[0-9]{2,3}[)]?[-\s.]*[0-9]{3}[-\s.]*[0-9]{3}[-\s.]*[0-9]{0,3}[-\s.]*$/)
    ) {
      errors.phone = "Numer telefonu jest nieprawidłowy";
    }
    if (email == null || email === "") {
      errors.email = "Adres email nie może być pusty.";
    } else if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      errors.email = "Nieprawidłowy adres email.";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  formatSignupEmail() {
    const { formData } = this.state;
    const {email, name, surname, phone, childname, age, leveldesc, level, typei, type2, typeg, desc, permission, days, times} = formData;
    const types = {
      indywidualne: typei,
      dwuosobowe: type2,
      grupowe: typeg,
    };
    const toList = obj => Object.entries(obj).filter(([, value]) => value === true).map(([key]) => key).join(", ");
    return `<p>Klient <b>${name} ${surname}</b> zgłosił chęć zapisania na zajęcia ucznia <b>${childname}</b></p>
    <p>Wiek ucznia: <b>${age}</b></p>
    <p>Wcześniejszy kontakt z językiem anglielskim: <b>${level} ${leveldesc}</b></p>
    <p>Telefon kontaktowy: <b>${phone}</b></p>
    <p>Email: <b>${email}</b></p>
    <p>Preferowany typ lekcji <b>${toList(types)}</b></p>
    <p>Preferowane dni: <b>${toList(days)}</b></p>
    <p>Preferowane godziny: <b>${toList(times)}</b></p>
    <p>Dostępność: <b>${desc}</b></p>
    <p>Zgoda na przetwarzanie danych osobowych <b>${permission ? "TAK" : "NIE"}</b></p>`;
  }

  render() {
    const { formData, errors, disabled } = this.state;
    return (
      <div className="pt-4 pr-4 pl-4 pr-lg-2 pl-lg-4">
        <SignupForm
          formData={formData}
          errors={errors}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default Signup;
