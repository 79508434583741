/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import {calculateDisplayedErrors} from "../common/Helpers";

const LoginForm = ({username, password, touched, errors, onBlur, onChange, onSubmit, onCancel, disabled, status}) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  return (
    <div className="quiz-text">
      <h2><strong>Logowanie</strong></h2>
      <form id="client-form" onSubmit={onSubmit}>

        <div className="form-row">
          <div className="col-md-9 col-lg-7">
            <Input label="Użytkownik (email)" onBlur={onBlur} onChange={onChange} name="username" value={username} error={err.username} placeholder="Użytkownik" />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-9 col-lg-7">
            <Input label="Hasło" type="password" onBlur={onBlur} onChange={onChange} name="password" value={password} error={err.password} placeholder="Hasło" />
          </div>
        </div>


        <div className="row">
          <div className="col-md-2 mb-2" >
            <button type="submit" className="btn manage-button manage-red-button btn-block" disabled={disabled}>Login</button>
          </div>
          <div className="col-md-2" >
            <button type="button" className="btn manage-button manage-teal-button btn-block" onClick={onCancel} disabled={disabled}>Anuluj</button>
          </div>
        </div>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  username:  PropTypes.string,
  password: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

LoginForm.defaultProps = {
  username: "",
  password: "",
  disabled: false,
};

export default LoginForm;
