/* eslint-disable react/prefer-stateless-function */
import React from "react";
import ContactPage from "./ContactPage";



const ContactSection = () => {
  return (
    <>
      <div className="contact">
        <div className="bottom-wave"></div>
        <ContactPage />
      </div>
    </>
  )
}

export default ContactSection;
