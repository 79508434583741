import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import { history } from "../../redux/store/configureStore";
import SchoolClassesList from "./SchoolClassesList";
import * as schoolClassActions from "../../redux/actions/schoolClassActions";
import * as teacherActions from "../../redux/actions/teacherActions";
import {filterArray, getById, minutesToTimeString, timeStringToMinutes} from "../common/Helpers";
import {CLASS_TYPES, WEEKDAYS} from "../common/Const";
import SchoolClassesFilterForm from "./SchoolClassesFilterForm";
import * as settingsActions from "../../redux/actions/settingsActions";


function SchoolClasses({ formattedClasses, schoolClasses, teachers, loadSchoolClasses, loadTeachers, filters, updateFilters, ...props }) {

  useEffect(() => {
    if (schoolClasses.length === 0) {
      loadSchoolClasses()
        .catch((error) => {
          alert(`Loading schoolClasses failed ${error}`);
        });
    }

    if (teachers.length === 0) {
      loadTeachers()
        .catch((error) => {
          alert(`Loading teachers failed ${error}`);
        });
    }
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    const newFilters = { ...filters, [name]: value };
    updateFilters(newFilters);
  };
  
  const handleBooleanFilterChange = (filterName) => {
    const newFilters = { ...filters, [filterName]: !filters[filterName] };
    updateFilters(newFilters);
  };
  
  const clearFilters = () => {
    const newFilters = {
      search: "",
      dayOfWeek: "",
      classTypeId: "",
      startTime: "",
      endTime: "",
      teacherId: "",
      showOnlyNotRepeating: false,
      showOnlyRepeating: false,
      fromDate: "",
      tillDate: "",
      comment: false,
    };
    updateFilters(newFilters);
  };
  
  const filteredClasses = filterArray(filters.search, formattedClasses).filter((schoolClass) => {
    const fromDate = new Date(schoolClass.fromDate);
    const tillDate = new Date(schoolClass.tillDate);
    const filterFromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    const filterTillDate = filters.tillDate ? new Date(filters.tillDate) : null;
    const startTime = schoolClass.startTime ? timeStringToMinutes(schoolClass.startTime) : null;
    const classType = getById(CLASS_TYPES, schoolClass.classTypeId);
    const endTime = classType && startTime ? startTime + classType.length : null;
  
    return (
      // Filter by isRecurring
      ((!filters.showOnlyRepeating && !filters.showOnlyNotRepeating) ||
        (filters.showOnlyRepeating !== filters.showOnlyNotRepeating && (
          (filters.showOnlyRepeating && schoolClass.isRecurring) ||
          (filters.showOnlyNotRepeating && !schoolClass.isRecurring)
        ))) &&
      // Filter by fromDate and tillDate
      (!filterFromDate || fromDate >= filterFromDate) &&
      (!filterTillDate || tillDate <= filterTillDate) &&
      // Filter by dayOfWeek
      (filters.dayOfWeek === '' || schoolClass.dayOfWeek === filters.dayOfWeek) &&
      // Filter by classTypeId
      (filters.classTypeId === '' || schoolClass.classTypeId === filters.classTypeId) &&
      // Filter by teacherId
      (filters.teacherId === '' || schoolClass.teacherId === filters.teacherId) &&
      // // Filter by startTime
      (filters.startTime === '' || (startTime && timeStringToMinutes(filters.startTime) <= startTime)) &&
      // // Filter by endTime
      (filters.endTime === '' || (endTime && timeStringToMinutes(filters.endTime) >= endTime)) &&
      // Filter by comment
      (!filters.comment || schoolClass.comment)
    );
  });
  

  const handleEditClass = (schoolClass) => {
    history.push(`/manage/class/${schoolClass.id}`);
  };

  const handleAddNewClass = () => {
    history.push(`/manage/class/new`);
  };

  return (
    <div className="mb-5">
      <h2 className="manage-title">Zajęcia</h2>

      <div className="row d-flex align-items-center mx-0">
        <input name="search" className="form-control form-group col-8" value={filters.search} onChange={handleFilterChange} placeholder="szukaj"/>

        <div className="col-4 col-md-2 col-xl-1 form-group justify-content-center d-flex">
          <SchoolClassesFilterForm 
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
            handleBooleanFilterChange={handleBooleanFilterChange}
            teachers={teachers}
            weekdays={WEEKDAYS}
            classTypes={CLASS_TYPES}
          />
        </div>
        <button
            type="button"
            className="btn manage-button btn-success col-md-2 col-xl-3 btn-block form-group"
            onClick={handleAddNewClass}
        >
          Nowe zajęcia
        </button>
      </div>

      <SchoolClassesList
        schoolClasses={filteredClasses}
        onAdd={handleAddNewClass}
        onEdit={handleEditClass}
      />
    </div>
  );
}

SchoolClasses.propTypes = {
  schoolClasses: PropTypes.array.isRequired,
  loadSchoolClasses: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  loadTeachers: PropTypes.func.isRequired,
  formattedClasses: PropTypes.array.isRequired,  
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

function formatSchoolClass(schoolClass, teachers) {
  const classType = getById(CLASS_TYPES, schoolClass.classTypeId);
  const dayOfWeek = getById(WEEKDAYS, schoolClass.dayOfWeek);
  const teacher = getById(teachers, schoolClass.teacherId);
  const startTime = timeStringToMinutes(schoolClass.startTime);
  const endTime = classType ? startTime + classType.length : null;
  const when = dayOfWeek && startTime && endTime ? `${dayOfWeek.name} ${minutesToTimeString(startTime)}-${minutesToTimeString(endTime)}` : '';
  const dateRange = schoolClass.tillDate ? `${schoolClass.fromDate} do ${schoolClass.tillDate}` : schoolClass.fromDate;
  const teacherName = teacher ? `${teacher.firstName} ${teacher.lastName}` : '';
  return {...schoolClass, classType: classType.name, when, dateRange, teacher: teacherName};
}

const mapStateToProps = state => ({
  schoolClasses: state.schoolClasses,
  teachers: state.teachers,
  formattedClasses: state.schoolClasses.map(schClass => formatSchoolClass(schClass, state.teachers)),
  filters: state.settings.schoolClassesFilters,
});

const mapDispatchToProps = dispatch => ({
  loadSchoolClasses: () => dispatch(schoolClassActions.loadSchoolClasses()),
  loadTeachers: () => dispatch(teacherActions.loadTeachers()),
  updateFilters: (filters) => dispatch(settingsActions.updateSchoolClassesFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolClasses);
