/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import PropTypes from "prop-types";
import "../styles/styles.scss";
import {halloweenGroup, africaGroup, eaglesGroup, summerCamp2021Group} from "../data/GalleryArrays";
import seo_keywords from "../data/seo";

class GalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLightbox: false,
      lightboxIndex: 0,
      imageArray: []
    };
    this.toggleLightbox = this.toggleLightbox.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.toggleLightbox, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.toggleLightbox, false);
  }

  generateImageArray = (array) => {
    const newArray = [];
    for (let i = 0; i < array.content.length; i++) {
      for (let j = 0; j < array.content[i].imageSetContent.length; j++) {
        const image = array.content[i].imageSetContent[j];
        if (image.vidUrl) {
          newArray.push({url: `${image.vidUrl}`, type: `${image.type}`, altTag: `${image.altTag}`});
        } else {
          newArray.push({url: `${image.url}`, type: `${image.type}`, altTag: `${image.altTag}`});
        }
      }
    }
    this.setState({imageArray: newArray});
  }

  toggleLightbox = (event, contentGroup) => {
    const {showLightbox} = this.state;
    const {toggleHeader} = this.props;
    // this.generateImageArray(halloweenGroup);

    if (!showLightbox && (!!event.keyCode == false)) {
      const imageIndex = event.currentTarget.dataset.img_id - 1;
      this.setState({lightboxIndex: imageIndex, showLightbox: true});
      this.generateImageArray(contentGroup);
      toggleHeader();
    } else if (showLightbox && (!!event.keyCode == false || event.keyCode == 27)) {
      this.setState({showLightbox: false});
      toggleHeader();
    }
  }

  render() {
    const {showLightbox, lightboxIndex, imageArray} = this.state;

    return (
      <div id="parent" className="blurred-background">
        {!showLightbox && (
          <div className="container-fluid">
            <div className="pt-3 row content">
              <div className="col-xl-2"/>
              <div className="col-xl-8" style={{textAlign: "justify"}}>

                <h2>Półkolonie z angielskim Lipiec 2021 </h2>
                <div id="image-gallery" role="figure">
                  <div className="row">
                    {
                      summerCamp2021Group.content.map(content => (
                        <div
                          className="column"
                          key={`${content.imageIndexSet}-set`}
                        >
                          {content.imageSetContent.map(imageSetContent => (
                            <img
                              src={`${(imageSetContent.url)}`}
                              className="img-fluid"
                              key={imageSetContent.imageIndex}
                              data-img_id={imageSetContent.imageIndex}
                              onClick={e => this.toggleLightbox(e, summerCamp2021Group)}
                                                          alt={seo_keywords}
                            />

                          ))}
                        </div>
                      ))
                    }
                  </div>
                </div>

                <h2>Nasza szkoła zdobyła wyróżnienie w branżowym plebiscycie <a href="https://www.orlyedukacji.pl/profile-327052-extra-english" target="_blank">Orły Edukacji</a> </h2>
                <div id="image-gallery" role="figure">
                  <div className="row">
                    {
                      eaglesGroup.content.map(content => (
                        <div
                          className="column"
                          key={`${content.imageIndexSet}-set`}
                        >
                          {content.imageSetContent.map(imageSetContent => (
                            <img
                              src={`${(imageSetContent.url)}`}
                              className="img-fluid"
                              key={imageSetContent.imageIndex}
                              data-img_id={imageSetContent.imageIndex}
                              onClick={e => this.toggleLightbox(e, eaglesGroup)}
                                                          alt={seo_keywords}
                            />

                          ))}
                        </div>
                      ))
                    }
                  </div>
                </div>


                <h2>Warsztaty z dynią</h2>
                <div id="image-gallery" role="figure">
                  <div className="row">
                    {
                      halloweenGroup.content.map(content => (
                        <div
                          className="column"
                          key={`${content.imageIndexSet}-set`}
                        >
                          {content.imageSetContent.map(imageSetContent => (
                            <img
                              src={`${(imageSetContent.url)}`}
                              className="img-fluid"
                              key={imageSetContent.imageIndex}
                              data-img_id={imageSetContent.imageIndex}
                              onClick={e => this.toggleLightbox(e, halloweenGroup)}
                                                          alt={seo_keywords}
                            />

                          ))}
                        </div>
                      ))
                    }
                  </div>
                </div>
                <h2>Handa's surprise</h2>
                <div id="image-gallery" role="figure">
                  <div className="row">
                    {
                      africaGroup.content.map(content => (
                        <div
                          className="column"
                          key={`${content.imageIndexSet}-set`}
                        >
                          {content.imageSetContent.map(imageSetContent => (
                            <img
                              src={`${(imageSetContent.url)}`}
                              className="img-fluid"
                              key={imageSetContent.imageIndex}
                              data-img_id={imageSetContent.imageIndex}
                              onClick={e => this.toggleLightbox(e, africaGroup)}
                                                          alt={seo_keywords}
                            />
                          ))}
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl-2"/>
            </div>
          </div>
        )
        }{
        showLightbox && (
          <ReactImageVideoLightbox
            data={imageArray}
            startIndex={lightboxIndex}
            showResourceCount
            onCloseCallback={this.toggleLightbox}
          />
        )
      }
      </div>

    );
  }
}

GalleryPage.propTypes = {
  toggleHeader: PropTypes.func.isRequired
};

export default GalleryPage;
