/* eslint-disable react/prefer-stateless-function */
import React from "react";
import CookieConsent from "react-cookie-consent";
import PropTypes from 'prop-types';

class PrivacyPopup extends React.Component {
  render() {
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="wasRead"
          style={{ background: "#c91f27" }}
          buttonStyle={{ color: "#fff", backgroundColor: "#007bff", borderColor: "#007bff", fontSize: "1rem", padding: "0.375rem 0.75rem", borderRadius: "0.25rem" }}
          expires={150}
        >
          Polityka prywatności opisuje zasady przetwarzania przez nas informacji na twój temat. Więcej informacji znajdziesz <a href="#" onClick={this.props.showPrivacyModal}> tutaj</a>
        </CookieConsent>
      </div>
    );
  }
}


export default PrivacyPopup;


PrivacyPopup.propTypes = {
  showPrivacyModal: PropTypes.func.isRequired
};
