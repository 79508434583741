/* eslint-disable react/prefer-stateless-function */

import React, { useState, useEffect } from "react";
import "../../styles/homepage.css";
import "../../styles/styles.scss";
import HomePageBackground from "./HomePageBackground";
import HeaderNav from "../HeaderNav";

import SchoolSection from "./schoolComponents/SchoolSection";
import OfferSection from "./offerComponents/OfferSection";
import ContactSection from "./contactComponents/ContactSection";

import { useLocation } from "react-router-dom";

const HomePage = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  function handleCheckForNavbar() {
    if (window.innerWidth >= 992) {
      setShowNavbar(window.scrollY > 300);
    } else {
      setShowNavbar(true);
    }
  }

  useEffect(() => {
    handleCheckForNavbar();
    window.addEventListener("scroll", handleCheckForNavbar);
    window.addEventListener("resize", handleCheckForNavbar);
    return () => {
      window.removeEventListener("scroll", handleCheckForNavbar);
      window.removeEventListener("resize", handleCheckForNavbar);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        document
          .getElementById(location.hash.substring(1))
          .scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 50);
  }, [location]);

  return (
    <>
      <div
        className={`navbar p-0 ${showNavbar ? "show-navbar" : "hide-navbar"}`}
      >
        <HeaderNav />
      </div>
      <div id="homepage-background">
        <a id="homepage" className="homepage-anchor"></a>
        <HomePageBackground />
        <div id="HomepageComponentsTrigger">
          <a id="SchoolPage" className="school-anchor"></a>
          <SchoolSection />
          <a id="OfferPage" className="offer-anchor"></a>
          <OfferSection />
          <a id="ContactPage" className="contact-anchor"></a>
          <ContactSection />
        </div>
      </div>
    </>
  );
};

export default HomePage;
