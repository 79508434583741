/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from "react";
import "react-bootstrap";
import Signup from "./Signup";

const SignupPage = () => (
  <div className="signup ">
    <div className="signup-page">
      <div className="row p-0 m-0 d-flex justify-content-center align-items-center">
        <div className="col-lg-1" />
        <div className="col-lg-10">
          <Signup />
        </div>
        <div className="col-lg-1" />
      </div>
    </div>
  </div>
);

export default SignupPage;
