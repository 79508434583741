import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import TeacherForm from "./TeacherForm";
import {STATUS} from "../common/Const";
import { history } from "../../redux/store/configureStore";
import * as teacherActions from "../../redux/actions/teacherActions";
import {validatePerson} from "../common/Validations";
import {hasErrors, hasNoErrors} from "../common/Helpers";

function Teacher({teachers, loadTeachers, saveTeacher, ...props}) {
  const [teacher, setTeacher] = useState({ ...props.teacher });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    if (teachers.length === 0) {
      loadTeachers().catch((error) => {
        alert(`Loading teachers failed ${error}`);
      });
    } else {
      setTeacher({...props.teacher});
    }
  }, [props.teacher]);

  const validate = () => {
    // eslint-disable-next-line no-shadow
    const errors = validatePerson(teacher);
    setErrors(errors);
    return hasNoErrors(errors);
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTeacher(prevTeacher => ({...prevTeacher, [name]: value,}));
    validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    const isValid = validate();

    if (isValid) {
      saveTeacher(teacher)
        .then(() => {
          setStatus(STATUS.COMPLETED);
          history.goBack();
        })
        .catch((error) => {
          setErrors({...errors, saveError: error.message});
          setStatus(STATUS.SUBMITTED);
        });
    } else {
      setStatus(STATUS.SUBMITTED);
    }
  };

  return (
    <div className="mb-5">
      { hasErrors(errors) && status === STATUS.SUBMITTED && (
        <div role="alert" className="alert alert-danger">
            {Object.keys(errors).map(key => <div key={key}>{errors[key]}</div>)}
        </div>
      )}
      <TeacherForm
        firstName={teacher.firstName}
        lastName={teacher.lastName}
        email={teacher.email}
        mobile={teacher.mobile}
        touched={touched}
        errors={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        status={status}
      />
    </div>
  );
}

Teacher.propTypes = {
  teachers: PropTypes.array.isRequired,
  teacher: PropTypes.object.isRequired,
  loadTeachers: PropTypes.func.isRequired,
  saveTeacher: PropTypes.func.isRequired,
};

const newTeacher = {};

const getTeacherById = (teachers, id) => teachers.find(teacher => teacher.id === id) || null;

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  const teacher =
    id === "new" || state.teachers.length === 0
      ? newTeacher
      : getTeacherById(state.teachers, id);

  return {
    teacher,
    teachers: state.teachers,
  };
};

const mapDispatchToProps = dispatch => ({
  loadTeachers: () => dispatch(teacherActions.loadTeachers()),
  saveTeacher: teacher => dispatch(teacherActions.saveTeacher(teacher)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teacher);
