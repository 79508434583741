/* eslint-disable max-len */

const OfferDataArray = [
  {
    id: 0,
    name: "3 - 5 lat",
    imgURL: "",
    groupDescription: `Zajęcia prowadzone są w oparciu o total immersion - całkowite zanurzenie - lekcje odbywają się 
    wyłącznie po angielsku. Uczymy się żywego języka w kontekście sytuacyjnym poprzez komunikaty, powtarzanie prostych 
    zwrotów językowych, piosenki, bajki, gry i zabawy.

    Język polski stosowany jest przez nauczyciela jedynie w sytuacjach wyjątkowych. W zajęciach tej grupy wiekowej wykorzystywany 
    jest kukiełka – Norton - przedstawiająca ptaszka-tukana. Norton to jedna z postaci występujących w historyjkach 'Incredible English' 
    i stanowi łącznik między klasą a światem fantazji. Jego główną rolą jest pomoc we wprowadzaniu oraz utrwalaniu nowego słownictwa.

    Norton ‘mówi’ wyłącznie po angielsku, więc żeby się z nim skomunikować dzieci naturalnie wybierają ten język. Kukiełka ma 
    także dostęp do ‘kit box’ – pudła z materiałami na lekcję, które jest przechowywane w klasie. Przedstawia cele nauki i prezentuje 
    materiały przygotowane w danym dniu.
    `
  },
  {
    id: 1,
    name: "6 -7 lat",
    imgURL: "",
    groupDescription: `Moment, w którym dzieci są gotowe do podjęcia nauki w szkole, jest szczególnie ważny, ponieważ 
    w tym wieku koncentracja jest lepsza, a dziecko potrafi w pełni zaangażować się w zajęcia. Celem lekcji jest rozwijanie 
    słownictwa i pewności siebie w spontanicznym używaniu języka oraz samodzielnej budowie zdań. Dzieci w tym wieku są bardzo 
    aktywne, ich umysły są chłonne, a więzi rówieśnicze niezwykle ważne.

    W zajęciach wykorzystujemy interaktywne i unikatowe książki wydawnictwa Oxford oraz wiele materiałów ze szkół brytyjskich. 
    Ciekawe quizy i motywujące gry wzmagają radość uczenia się i są doskonałym motywatorem, umożliwiającym równomierne rozwijanie 
    umiejętności komunikacyjnych oraz czytania i pisania.

    Oceny postępów uczniów są prowadzone poprzez obserwację uczestnictwa na zajęciach. Dodatkowo, 2 razy w roku organizowane są 
    testy w formie konkursu.`
  },
  {
    id: 2,
    name: "8 - 9 lat",
    imgURL: "",
    groupDescription: `Poziom A1. Zajęcia mają charakter ogólny, skupiają się na doskonaleniu umiejętności spontanicznego 
    budowania zdań, rozbudowie słownictwa oraz poprawnej wymowie. Nacisk kładziony jest również na doskonalenie czytania i 
    pisania. Istnieje możliwość przygotowania do egzaminów językowych, takich jak Cambridge Young Learners English (Movers, 
    Flyers, Key, Preliminary for schools) oraz konkursów przedmiotowych (patrz też grupy egzaminacyjne).

    W trakcie zajęć uczestnik poznaje około 1000 słów rocznie. Ważnym elementem wzbogacającym zajęcia są krótkie przedstawienia 
    i role-play, czyli scenki teatralne, które pozwalają uczniowi rozwinąć ekspresję oraz swój potencjał aktorski.`
  },

  {
    id: 3,
    name: "10 - 13 lat",
    imgURL: "",
    groupDescription: `Poziom A2-B1. Kurs jest kontynuacją poprzedniego poziomu dla młodszych dzieci, ale może też do 
    niego dołączyć nowy uczeń, o ile jego kompetencje językowe na to pozwalają. Staramy się, aby w konkretnej grupie byli 
    uczestnicy w podobnym wieku i o podobnych umiejętnościach. W czasie zajęć mówimy wyłącznie po angielsku, doskonalimy 
    umiejętności komunikacyjne w zakresie general English, utrwalamy słownictwo i ćwiczymy gramatykę w praktyce.

    Na tym poziomie wprowadzone jest też wiele związków frazeologicznych, idiomów i przysłów - średnio 1400 nowych słów w ciągu roku. 
    Małe grupy, zazwyczaj 4-osobowe, pozwalają na dobre tempo zajęć i sprawiają, że nauczanie jest bardzo efektywne. Ważne jest, że 
    lektor ma również czas na ocenę postępów uczniów (continous assessment) i realizację oryginalnych projektów dostosowanych do 
    potrzeb i zainteresowań grupy.`
  },
  {
    id: 4,
    name: "14 - 16 lat",
    imgURL: "",
    groupDescription: `Poziom B1-B2 nauki ma na celu równomierny rozwój wszystkich kwalifikacji językowych z naciskiem 
    na mówienie, rozumienie ze słuchu, czytanie ze zrozumieniem i oczywiście pisanie. Celem kursu jest rozwój autonomii i 
    niezależności w posługiwaniu się drugim językiem. Nabycie pewności siebie w wyrażaniu ocen i formułowaniu opinii jest 
    kolejnym krokiem do niezależności w środowisku anglojęzycznym.

    Rozwój umiejętności interpersonalnych następuje poprzez pracę w grupie, ćwiczenie reagowania w sytuacjach kryzysowych 
    oraz odgrywanie scenek sytuacyjnych. Zajęcia pomagają w opracowaniu indywidualnej strategii nauki poprzez powtórzenia, 
    prace pisemne i zadania domowe.

    W monitorowaniu postępów wykorzystywane są testy Cambridge oraz SATs. Te ostatnie to oryginalne materiały opracowane dla 
    uczniów anglojęzycznych i wykorzystywane we wszystkich szkołach w Wielkiej Brytanii.`
  },
  {
    id: 5,
    name: "Dla dorosłych",
    imgURL: "",
    groupDescription: `Dorośli - Small Talk to program dostosowany do indywidualnych potrzeb i zainteresowań uczestników. 
    Minimalna liczba osób w grupie to 4, ale istnieje także możliwość zajęć indywidualnych. Często zajęcia są związane z podróżami 
    i językiem przydatnym do komunikacji zagranicą, takim jak słownictwo związane z hotelem, restauracją, lekarzem, zakupami, jedzeniem 
    i zakwaterowaniem. Program opiera się na prowadzeniu prostych konwersacji w oparciu o wprowadzone słownictwo oraz odgrywaniu scenek 
    sytuacyjnych, przy minimalnym poziomie gramatyki.

    Dużym powodzeniem cieszą się piosenki zespołów anglojęzycznych, które są wykorzystywane w ćwiczeniach, gry karciane typu Taboo, 
    shopping list i wiele innych. Innym wariantem zajęć są te związane z rozwojem kariery zawodowej, w tym pomoc w pisaniu angielskiego 
    życiorysu, listów motywacyjnych, ubieganiu się o pracę oraz opanowanie branżowego słownictwa z dziedzin takich jak prawo, finanse, 
    medycyna i budownictwo.`
  },
  {
    id: 6,
    name: "Egzaminacyjna",
    imgURL: "",
    groupDescription: `Szkoła ma możliwość przygotowania uczniów do egzaminów Cambridge oraz przeprowadzania próbnych testów 
    oceniających gotowość kandydatów. Przygotowujemy do wszystkich poziomów egzaminów (KET, PET, FCE, CAE, PCE) oraz pomagamy w 
    wypełnianiu aplikacji zgłaszających się do testów.

    Rekomendujemy testy Cambridge-Young Learners i English for Schools. Dwa razy w roku zgłaszamy chętnych uczniów do konkursów 
    Panda i Pingwin na poziomie szkoły podstawowej. Konkursy te przeprowadzane są w naszej szkole, a każdy uczestnik otrzymuje dyplom z wynikiem.

    W naszej szkole istnieje również możliwość przygotowania uczniów do egzaminów 8-klasisty i egzaminu maturalnego zgodnie z 
    aktualnymi wymaganiami egzaminacyjnymi. Oferujemy repetytoria i testy z lat ubiegłych, a także wykorzystujemy nowoczesne metody 
    dydaktyczne i tablice interaktywne.`
  },
  {
    id: 7,
    name: "Storytime",
    imgURL: "",
    groupDescription: `Story time to czas, który spędzamy z dziećmi i bajką. Opowieść jest zawsze połączona z książką, 
    muzyką i pięknymi ilustracjami oraz mini-teatrzykiem. Wszystkie nasze bajki mają w komplecie torby pełne niespodzianek, 
    nazywane story sacks, które zawierają tematycznie powiązane postacie, zwierzęta, owoce, warzywa itp.

    Zajęcia są przeznaczone dla dzieci w wieku od 6 do 10 lat, a podczas dwugodzinnych spotkań dominującym językiem jest angielski. 
    Total immersion - całkowite zanurzenie w języku, oraz atmosfera twórczej zabawy sprzyjają nauce, nawet w piątkowy wieczór. Zajęcia 
    będą odbywać się cyklicznie, raz lub dwa razy w miesiącu. Maksymalna liczba uczestników to 8.`
  },
  {
    id: 8,
    name: "Chillout",
    imgURL: "",
    groupDescription: `Chillout to po angielsku relaks. Taki właśnie charakter mają nasze zajęcia z najmłodszymi dziećmi.

    Spotkanie trwa godzinę zegarową, a opiekun zostaje na zajęciach, uczestnicząc aktywnie razem z dzieckiem. 
    Jest to doskonała szansa, żeby pogłębić więź i budować dobrą wzajemną relację.

    W programie wykorzystujemy elementy baby jogi, zabaw paluszkowych, dużo muzyki i piosenek, oczywiście wszystko 
    po angielsku. Podstawę zajęć stanowią tradycyjne nursery rhymes, na których wychowało się już miliony native speakerów. 
    Naturalny kontakt z językiem poprzez rymowanki, wyliczanki i łatwo wpadające w ucho piosenki pozwala na osłuchanie się z 
    językiem w ten sam sposób, jak robią to rówieśnicy w krajach anglojęzycznych.

    W czasie zajęć dzieci mają możliwość swobodnego doświadczania otaczającej przestrzeni. Zazwyczaj przygotowane są trzy lub 
    cztery stacje z pomocami dydaktycznymi dostosowanymi do wieku i tematu. Pracujemy nad stymulacją wszystkich zmysłów, więc 
    używamy instrumentów, zapachu, dotyku, koloru i smaku.

    Kończymy zajęcia krótką bajeczką, wykorzystując kukiełki i ilustracje. Dodatkową korzyścią programu jest nawiązywanie 
    pierwszych relacji rówieśniczych, a dla dorosłych możliwość odświeżenia własnych umiejętności językowych.`
  },
]
  


export default OfferDataArray;
