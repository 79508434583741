import React, {useEffect} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import { history } from "../../redux/store/configureStore";
import BooksList from "./BooksList";
import * as bookActions from "../../redux/actions/bookActions";

function Books({ books, loadBooks, ...props }) {

  useEffect(() => {
    if (books.length === 0) {
      loadBooks()
        .catch((error) => {
          alert(`Loading books failed ${error}`);
        });
    }
  }, []);


  const handleEdit = (book) => {
    history.push(`/manage/book/${book.id}`);
  };

  const handleAdd = () => {
    history.push(`/manage/book/new`);
  };

  return (
    <div className="mb-5">
      <BooksList
        books={books}
        onAdd={handleAdd}
        onEdit={handleEdit}
      />
    </div>
  );
}

Books.propTypes = {
  books: PropTypes.array.isRequired,
  loadBooks: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return ({
    books: state.books,
  });
};

const mapDispatchToProps = dispatch => ({
  loadBooks: () => dispatch(bookActions.loadBooks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Books);
