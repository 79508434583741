import React from "react";
import PropTypes from "prop-types";
import {
  timeRanges,
  daysOfWeek,
  typeCheckboxes,
  tooltips,
  inputProps,
} from "./SignupData";
import Checkbox from "../common/Checkbox";
import Input from "../common/Input";
import Tooltip from "../common/Tooltip";
import SelectInput from "../common/SelectInput";

const SignupForm = ({ formData, onChange, onSubmit, errors, disabled }) => {
  const isValid = error => (error ? "is-invalid" : "");
  return (
    <div>
      <h2 className="signup-page-title">Zapisz się na zajęcia</h2>
      <p className="mb-4">
        Wypełnij poniższy formularz już dziś i opanuj język angielski. Nie zwlekaj! Liczba miejsc jest
        ograniczona.
      </p>

      <form id="signup-form ml-0 mr-0 mt-2" onSubmit={onSubmit}>
        <div className="signup-form-row form-row">
          <div className="col-lg-6 pl-2">
            <div className="signup-form-row form-row">Dane kontaktowe</div>
            <Input {...inputProps.email} value={formData.email} error={errors.email} onChange={onChange}/>
            <div className="signup-form-row form-row">
              <div className="col-lg-4 pl-0">
                <Input
                  {...inputProps.name}
                  value={formData.name}
                  error={errors.name}
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pl-0">
                <Input
                  {...inputProps.surname}
                  value={formData.surname}
                  error={errors.surname}
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 pl-0">
                <Input
                  {...inputProps.phone}
                  value={formData.phone}
                  error={errors.phone}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="signup-form-row form-row">Dane ucznia</div>
            <div className="signup-form-row form-row">
              <div className="col-lg-4 mb-1">
                <Input
                  {...inputProps.childname}
                  value={formData.childname}
                  error={errors.childname}
                  onChange={onChange}
                />
              </div>
              <div className="col-lg-4 mb-1">
                <SelectInput
                  label="Wiek"
                  onChange={onChange}
                  name="age"
                  defaultOption="Wybierz wiek"
                  value={formData.age}
                  options={Array.from({ length: 18 }, (value, index) => ({
                    value: (index + 1).toString(),
                    text: (index + 1).toString(),
                  }))}
                />

              </div>
            </div>
            <div className="signup-form-row form-row">
              <div className="col-lg-4 pl-0">
                <div className="signup-form-row form-row signup-info">
                  Kontakt z j, ang.
                  <Tooltip description={tooltips.contact} />
                </div>
                <div className="radio-container">
                  {["Tak", "Nie"].map(level => (
                    <div key={level} className="radio-item">
                      <input
                        type="radio"
                        name="level"
                        value={level}
                        className={`form-check-input ${
                          errors.level ? "is-invalid" : ""
                        }`}
                        onChange={onChange}
                        id={`level-${level}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`level-${level}`}
                      >
                        {level}
                      </label>
                    </div>
                  ))}
                  {errors.level && (
                    <div className="invalid-feedback">{errors.level}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="signup-form-row form-row">
              <Input
                {...inputProps.leveldesc}
                className="w-100"
                value={formData.leveldesc}
                error={errors.leveldesc}
                onChange={onChange}
              />
            </div>
            <Checkbox
              name="permission"
              className="signup"
              onChange={onChange}
              error={errors.permission}
              isChecked={formData.permission}
              textPosition="rightOnLarge"
              label="Zgadzam się na przechowywanie i przetwarzanie moich danych"
              styleOptions={{
                errorPosition: "belowOnLarge",
                fontStyle: "signup-checkbox signup-info",
                labelBold: false,
                centerContent: false,
                narrowCheckbox: true,
              }}
            />
            <div className="signup-form-row form-row" />
          </div>
          <div className="col-lg-6 pl-4">
            <div className="signup-form-row form-row">
              Typ Lekcji
              <Tooltip description={tooltips.type} />
            </div>
            <div className="input-group signup-form-row form-row form-column-container">
              {typeCheckboxes.map(checkbox => (
                <div
                  className={`mb-3 form-column form-control ${isValid(
                    errors[checkbox.name]
                  )}`}
                  key={checkbox.name}
                >
                  <Checkbox
                    name={checkbox.name}
                    className="signup"
                    onChange={onChange}
                    textPosition="belowOnLarge"
                    isChecked={formData[checkbox.name]}
                    label={checkbox.label}
                    styleOptions={{
                      errorPosition: "belowOnLarge",
                      fontStyle: "signup-checkbox signup-info",
                      labelBold: false,
                    }}
                  />
                </div>
              ))}
              <div className="invalid-feedback text-left mt-2">
                {errors.typei}
              </div>
            </div>

            <div className="signup-form-row form-row">
              Preferowane terminy zajęć
              <Tooltip description={tooltips.time} />
            </div>
            <div className="signup-form-row form-row">
              <div className="col-lg-12 pl-0">
                <label className="signup-info signup-info-time">Dni</label>
                <div className="input-group signup-form-row form-row form-column-container">
                  {daysOfWeek.map(day => (
                    <div className="form-column text-center" key={day}>
                      <Checkbox
                        name={day}
                        className="signup"
                        onChange={onChange}
                        textPosition="belowOnLarge"
                        isChecked={formData.days[day]}
                        label={day}
                        styleOptions={{
                          errorPosition: "belowOnLarge",
                          fontStyle: "signup-checkbox signup-info",
                          labelBold: false,
                        }}
                      />

                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="signup-form-row form-row">
              <div className="col-lg-12 pl-0">
                <div className="signup-info signup-info-time">Godziny</div>
                <div className="input-group signup-form-row form-row form-column-container">
                  {timeRanges.map(range => (
                    <div className="form-column text-center" key={range}>
                      <Checkbox
                        name={range}
                        className="signup"
                        onChange={onChange}
                        textPosition="belowOnLarge"
                        isChecked={formData.times[range]}
                        label={range}
                        styleOptions={{
                          errorPosition: "belowOnLarge",
                          fontStyle: "signup-checkbox signup-info",
                          labelBold: false,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="signup-form-row form-row">
              <div className="col-lg-12 pl-0 h-100">
                <Input
                  {...inputProps.desc}
                  className="w-100"
                  value={formData.desc}
                  error={errors.desc}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-danger font-weight-bold btn-block"
          style={{ background: "#c91f27" }}
          disabled={disabled}
        >
          Wyślij nam wiadomość z prośbą o zapis
        </button>
      </form>
    </div>
  );
};

SignupForm.propTypes = {
  formData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    childname: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    leveldesc: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    typei: PropTypes.bool.isRequired,
    type2: PropTypes.bool.isRequired,
    typeg: PropTypes.bool.isRequired,
    desc: PropTypes.string.isRequired,
    permission: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SignupForm;
