import * as types from "./actionTypes";
import API from "../../services/ClientService";
import {apiCallError, beginApiCall} from "./apiStatusActions";
import { saveStudent } from "./studentActions";

export function loadClientsSuccess(clients) {
  return { type: types.LOAD_CLIENTS_SUCCESS, clients };
}

export function createClientSuccess(client) {
  return { type: types.CREATE_CLIENT_SUCCESS, client };
}

export function updateClientSuccess(client) {
  return { type: types.UPDATE_CLIENT_SUCCESS, client };
}

export function archiveClientOptimistic(client) {
  return { type: types.ARCHIVE_CLIENT_OPTIMISTIC, client };
}

export function deleteClientOptimistic(client) {
  return { type: types.DELETE_CLIENT_OPTIMISTIC, client };
}

function updateStudents(dispatch, getState, savedClient) {
  const { students } = getState();
  const { deleted, archived } = savedClient;
  students
    .filter(student => student.clientId === savedClient.id)
    .forEach((student) => {
      dispatch(saveStudent({ ...student, deleted, archived }));
    });
}

export function archiveClient(client) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('archiveClient'));

    return API.saveClient({...client, archived: !client.archived})
      .then((savedClient) => {
        dispatch(updateClientSuccess(savedClient));
        updateStudents(dispatch, getState, savedClient);
        return savedClient;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteClient(client) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('deleteClient'));

    return API.saveClient({...client, deleted: true})
      .then((savedClient) => {
        dispatch(updateClientSuccess(savedClient));
        updateStudents(dispatch, getState, savedClient);
        return savedClient;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveClient(client) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveClient'));
    return API.saveClient(client)
      .then((savedClient) => {
        dispatch(
          client.id ? updateClientSuccess(savedClient) : createClientSuccess(savedClient)
        );
        updateStudents(dispatch, getState, savedClient);
        return savedClient;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function loadClients() {
  return (dispatch) => {
    dispatch(beginApiCall('getClients'));
    return API.getClients()
      .then((clients) => {
        dispatch(loadClientsSuccess(clients));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
