import {handleError, handleResponse} from "./apiUtils";

const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

function getBook(id) {
  return fetch(`${baseUrl}books/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

function getBooks() {
  return fetch(`${baseUrl}books`)
    .then(handleResponse)
    .catch(handleError);
}

function saveBook(book) {
  return fetch(`${baseUrl}books/${book.id || ""}`, {
    method: book.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(book),
  })
    .then(handleResponse)
    .catch(handleError);
}


export default {
  getBook,
  getBooks,
  saveBook,
};
