import React, {useEffect, useState} from "react";
import Checkbox from "../common/Checkbox";
import Modal from 'react-bootstrap/Modal';
import Input from "../common/Input";
import SelectInput from "../common/SelectInput";

function SchoolClassesFilterForm({filters, handleFilterChange, clearFilters, handleBooleanFilterChange, teachers, weekdays, classTypes}) {
  const [isModalShowing, setIsModalShowing] = useState(false);

  const handleShowModal = () => {
    setIsModalShowing(!isModalShowing);
  };

  return (
    <div>
      <button type="button" className="btn manage-button btn-dark mr-2" onClick={handleShowModal}><i className="fas fa-filter fa-lg"></i></button>
      <Modal show={isModalShowing} onHide={handleShowModal} className="custom-modal" centered>
        <Modal.Body>
          <div className="row d-flex m-0">
            <SelectInput
              label="Nauczyciel"
              onChange={handleFilterChange}
              name="teacherId"
              value={filters.teacherId}
              defaultOption="Wybierz nauczyciela"
              options={teachers.map(t => ({
                value: t.id,
                text: `${t.firstName} ${t.lastName}`,
              }))}
              className="col-sm-6 px-0 pr-sm-3"
            />
            <SelectInput
              label="Typ"
              onChange={handleFilterChange}
              name="classTypeId"
              value={filters.classTypeId}
              defaultOption="Wybierz typ zajęć"
              options={classTypes.map(classType => ({
                value: classType.id,
                text: classType.name,
              }))}
              className="col-sm-6 px-0"
            />
          </div>
          <div className="row d-flex m-0">
            <SelectInput
              label="Dzień tygodnia"
              onChange={handleFilterChange}
              name="dayOfWeek"
              value={filters.dayOfWeek}
              defaultOption="Wybierz dzień tygodnia"
              options={weekdays.map(d => ({
                value: d.id,
                text: d.name,
              }))}
              className="col-sm-6 p-0"
            />
            <Input 
              label="Od:" 
              type='time' 
              onChange={handleFilterChange} 
              name="startTime" 
              value={filters.startTime} 
              placeholder="Początek zajęć" 
              className="col-6 col-sm-3 pr-2 pl-0 pl-sm-3 pr-sm-0"
            />
            <Input 
              label="Do:" 
              type='time' 
              onChange={handleFilterChange} 
              name="endTime" 
              value={filters.endTime} 
              placeholder="Koniec zajęć" 
              className="col-6 col-sm-3 pl-2 pl-sm-3 pr-0"
            />
          </div>
          <div className="row d-flex m-0">
            <Input
              type="date"
              label="Pierwsza Lekcja:"
              id="fromDate"
              name="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChange}
              className="col-sm-6 px-0 pr-sm-2"
            />
            <Input
              type="date"
              label="Ostatnia Lekcja:"
              id="tillDate"
              name="tillDate"
              value={filters.tillDate}
              onChange={handleFilterChange}
              className="col-sm-6 px-0 pl-sm-2"
            />
          </div>

          <div className="mt-3">
            <Checkbox label="Tylko zajęcia jednorazowe?" onChange={() => handleBooleanFilterChange('showOnlyNotRepeating')} name="showOnlyNotRepeating" isChecked={filters.showOnlyNotRepeating}/>
            <Checkbox label="Tylko zajęcia cykliczne?" onChange={() => handleBooleanFilterChange('showOnlyRepeating')} name="showOnlyRepeating" isChecked={filters.showOnlyRepeating}/>
            <Checkbox label="Uwaga lub komentarz?" onChange={() => handleBooleanFilterChange('comment')} name="comment" isChecked={filters.comment}/>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn manage-button btn-success mr-auto" onClick={clearFilters}><i className="fas fa-undo" /></button>
          <button type="button" className="btn manage-button manage-teal-button" onClick={handleShowModal}>Powrót</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SchoolClassesFilterForm;
