/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../../styles/styles.scss";


class PrivacyModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="no-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "justify" }}>
            <h3 style={{ padding: "0 0 0 0px" }}>
              <strong>
                Polityka prywatności
              </strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <div className="politykaPrywatnosci">
                  <h3>Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.</h3>
                  <hr />
                  <div className="lh166">
                    <h3 className="pb-12"><strong>1. Informacje ogólne</strong></h3>
                    <ol>
                      <li>

                        Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url:
                        <b> www.extra-english.pl</b>
                      </li>
                      <li>Operatorem serwisu oraz Administratorem danych osobowych jest: Alicja Marciniak ul. Wypoczynkowa 43, 62-081, Baranowo</li>
                      <li>Adres kontaktowy poczty elektronicznej operatora: szkola@extra-english.pl</li>
                      <li>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</li>
                      <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
                      <ul>
                        <li>Wyświetlanie ogłoszeń użytkowników</li>
                        <li>Prezentacja oferty lub informacji</li>
                      </ul>
                      <li>

                        Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:

                        <ol>
                          <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
                          <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. &quot;ciasteczka&quot;).</li>
                        </ol>
                      </li>
                    </ol>
                    <h3 className="pb-12"><strong>2. Wybrane metody ochrony danych stosowane przez Operatora</strong></h3>
                    <ol>
                      <li>Operator okresowo zmienia swoje hasła administracyjne.</li>
                      <li>Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.</li>
                    </ol>
                    <h3 className="pb-12"><strong>3. Hosting</strong></h3>
                    <ol>
                      <li>Serwis jest hostowany (technicznie utrzymywany) na serwerach operatora: Amazon.com</li>
                    </ol>
                    <h3 className="pb-12"><strong>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</strong></h3>
                    <ol>
                      <li>

                        W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na Administratorze. Dotyczy to takich grup odbiorców:
                        <ul><li>upoważnieni pracownicy i współpracownicy, którzy korzystają z danych w celu realizacji celu działania strony</li></ul>
                      </li>
                      <li>Twoje dane osobowe przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż przez 3 lata.</li>
                      <li>

                        Przysługuje Ci prawo żądania od Administratora:

                        <ul>
                          <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                          <li>ich sprostowania,</li>
                          <li>usunięcia,</li>
                          <li>ograniczenia przetwarzania,</li>
                          <li>oraz przenoszenia danych.</li>
                        </ul>
                      </li>
                      <li>Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interesów realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub obrony roszczeń.</li>
                      <li>Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</li>
                      <li>Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi Serwisu.</li>
                      <li>W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.</li>
                      <li>Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy ich poza teren Unii Europejskiej.</li>
                    </ol>
                    <h3 className="pb-12"><strong>5. Informacje w formularzach</strong></h3>
                    <ol>
                      <li>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</li>
                      <li>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</li>
                      <li>Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim wypadku adres e-mail użytkownika pojawia się wewnątrz adresu url strony zawierającej formularz.</li>
                      <li>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do czego on służy.</li>
                    </ol>

                    <h3 className="pb-12"><strong>6. Logi Administratora</strong></h3>
                    <ol>
                      <li>Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.</li>
                    </ol>
                    <h3 className="pb-12"><strong>7. Istotne techniki marketingowe</strong></h3>
                    <ol><li>Operator stosuje analizę statystyczną ruchu na stronie, poprzez Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje do operatora tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia: https://www.google.com/ads/preferences/</li></ol>

                    <h3 className="pb-12"><strong>8. Informacja o plikach cookies</strong></h3>
                    <ol>
                      <li>Serwis korzysta z plików cookies.</li>
                      <li>Pliki cookies (tzw. &quot;ciasteczka&quot;) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
                      <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
                      <li>

                        Pliki cookies wykorzystywane są w następujących celach:

                        <ol>
                          <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
                          <li>realizacji celów określonych powyżej w części &quot;Istotne techniki marketingowe&quot;;</li>
                        </ol>
                      </li>
                      <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: &quot;sesyjne&quot; (session cookies) oraz &quot;stałe&quot; (persistent cookies). Cookies &quot;sesyjne&quot; są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). &quot;Stałe&quot; pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
                      <li>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie.&nbsp;Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</li>
                      <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
                      <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujące z operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</li>
                    </ol>
                    <h3 className="pb-12"><strong>9. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</strong></h3>
                    <ol>
                      <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić,&nbsp;a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www</li>
                      <li>
                        W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:
                        <ul>
                          <li><a href="https://support.a.com/pl-pl/help/10607/a-edge-view-delete-browser-history">Edge</a></li>
                          <li><a href="https://support.a.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a></li>
                          <li><a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a></li>
                          <li><a href="http://support.apple.com/kb/PH5042">Safari</a></li>
                          <li><a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a></li>
                          <li><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li>
                        </ul>
                        <p>Urządzenia mobilne:</p>
                        <ul>
                          <li><a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Android</a></li>
                          <li><a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">Safari (iOS)</a></li>
                          <li><a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows Phone</a></li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-xl-1" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: "justify" }}>
          <Button onClick={this.props.onHide}>Zgadzam się</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PrivacyModal;
