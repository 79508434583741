import React from "react";
import PropTypes from "prop-types";
import seo_keywords from "../../../data/seo";

const TeacherCard = ({name, imgURL, aboutMeText}) => {

  const paragraphs = aboutMeText.split('\n\n');
  console.log(paragraphs)
  return (
  <>
    <div className="row d-flex align-items-center justify-content-center">

      <div className="col-lg-6 pb-3">
        <img
          src={imgURL}
          alt={seo_keywords}
          className={`portrait-in-card rounded-circle pb-2 mx-auto d-block mb-2`}
          />
      </div>
      <div className="col-lg-6 pb-3">
        <p className="panel-title text-center text-lg-left">{name}</p>
      </div>

      <div className="col-11">
        {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ))}
      </div>

    </div>
  </>
);
}

TeacherCard.propTypes = {
  name: PropTypes.string.isRequired,
  imgURL: PropTypes.string.isRequired,
  aboutMeText: PropTypes.string.isRequired,

};

export default TeacherCard;
