/* eslint-disable import/prefer-default-export */
export const africaGroup =
{
  name: "Africa",
  content: [
    {
      imageIndexSet: [1, 2, 3, 4, 5],
      imageSetContent:
        [
          {
            imageIndex: 1,
            url: '../resources/events/Africa/1.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 2,
            url: '../resources/events/Africa/2.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 3,
            url: '../resources/events/Africa/3.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 4,
            url: '../resources/events/Africa/4.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 5,
            url: '../resources/events/Africa/5.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [6, 7, 8, 9, 10],
      imageSetContent:
        [
          {
            imageIndex: 6,
            url: '../resources/events/Africa/6.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 7,
            url: '../resources/events/Africa/7.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 8,
            url: '../resources/events/Africa/8.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 9,
            url: '../resources/events/Africa/11.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 10,
            url: '../resources/events/Africa/12.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [11, 12, 13, 14, 15],
      imageSetContent:
        [
          {
            imageIndex: 11,
            url: '../resources/events/Africa/10.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 12,
            url: '../resources/events/Africa/16.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 13,
            url: '../resources/events/Africa/9.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 14,
            url: '../resources/events/Africa/v1.jpg',
            type: "video",
            vidUrl: '../resources/events/Africa/v1.mp4',
            altTag: "pumpkins",
          },
          {
            imageIndex: 15,
            url: '../resources/events/Africa/15.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [16, 17, 18, 19],
      imageSetContent:
        [
          {
            imageIndex: 16,
            url: '../resources/events/Africa/13.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 17,
            url: '../resources/events/Africa/14.jpg ',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 18,
            url: '../resources/events/Africa/v2.jpg',
            vidUrl: '../resources/events/Africa/v2.mp4',
            type: "video",
            altTag: "pumpkins",
          },
        ],
    },
  ],
};


export const halloweenGroup =
{
  name: "Halloween",
  content: [
    {
      imageIndexSet: [1, 2, 3, 4, 5, 6],
      imageSetContent:
        [
          {
            imageIndex: 1,
            url: '../resources/events/Halloween/1.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 2,
            url: '../resources/events/Halloween/2.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 3,
            url: '../resources/events/Halloween/3.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 4,
            url: '../resources/events/Halloween/4.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 5,
            url: '../resources/events/Halloween/21.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 6,
            url: '../resources/events/Halloween/6.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [7, 8, 9, 10, 11, 12],
      imageSetContent:
        [
          {
            imageIndex: 7,
            url: '../resources/events/Halloween/7.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 8,
            url: '../resources/events/Halloween/8.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 9,
            url: '../resources/events/Halloween/9.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 10,
            url: '../resources/events/Halloween/10.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 11,
            url: '../resources/events/Halloween/11.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 12,
            url: '../resources/events/Halloween/12.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [13, 14, 15, 16, 17, 18],
      imageSetContent:
        [

          {
            imageIndex: 13,
            url: '../resources/events/Halloween/13.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 14,
            url: '../resources/events/Halloween/14.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 15,
            url: '../resources/events/Halloween/15.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 16,
            url: '../resources/events/Halloween/16.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 17,
            url: '../resources/events/Halloween/17.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 18,
            url: '../resources/events/Halloween/18.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
    {
      imageIndexSet: [19, 20, 21, 22, 23],
      imageSetContent:
        [
          {
            imageIndex: 19,
            url: '../resources/events/Halloween/19.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 20,
            url: '../resources/events/Halloween/20.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 21,
            url: '../resources/events/Halloween/5.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 22,
            url: '../resources/events/Halloween/22.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
          {
            imageIndex: 23,
            url: '../resources/events/Halloween/23.jpg',
            type: "photo",
            altTag: "pumpkins",
          },
        ],
    },
  ],
};


export const eaglesGroup =
  {
    name: "Orły",
    content: [
      {
        imageIndexSet: [1],
        imageSetContent:
          [
            {
              imageIndex: 1,
              url: '../resources/events/Eagles/Kinga.jpg',
              type: "photo",
              altTag: "orły edukacji",
            },
          ],
      },
      {
        imageIndexSet: [2],
        imageSetContent:
          [
            {
              imageIndex: 2,
              url: '../resources/events/Eagles/Alicja.jpg',
              type: "photo",
              altTag: "orły edukacji",
            },
          ],
      },
      {
        imageIndexSet: [3],
        imageSetContent:
          [
            {
              imageIndex: 3,
              url: '../resources/events/Eagles/Faith.jpg',
              type: "photo",
              altTag: "orły edukacji",
            },
          ],
      },
    ],
  };

export const summerCamp2021Group =
  {
    name: "Półkolonie z angielskim",
    content: [
      {
        imageIndexSet: [1, 2, 3, 4, 5, 6, 7, 8],
        imageSetContent:
          [
            {
              imageIndex: 1,
              url: '../resources/events/summerCamp2021/IMG_0003.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 2,
              url: '../resources/events/summerCamp2021/IMG_0005.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 3,
              url: '../resources/events/summerCamp2021/IMG_0012.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 4,
              url: '../resources/events/summerCamp2021/IMG_0013.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 5,
              url: '../resources/events/summerCamp2021/IMG_0021.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 6,
              url: '../resources/events/summerCamp2021/IMG_0063.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 7,
              url: '../resources/events/summerCamp2021/IMG_0064.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 8,
              url: '../resources/events/summerCamp2021/IMG_0065.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },

          ],
      },
      {
        imageIndexSet: [10, 11, 12, 13, 14, 15, 16],
        imageSetContent:
          [
            {
              imageIndex: 10,
              url: '../resources/events/summerCamp2021/IMG_0027.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 11,
              url: '../resources/events/summerCamp2021/IMG_0028.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 12,
              url: '../resources/events/summerCamp2021/IMG_0024.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 13,
              url: '../resources/events/summerCamp2021/IMG_0025.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 14,
              url: '../resources/events/summerCamp2021/IMG_0026.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 15,
              url: '../resources/events/summerCamp2021/IMG_0031.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 16,
              url: '../resources/events/summerCamp2021/IMG_0032.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
          ],
      },
      {
        imageIndexSet: [20, 21, 22, 23, 24],
        imageSetContent:
          [
            {
              imageIndex: 20,
              url: '../resources/events/summerCamp2021/IMG_0037.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 21,
              url: '../resources/events/summerCamp2021/IMG_0039.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 22,
              url: '../resources/events/summerCamp2021/IMG_0045.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 23,
              url: '../resources/events/summerCamp2021/IMG_0046.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 24,
              url: '../resources/events/summerCamp2021/IMG_0048.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 25,
              url: '../resources/events/summerCamp2021/IMG_0067.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 26,
              url: '../resources/events/summerCamp2021/IMG_0069.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 27,
              url: '../resources/events/summerCamp2021/IMG_0071.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
          ],
      },
      {
        imageIndexSet: [30, 31, 32, 33, 34, 35, 36, 37],
        imageSetContent:
          [
            {
              imageIndex: 30,
              url: '../resources/events/summerCamp2021/IMG_0051.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 31,
              url: '../resources/events/summerCamp2021/IMG_0053.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 32,
              url: '../resources/events/summerCamp2021/IMG_0058.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 34,
              url: '../resources/events/summerCamp2021/IMG_0059.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 35,
              url: '../resources/events/summerCamp2021/IMG_0062.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 36,
              url: '../resources/events/summerCamp2021/IMG_0074.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
            {
              imageIndex: 37,
              url: '../resources/events/summerCamp2021/IMG_0035.JPG',
              type: "photo",
              altTag: "półkolonie 2021",
            },
          ],
      },
    ],
  };
