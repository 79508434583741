import React from "react";
import PropTypes from "prop-types";

const OfferCard = ({name, groupDescription}) => {

  const paragraphs = groupDescription.split('\n\n');
  
  return (
  <>
    <div className="row d-flex align-items-center m-2 mt-4">
      <div className="col-lg-6 py-3">
        <p className="offer-tab-title ">{name}</p>
      </div>

      {/* <div className="col-lg-6 ">
        <img
          src={imgURL}
          className={`portrait-image rounded-circle pb-2 mx-auto d-block mb-2`}
          />
      </div> */}

      <div className="col-12">
        {paragraphs.map((paragraph, index) => (
            <p className="p-0" key={index}>{paragraph}</p>
        ))}
      </div>

    </div>
  </>
);
}

OfferCard.propTypes = {
  name: PropTypes.string.isRequired,
  imgURL: PropTypes.string.isRequired,
  groupDescription: PropTypes.string.isRequired,
};

export default OfferCard;
