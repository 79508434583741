const OfferDataPrices = [
  {
    title: "Zajęcia Indywidualne",
    prices: [
      {duration: "45 min", price: "100 zł"},
      {duration: "60 min", price: "120 zł"}],
  },
  {
    title: "Indwidualne Online",
    tooltipText: `Indywidualne zajęcia z native speakerem (tylko po angielsku)`,
    prices: [
      {duration: "45 min", price: "80 zł"},
      {duration: "60 min", price: "100 zł"}],
  },
  {
    title: "Zajęcia Grupowe",
    prices: [
      {duration: "45 min", price: "40 zł"},
      {duration: "60 min", price: "45 zł"}],
  },
  {
    title: "Zajęcia Dwuosobowe",
    tooltipText: `Cena za osobę przy dwóch osobach na lekcji, przy jednej osobie naliczana jest opłata za indywidualną lekcję`,
    prices: [
      {duration: "60 min", price: "60 zł"},
    ],
  },
];

export default OfferDataPrices;
