import React from 'react';
import PropTypes from 'prop-types';

const Answer = ({ answerIndex, answer, onAnswerSelected, isCorrect, isWrong, readOnly }) => (
  <li
    className={`question-answer ${isCorrect ? 'right' : ''} ${isWrong ? 'wrong' : ''}`}
    onClick={(event) => {
      event.preventDefault();
      if (readOnly) return;
      onAnswerSelected(answerIndex);
    }}
    onKeyDown={(event) => {
      event.preventDefault();
      if (readOnly) return;
      if (event.keyCode === 13) {
        onAnswerSelected(answerIndex);
      }
    }}
  >
    <span>{answer}</span>
  </li>
);

Answer.propTypes = {
  answer: PropTypes.string.isRequired,
  answerIndex: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isWrong: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default Answer;
