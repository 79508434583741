import React from 'react';
import PropTypes from 'prop-types';
import Lesson from './Lesson';

const LessonWrapper = ({ event, display, students, teachers, onSubmit, deleteLessonRegister }) => {
  const scheduledLesson = event.scheduledLesson;

  return (
    <Lesson
      index={event.id}
      lesson={scheduledLesson}
      display={display}
      students={students}
      teachers={teachers}
      onSubmit={onSubmit}
      deleteLessonRegister={deleteLessonRegister}
    />
  );
};

LessonWrapper.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    scheduledLesson: PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string,
      schoolClassId: PropTypes.string,
      lessonRegister: PropTypes.object,
    }).isRequired,
  }).isRequired,
  display: PropTypes.string.isRequired,
  students: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
};

export default LessonWrapper;
