/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getById } from "../common/Helpers";
import { WEEKDAYS } from "../common/Const";
import LessonModal from './LessonModal';

const Lesson = ({
  index,
  lesson,
  display,
  students,
  teachers,
  onSubmit,
  deleteLessonRegister,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showLessonDetailsMenu, setShowLessonDetailsMenu] = useState(false);
  const [showAddStudentMenu, setShowAddStudentMenu] = useState(false);

  const [studentsEx, setStudentsEx] = useState([]);
  const [studentsNotInThisClassEx, setStudentsNotInThisClassEx] = useState([]);
  const [lessonDetailsEx, setLessonDetailsEx] = useState({});
  const [undoChanges, setUndoChanges] = useState(false);

  useEffect(() => {
    setStudentsEx(
      lesson.lessonRegister
        ? lesson.lessonRegister.students.map(s => ({ id: s.id, isPresent: s.isPresent }))
        : lesson.defaultStudents.map(s => ({ id: s.id, isPresent: false }))
    );
  }, [lesson, showModal, undoChanges]);

  useEffect(() => {
    setLessonDetailsEx(
      lesson.lessonRegister
        ? lesson.lessonRegister.lessonDetails
        : {
            ...lesson.defaultLessonDetails
          }
    );
  }, [lesson, showModal, undoChanges]);

  useEffect(() => {
    const studentIds = studentsEx.map(s => s.id);
    const filteredArray = students.filter(
      s => !studentIds.includes(s.id) && !s.deleted && !s.archived
    );
    const studentsNotInThisClassList = filteredArray.map(s => ({
      value: s.id,
      text: (s.firstName || "") + " " + (s.lastName || "")
    }));
    setStudentsNotInThisClassEx(studentsNotInThisClassList);
  }, [studentsEx]);

  const handleDateChange = (e) => {
    const newDayOfWeek = formatDayOfWeek(e.target.value);
    setLessonDetailsEx(prevLesson => ({
      ...prevLesson,
      date: e.target.value,
      dayOfWeek: newDayOfWeek,
    }));
  };

  const formatDayOfWeek = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek)
      ? null
      : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SUN'][dayOfWeek];
  };

  const getDayOfWeek = (dayOfWeek) => {
    const weekdayObject = WEEKDAYS ? getById(WEEKDAYS, dayOfWeek) : "";
    if (!weekdayObject) {
      return "";
    }
    return (weekdayObject.name || "");
  };

  const getNameById = (peopleArray, personId) => {
    const personObject = peopleArray ? getById(peopleArray, personId) : "";
    if (!personObject) {
      return "";
    }
    return `${personObject.firstName || ""} ${personObject.lastName || ""}`;
  };

  const handleUndoChanges = () => {
    setUndoChanges(!undoChanges);
  };

  const handleAddStudent = (student) => {
    setShowAddStudentMenu(!showAddStudentMenu);
    setStudentsEx([...studentsEx, { id: student.value }]);
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    setLessonDetailsEx(prevLesson => ({ ...prevLesson, [name]: value }));
  };

  const handleAttendanceChange = (event) => {
    setStudentsEx(studentsEx.map(s => (s.id === event.studentId ? { ...s, isPresent: event.target.value } : s)));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLessonDetailsMenu(false)
    setShowAddStudentMenu(false)
    setShowModal(false)
    const id = lesson.lessonRegister ? { id: lesson.lessonRegister.id } : {};
    onSubmit({
      lessonRegister: {
        ...id,
        lessonId: lesson.id,
        lessonDetails: lessonDetailsEx,
        students: studentsEx,
      },
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    if (lesson.lessonRegister) {
      if (window.confirm('Are you sure you want to delete this lesson register?')) {
        deleteLessonRegister(lesson.lessonRegister);
      }
    }
  };

  return (
    <LessonModal
      lesson={lesson}
      index={index}
      display={display}
      students={students}
      teachers={teachers}
      weekdays={WEEKDAYS}
      onDateChange={handleDateChange}
      showModal={showModal}
      showLessonDetailsMenu={showLessonDetailsMenu}
      showAddStudentMenu={showAddStudentMenu}
      setShowModal={setShowModal}
      setShowLessonDetailsMenu={setShowLessonDetailsMenu}
      setShowAddStudentMenu={setShowAddStudentMenu}
      lessonDetailsEx={lessonDetailsEx}
      studentsEx={studentsEx}
      studentsNotInThisClassEx={studentsNotInThisClassEx}
      getDayOfWeek={getDayOfWeek}
      getNameById={getNameById}
      onAddStudent={handleAddStudent}
      onUndoChanges={handleUndoChanges}
      onDetailsChange={handleDetailsChange}
      onAttendanceChange={handleAttendanceChange}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  );
};

Lesson.propTypes = {
  lesson: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  students: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
};

export default Lesson;
