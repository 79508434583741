import * as types from "./actionTypes";
import API from "../../services/TeacherService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadTeachersSuccess(teachers) {
  return { type: types.LOAD_TEACHERS_SUCCESS, teachers };
}

export function createTeacherSuccess(teacher) {
  return { type: types.CREATE_TEACHER_SUCCESS, teacher };
}

export function updateTeacherSuccess(teacher) {
  return { type: types.UPDATE_TEACHER_SUCCESS, teacher };
}

export function deleteTeacherOptimistic(teacher) {
  return { type: types.DELETE_TEACHER_OPTIMISTIC, teacher };
}


export function saveTeacher(teacher) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveTeacher'));
    return API.saveTeacher(teacher)
      .then((savedTeacher) => {
        dispatch(
          teacher.id ? updateTeacherSuccess(savedTeacher) : createTeacherSuccess(savedTeacher)
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function loadTeachers() {
  return (dispatch) => {
    dispatch(beginApiCall('getTeachers'));
    return API.getTeachers()
      .then((teachers) => {
        dispatch(loadTeachersSuccess(teachers));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
