/* eslint-disable react/prefer-stateless-function */

import React from "react";
import SchoolPage from "./SchoolPage";
import SchoolTeachers from "./SchoolTeachers";

const SchoolSection = () => {
  return (
    <div>

      <div className="row m-0 justify-content-center">
        <div className="col-md-11 col-lg-10 p-0">
          <SchoolPage />
          <SchoolTeachers />
        </div>
      </div>
      <div className="top-wave"></div>
    </div>

  )
}

export default SchoolSection;

