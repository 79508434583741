import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const cn = (block, modifiers) => {
  let className = 'quiz-results';
  if (block && block !== '') className += `__${block.trim()}`;

  let arrModifiers = [];
  if (typeof modifiers === "string") arrModifiers.push(modifiers);
  if (Array.isArray(modifiers)) arrModifiers = modifiers;

  return _(arrModifiers)
    .filter(modifier => typeof modifier == 'string' && modifier !== '')
    .map(modifier => `${className}--${modifier}`)
    .union([className])
    .join(" ");
};

const renderAnswer = (key, answer, aIndex, isSelected, isCorrect) => (
  <li
    key={key}
    className={`${cn('answer', [isCorrect && "correct", isSelected && 'selected'])}`}
  >
    {answer}
  </li>
);

const renderQuestion = (question, qIndex) => (
  <div key={`Q${qIndex}`} className={cn('question')}>
    <div className={cn('question-number')}>{`Pytanie ${qIndex + 1}`}</div>
    <div>{question.question}</div>
    <ul>
      {question.answers.map((answer, aIndex) => {
        const isSelected = aIndex + 1 === question.selectedAnswer;
        const isCorrect = aIndex + 1 === question.correctAnswer;
        return renderAnswer(`Q${qIndex}.A${aIndex}`, answer, aIndex, isSelected, isCorrect);
      }) }
    </ul>
  </div>
);

const Results = ({ questions, userAnswers }) => {
  const questionsAndAnswers = _.merge(questions, userAnswers);
  const correctAnswersCount = questionsAndAnswers.filter(q => q.isCorrect).length;
  return (
    <div className={cn('')}>
      <div className={cn('container')}>
        <div className={cn('header')}>Gratulacje udało Ci się ukończyć test odpowiadając poprawnie
          {` na ${correctAnswersCount} pytań z ${questionsAndAnswers.length}. `}
          Wyniki testu zostały wysłane na adres email szkoła@extra-english.pl i zostaną sprawdzone przez lektora.
        </div>
        {questionsAndAnswers.map((question, index) => renderQuestion(question, index))}
      </div>
    </div>
  );
};

Results.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    correctAnswer: PropTypes.number
  })).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.shape({
    tries: PropTypes.number.isRequired,
    isCorrect: PropTypes.bool.isRequired,
    selectedAnswer: PropTypes.number.isRequired
  })).isRequired
};


export default Results;
