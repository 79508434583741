import React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import 'react-toastify/dist/ReactToastify.css';
import {history} from "../../redux/store/configureStore";
import StudentForm from "./StudentForm";
import {STATUS} from "../common/Const";
import {mandatory, validateValues} from "../common/Validations";
import * as clientActions from "../../redux/actions/clientActions";
import * as studentActions from "../../redux/actions/studentActions";
import {getById} from "../common/Helpers";

const validationConfig = {
  firstName: {
    label: "Imię",
    validate: mandatory
  }
};

const getErrors = student => validateValues(student, validationConfig);

const hasNoErrors = errors => Object.keys(errors).length === 0;

class Student extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: {},
      client: {},
      errors: {},
      touched: {},
      status: STATUS.IDLE,
      saveError: null,
    };
  }

  componentDidMount() {
    const {clients, client, loadClients, students, student, loadStudents} = this.props;

    if (clients.length === 0) {
      loadClients().catch((error) => {
        alert(`Loading clients failed${error}`);
      });
    }

    if (students.length === 0) {
      loadStudents().catch((error) => {
        alert(`Loading students failed${error}`);
      });
    }

    if (client) this.setClient(client);
    if (student) this.setStudent(student);
  }

  componentDidUpdate(prevProps) {
    const {client, student} = this.props;

    if (client !== prevProps.client) {
      this.setClient(client);
    }
    if (student !== prevProps.student) {
      this.setStudent(student);
    }
  }

  setClient(client) {
    this.setState({ client });
  }

  setStudent(student) {
    this.setState({ student: { ...student } });
  }

  handleBlur = (event) => {
    const { name } = event.target;
    const { touched } = this.state;
    this.setState({ touched: { ...touched, [name]: true } });
    this.validate();
  }

  handleCancel = () => {
    history.goBack();
  }

  handleChange = (event) => {
    const {student} = this.state;
    const { name, value } = event.target;
    student[name] = value;
    this.setState({student});
    this.validate();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ status: STATUS.SUBMITTING });
    const isValid = this.validate();
    if (isValid) {
      const { student } = this.state;
      const { saveStudent } = this.props;
      saveStudent(student)
        .then(() => {
          this.setState({ status: STATUS.COMPLETED });
          history.goBack();
        })
        .catch((error) => {
          this.setState({ errors: {saveError: error.message}});
        });
    } else {
      this.setState({ status: STATUS.SUBMITTED });
    }
  }

  handleViewParent = (client) => {
    history.push(`/manage/client/${client.id}`);
  };

  validate() {
    const { student } = this.state;
    const errors = getErrors(student);
    this.setState({ errors });
    return hasNoErrors(errors);
  }

  render() {
    const {
      student, touched, errors, status, saveError, client
    } = this.state;
    const isValid = hasNoErrors(errors);
    if (saveError) throw saveError;

    return (
      <div className="mb-5">
        { !isValid && status === STATUS.SUBMITTED && (
          <div role="alert" className="alert alert-danger">
            <p>Proszę usunąć następujące błędy</p>
            <ul>
              {Object.keys(errors).map(key => <li key={key}>{errors[key]}</li>)}
            </ul>
          </div>
        )}
        <StudentForm
          firstName={student.firstName}
          lastName={student.lastName}
          additionalInfo={student.additionalInfo}
          touched={touched}
          errors={errors}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          status={status}
          client={client}
          onViewParent={this.handleViewParent}
        />
      </div>
    );
  }
}

Student.propTypes = {
  student: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  clients: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  loadStudents: PropTypes.func.isRequired,
  saveStudent: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  const clientId = new URLSearchParams(ownProps.location.search).get('clientId');
  const student = id === "new" || state.students.length === 0
    ? {clientId}
    : getById(state.students, id);
  const client = !student || !student.clientId || state.clients.length === 0
    ? {}
    : getById(state.clients, student.clientId);
  return {
    client,
    student,
    clients: state.clients,
    students: state.students,
  };
};

const mapDispatchToProps = dispatch => ({
  loadClients: () => dispatch(clientActions.loadClients()),
  loadStudents: () => dispatch(studentActions.loadStudents()),
  saveStudent: student => dispatch(studentActions.saveStudent(student)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Student);