import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  label,
  name,
  error,
  isChecked,
  type,
  readOnly,
  onChange,
  textPosition,
  styleOptions,
}) => {
  const {
    errorPosition,
    fontStyle,
    labelBold,
    centerContent,
    narrowCheckbox,
  } = styleOptions;
  const isValid = (e) => (e ? "is-invalid" : "");

  const leftPadding = textPosition !== "belowOnLarge" ? "pl-xl-3" : "";
  const rightMargin = textPosition !== "belowOnLarge" ? "mr-md-1" : "";
  let checkboxWidth, labelWidth;
  narrowCheckbox
    ? ((checkboxWidth = "col-md-1"), (labelWidth = "col-md-10"))
    : ((checkboxWidth = "col-md-3"), (labelWidth = "col-md-8"));

  const baseStyle = `d-flex col-12 justify-content-center p-0  ${fontStyle}`;
  const baseCheckboxStyle = `${baseStyle} ${leftPadding} ${rightMargin} mb-1 mb-md-0  align-items-center `;
  const baseLabelStyle = `${baseStyle} `;

  const checkboxClassname =
    textPosition === "rightOnLarge"
      ? `${baseCheckboxStyle} ${checkboxWidth}`
      : textPosition === "belowOnLarge"
      ? `${baseCheckboxStyle}`
      : "col-3";

  const labelClassname =
    textPosition === "rightOnLarge"
      ? `${baseLabelStyle} ${labelWidth} justify-content-md-start`
      : textPosition === "belowOnLarge"
      ? `${baseLabelStyle}`
      : `pl-2 col-9`;

  const labelElement = labelBold ? <strong>{label}</strong> : label;

  return (
    <div
      className={`row d-flex align-items-center ${
        centerContent && "justify-content-center"
      } form-group`}
    >
      <div className={checkboxClassname}>
        <input
          type={type}
          name={name}
          className={`form-control input-checkbox  ${isValid(error)}`}
          checked={isChecked}
          disabled={readOnly}
          onChange={(event) =>
            onChange({
              ...event,
              target: { name, value: event.target.checked },
            })
          }
        />
        {errorPosition == "rightOnLarge" && (
          <div className="invalid-feedback">{error}</div>
        )}
      </div>
      <span className={labelClassname}>{labelElement}</span>

      {errorPosition == "belowOnLarge" && (
        <div className="row pl-0 w-100">
          <div className="col-lg-12 d-block invalid-feedback pt-2 pl-3">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  isChecked: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  textPosition: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  styleOptions: PropTypes.shape({
    errorPosition: PropTypes.string,
    fontStyle: PropTypes.string,
    labelBold: PropTypes.bool,
    centerContent: PropTypes.bool,
    narrowCheckbox: PropTypes.bool,
  }),
};

Checkbox.defaultProps = {
  label: "",
  error: undefined,
  isChecked: false,
  type: "checkbox",
  readOnly: false,
  styleOptions: {
    errorPosition: "rightOnLarge",
    fontStyle: "checkbox-label",
    labelBold: true,
    centerContent: true,
    narrowCheckbox: false,
  },
};

export default Checkbox;
