import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const schoolClassesReducer = (state = initialState.schoolClasses, action) => {
  switch (action.type) {
    case types.CREATE_SCHOOL_CLASS_SUCCESS:
      return [...state, { ...action.schoolClass }];
    case types.UPDATE_SCHOOL_CLASS_SUCCESS:
      return state.map(schoolClass => (schoolClass.id === action.schoolClass.id ? action.schoolClass : schoolClass));
    case types.LOAD_SCHOOL_CLASSES_SUCCESS:
      return action.schoolClasses;
    case types.DELETE_SCHOOL_CLASS_OPTIMISTIC:
      return state.filter(schoolClass => schoolClass.id !== action.schoolClass.id);
    default:
      return state;
  }
};

export default schoolClassesReducer;
