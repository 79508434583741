/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import {STATUS} from "../common/Const";
import {calculateDisplayedErrors, minutesToTimeString, timeStringToMinutes} from "../common/Helpers";
import SelectInput from "../common/SelectInput";
import Checkbox from "../common/Checkbox";

const SchoolClassForm = ({id, classTypeId, dayOfWeek, startTime, teacherId, bookId, isRecurring, fromDate, tillDate, comment,
  classTypes, weekdays, teachers, books, touched, errors, onBlur, onChange, onSubmit, onEdit, onCancel, status, dateReadOnly}) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  const disabled = status === STATUS.SUBMITTING;
  const readOnly = status === STATUS.VIEW;
  const selectedClassType = classTypes.find(value => value.id === classTypeId);
  const startTimeMinutes = timeStringToMinutes(startTime);
  const endTime = (selectedClassType && startTimeMinutes) ? minutesToTimeString(startTimeMinutes + selectedClassType.length) : "";
  return (
    <div>
      <h2 className="manage-title">Zajęcia</h2>
      <form id="group-form" onSubmit={onSubmit}>
        <div className="form-row d-fl">
          <div className="col-xl-2 col-md-5 mb-1  ">
            <SelectInput
              label="Dzień tygodnia"
              onBlur={onBlur}
              onChange={onChange}
              name="dayOfWeek"
              value={dayOfWeek}
              error={err.dayOfWeek}
              readOnly={readOnly}
              defaultOption="Wybierz dzień tygodnia"
              options={weekdays.map(d => ({
                value: d.id,
                text: d.name,
              }))}
            />
          </div>
          <div className="col-xl-3 col-md-7 mb-1  ">
            <SelectInput
              label="Typ"
              onBlur={onBlur}
              onChange={onChange}
              name="classTypeId"
              value={classTypeId}
              error={err.classTypeId}
              readOnly={readOnly}
              defaultOption="Wybierz typ zajęć"
              options={classTypes.map(classType => ({
                value: classType.id,
                text: classType.name,
              }))}
            />
          </div>
          <div className="col-xl-2 col-md-2 mb-1  ">
            <Input 
              label="Początek" 
              onBlur={onBlur} 
              type='time' 
              onChange={onChange} 
              name="startTime" 
              value={startTime} 
              error={err.startTime} 
              placeholder="Początek zajęć" 
              readOnly={readOnly} />
          </div>
          <div className="col-xl-2 col-md-2 mb-1  ">
            <Input 
              label="Koniec" 
              onBlur={onBlur} 
              type='time' 
              onChange={onChange} 
              name="endTime" 
              value={endTime} 
              error={err.endTime} 
              placeholder="Koniec zajęć" 
              readOnly={readOnly} />
          </div>
          <div className="col-xl-3 col-md-8 mb-1  ">
            <SelectInput
              label="Nauczyciel"
              onBlur={onBlur}
              onChange={onChange}
              name="teacherId"
              value={teacherId}
              error={err.teacherId}
              readOnly={readOnly}
              defaultOption="Wybierz nauczyciela"
              options={teachers.map(t => ({
                value: t.id,
                text: `${t.firstName} ${t.lastName}`,
              }))}
            />
          </div>
        </div>
        <div className="form-row d-flex align-items-center">
          <div className="col-xl-3 col-md-4 col-sm-8 mb-1">
            <Checkbox 
              label="Zajęcia cykliczne" 
              onBlur={onBlur} 
              onChange={onChange} 
              name="isRecurring" 
              isChecked={isRecurring} 
              readOnly={readOnly} 
              error={err.isRecurring}/>
          </div>
          <div className="col-xl-2 col-md-4 mb-1  ">
            <Input 
              type="date" 
              label={isRecurring ? `Pierwszy tydzień` : 'Data'} 
              onBlur={onBlur} 
              onChange={onChange} 
              name="fromDate" 
              value={fromDate} 
              readOnly={readOnly || dateReadOnly} 
              error={err.fromDate} />
          </div>
          
            <div className="col-xl-2 col-md-4 mb-1  ">
              {isRecurring 
              ? <Input 
                  type="date" 
                  label="Ostatni tydzień" 
                  onBlur={onBlur} 
                  onChange={onChange} 
                  name="tillDate" 
                  value={tillDate} 
                  readOnly={readOnly} 
                  error={err.tillDate} />
              : <></>}
            </div>
          
          <div className="col-xl-5 mb-1  ">
            <SelectInput
              label="Książka"
              onBlur={onBlur}
              onChange={onChange}
              name="bookId"
              value={bookId}
              error={err.bookId}
              readOnly={readOnly}
              defaultOption="Wybierz książke"
              options={books.map(b => ({
                value: b.id,
                text: b.title,
              }))}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-xl-12 mb-1  ">
            <Input 
              label="Uwagi" 
              type="textarea" 
              onBlur={onBlur} 
              onChange={onChange} 
              name="comment" 
              value={comment} 
              readOnly={readOnly}
              error={err.comment} 
              placeholder="Uwagi" />
          </div>
        </div>

        <div className="pt-1 row  m-0 mb-3 ">
          {readOnly && (
            <div className="col-xl-block ml-auto" >
              <button type="button" className="btn manage-button manage-red-button btn-block " disabled={disabled} onClick={onEdit}>Modyfikuj</button>
            </div>
          )}
          {!readOnly && (
            <div className="col-xl-block ml-auto" >
              <button type="submit" className="btn manage-button manage-red-button btn-block " disabled={disabled}>Zapisz</button>
            </div>
          )}
          <div className="col-xl-block pl-2 pr-2" >
            <button type="button" className="btn manage-button manage-teal-button btn-block " disabled={disabled} onClick={onCancel}>{readOnly || !id ? 'Powrót' : 'Anuluj'}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

SchoolClassForm.propTypes = {
  id: PropTypes.string,
  classTypeId: PropTypes.string,
  dayOfWeek: PropTypes.string,
  startTime: PropTypes.string,
  teacherId: PropTypes.string,
  bookId: PropTypes.string,
  isRecurring: PropTypes.bool,
  fromDate: PropTypes.string,
  tillDate: PropTypes.string,
  comment: PropTypes.string,
  classTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    length: PropTypes.number,
    maxStudents: PropTypes.number,
  })).isRequired,
  weekdays: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  teachers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  })).isRequired,
  books: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

SchoolClassForm.defaultProps = {
  id: undefined,
  classTypeId: "",
  bookId: "",
  dayOfWeek: "",
  startTime: "",
  teacherId: "",
  isRecurring: false,
  fromDate: "",
  tillDate: "",
  comment: "",
};

export default SchoolClassForm;
