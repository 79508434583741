import {handleError, handleResponse} from "./apiUtils";

const baseUrl = "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/";

function signUp(user) {
  return fetch(`${baseUrl}users/${user.id}`, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

function signIn(user) {
  return fetch(`${baseUrl}login`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .then((response) => {
      localStorage.token = response.token;
      return response.user;
    })
    .catch(handleError);
}

export default {
  signUp,
  signIn,
};
