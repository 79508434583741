import React, { useEffect, useState, useRef } from "react";
import TeachersArray from "../../../data/TeachersArray";
import TeacherCard from "./TeacherCard";
import seo_keywords from "../../../data/seo";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode, Navigation } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/free-mode/free-mode.min.css';


const SchoolTeachers = () => {
  const [fade, setFade] = useState(false);
  const [isListVisible, setIsListVisible] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  
  const teacherCardRef = useRef();


  const handleClickReturn = () => {
    setFade(true);
    setTimeout(() => {
      setIsListVisible(true)
      setFade(false)
    }, 600);
  };

  const handleTeacherSelect = (teacher) => {
    setFade(true);
    setTimeout(() => {
      setSelectedTeacher(teacher.id);
      setIsListVisible(false);
      setFade(false)
    }, 600);
  };

  const selectedTeacherInfo = TeachersArray.find(
    (teacher) => teacher.id === selectedTeacher
  );

  useEffect(() => {

    teacherCardRef.current.scrollTop = 0;
  }, [selectedTeacher]);

  return (
    <div className="school-container mx-md-2 my-4 mx-3">
        <div className={`${fade ? "fade-out" : "fade-in"}`} ref={teacherCardRef}>
          { isListVisible ? (
            <>
              <div className="row py-3 px-4 p-sm-4 d-flex justify-content-around align-items-center">
                <div className="col-11">
                  <div className="row d-flex flex-row align-items-center">
                    <span className="panel-title pl-sm-3 pl-xl-0 pr-3 pb-0">Poznaj nas</span>
                    <span className="pl-sm-3 pl-xl-0">Kliknij w profil aby dowiedzieć się więcej o nas</span>
                  </div>               
                </div>
                <div className="col-xl-0" />


              </div>
              <Swiper
                freeMode={true}
                spaceBetween= {20}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1.3,
                    spaceBetween: 30,
                  },
                  576: {
                    slidesPerView: 1.66,
                  },
                  768: {
                    slidesPerView: 2.5,
                  },
                  992: {
                    slidesPerView: 2.66,
                  },
                  1200: {
                    slidesPerView: 3.5,
                  },
                }}
              >
                {TeachersArray.map (teacher => (
                  <SwiperSlide key={teacher.id}>
                    <div className="teacher-swiper-profile">
                      <img
                        src={teacher.imgURL}
                        alt={seo_keywords}
                        onClick={() => handleTeacherSelect(teacher)}
                        draggable="false"
                        className={`portrait-image rounded-circle mx-auto d-block`}
                        />
                      <div className="">
                        <p className="panel-title m-2 pb-2 text-center">{teacher.name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : 
          (
            <>
              <div className="p-3 py-5">
                <TeacherCard
                  id={selectedTeacherInfo.id}
                  name={selectedTeacherInfo.name}
                  imgURL={selectedTeacherInfo.imgURL}
                  aboutMeText={selectedTeacherInfo.aboutMeText}
                  />
              </div>
              <div className="teacher-footer d-flex py-3" onClick={handleClickReturn}>
                <i className="fas fa-undo-alt fa-lg"></i>
              </div>
            </>
          )}
        </div>
    </div>
  );
};

export default SchoolTeachers;
