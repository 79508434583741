export const timeRanges = [
  "9:00-10:00",
  "10:00-12:00",
  "12:00-14:00",
  "14:00-16:00",
  "16:00-18:00",
  "18:00-20:00",
];

export const daysOfWeek = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];

export const typeCheckboxes = [
  { name: "typei", label: "Indywidualne" },
  { name: "type2", label: "2-osobowe" },
  { name: "typeg", label: "Grupowe" },
];

export const tooltips = {
  time: "Można dodać więcej szczegółów w poniższym polu.",
  type:
    "Można wybrać więcej niż jeden typ zajęć. Więcej informacji znajduje się powyżej, na stronie 'oferta'.",
  contact:
    "Kontakt oznacza tutaj naukę w szkola a także poza nią. Proszę opisać rodzaj kontaktu i poziom w poniższym polu.",
};

export const inputProps = {
  email: {
    label: "Adres email",
    name: "email",
    id: "email-input",
    placeholder: "Adres email",
  },
  name: {
    label: "Imię",
    name: "name",
    id: "name-input",
    placeholder: "Imię",
  },
  surname: {
    label: "Nazwisko",
    name: "surname",
    id: "surname-input",
    placeholder: "Nazwisko",
  },
  phone: {
    label: "Nr. tel.",
    name: "phone",
    id: "phone-input",
    placeholder: "Numer telefonu",
  },
  childname: {
    label: "Imię",
    name: "childname",
    id: "childname-input",
    placeholder: "Imię dziecka",
  },
  level: {
    type: "radio",
    label: "Kontakt z j. ang.",
    name: "level",
    id: "level-input",
    placeholder: "",
  },
  leveldesc: {
    type: "textarea",
    rows: "3",
    label: "",
    name: "leveldesc",
    id: "email-input",
    placeholder: "Szczegóły dotyczące poziomu j. angielskiego",
  },
  desc: {
    type: "textarea",
    rows: "3",
    label: "",
    name: "desc",
    id: "desc-input",
    placeholder: "Szczegóły dotyczące preferowanych godzin",
  },
};
