import React from "react";
import PropTypes from "prop-types";
import QuizApp from "./QuizApp";
import "../../styles/styles.scss";

class QuizMail extends React.Component {
  static propTypes = {
    totalQuestions: PropTypes.number.isRequired
  };

  constructor() {
    super();
    this.state = {
      hideQuiz: true,
      email: "",
      error: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  onSubmit(event) {
    event.preventDefault();
    if (this.formIsValid()) {
      this.setState({hideQuiz: false});
    }
  }

  onChange(event) {
    const {name, value} = event.target;
    const newState = Object.assign({}, this.state);
    newState[name] = value;
    this.setState(newState);
  }

  formIsValid() {
    const {email} = this.state;
    const error = {};
    let formIsValid = true;
    if (email === null || email === "") {
      error.email = "Adres email nie może być pusty.";
      formIsValid = false;
    } else if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      error.email = "Nieprawidłowy adres email.";
      formIsValid = false;
    }
    this.setState({error});
    return formIsValid;
  }

  render() {
    const {totalQuestions} = this.props;
    const {hideQuiz, error, email} = this.state;
    return (
      <div id="parent" className="blurred-background">
        <div className="container-fluid">
          <div className="pt-2 row content">
            <div className="col-xl-2"/>
            {hideQuiz && (
              <div className="col-xl-8">
                <div className="content-heading">
                  <h1 className="quiz-text"><strong>Sprawdź swoją wiedzę</strong></h1>
                </div>
                <div className="quiz-text">
                  Chcesz sprawdzić swoją wiedzę z <strong>języka angielskiego? </strong>
                  Poniższy test składa się z {totalQuestions} pytań wielokrotnego wyboru.
                  Po zakończeniu testu jego wyniki zostaną wysłane na adres szkola@extra-english.pl
                  i pomogą naszym lektorom zakwalifikować Cię do odpowiedniej grupy.
                  <hr/>
                  <form id="contact-form">
                    <div className="form-row">
                      <div className="col-md-12 mb-1  ">
                        <div className="input-group">
                          <input
                            type="text"
                            name="email"
                            id="email-input"
                            placeholder="Adres email"
                            className={`form-control ${error.email ? "is-invalid" : ''}`}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">{error.email}</div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <button
                    type="submit"
                    className="btn manage-button manage-teal-button btn-block"
                    onClick={this.onSubmit}
                    style={{width: "100px"}}
                  >Dalej
                  </button>
                </div>
              </div>
            )}
            <div className="col-xl-2"/>
          </div>
          {!hideQuiz && (
            <div className="QuizContainer">
              <div className="row content">
                <div className="col-xl-2"/>
                <div className="col-xl-8">
                  <div className="content-heading"/>
                  <QuizApp
                    totalQuestions={totalQuestions}
                    email={email}
                  />
                </div>
                <div className="col-xl-2"/>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}


export default QuizMail;
