/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from "react";
import "react-bootstrap";
import ContactFormStateful from "./ContactFormStateful";

const ContactPage = () => (

  <div className="contact-page">
      <div className="row p-0 m-0 d-flex justify-content-center align-items-center">
        <div className="col-lg-5">
          <ContactFormStateful />
          <div className="row ml-4 d-flex align-items-center">
            <a target="_blank" href="https://www.facebook.com/SuperExtraEnglish/" className="mt-3 mr-3">
              <img
                src="../../resources/contact/facebook.png"
                style={{ width: "100px"}}
                title="angielski chyby angielski przeźmierowo angielski rokietnica angielski sady angielski smochowice angielski baranowo angielski kiekrz nauka angielskiego chyby nauka angielskiego przeźmierowo nauka angielskiego rokietnica nauka angielskiego sady nauka angielskiego smochowice nauka angielskiego baranowo nauka angielskiego kiekrz szkoła językowa chyby szkoła językowa przeźmierowo szkoła językowa rokietnica szkoła językowa sady szkoła językowa smochowice szkoła językowa baranowo szkoła językowa kiekrz"
                alt="angielski chyby angielski  przeźmierowo angielski rokietnica angielski sady angielski smochowice angielski baranowo angielski kiekrz nauka angielskiego chyby nauka angielskiego przeźmierowo nauka angielskiego rokietnica nauka angielskiego sady nauka angielskiego smochowice nauka angielskiego baranowo nauka angielskiego kiekrz szkoła językowa chyby szkoła językowa przeźmierowo szkoła językowa rokietnica szkoła językowa sady szkoła językowa smochowice szkoła językowa baranowo szkoła językowa kiekrz"
              />
            </a>
          </div>
          <div className="row ml-4 d-flex align-items-center">
            <a target="_blank" rel="noopener noreferrer" href="../../resources/offer/umowa_o_nauke_angielskiego_2024.pdf" className="mt-3">
              <button type="button" className="btn btn-info font-weight-bold">
                  Umowa o świadczenie usług
              </button>
            </a>
          </div>
        </div>

        <div className="col-lg-5 pt-4 pr-4 pl-4 pl-lg-0 ">
          <div className="google-maps embed-responsive embed-responsive-16by9">
            <iframe
              title="google-maps"
              className="embed-responsive-item"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.243909418292!2d16.792514416121623!3d52.438496250018154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704476481f3f1b9%3A0x1b85fc0a2ae42062!2sExtra+English!5e0!3m2!1sen!2suk!4v1566327647470!5m2!1sen!2suk"
              frameBorder="0"
              />
            </div>
        </div>
      </div>

      <div className="row p-0 m-0 d-flex justify-content-center align-items-center">
        <div className="col-md-5">
          <div className="pt-3 pr-4 pl-4">
            <h2 className="contact-page-title" >Kontakt z nami</h2>
            <div className="contact-info">
              <p>
                <i className="contact-icon fas fa-map-marker-alt"></i>
                Wypoczynkowa 43, 62-081 Baranowo
              </p>
              <p>
                <i className="contact-icon fas fa-phone"></i>
                (+48) 575 400 141
              </p>
              <p>
                <i className="contact-icon fas fa-envelope"></i>
                szkola@extra-english.pl
              </p>
              <p>
                <i className="contact-icon fas fa-clock"></i>
                Otwarte od 8:00 do 20:00 pn.-pt.
              </p>
            </div>
          </div>
        </div>
        
        <div className="contact-info col-md-5">
        <div className="pt-md-4 pr-4 pl-4 pr-md-2 pl-md-4">
          <p>
            <i className="contact-icon fas fa-dollar-sign"></i>
              NIP: 777-255-43-86
            </p>
            <p>
              <i className="contact-icon fas fa-check-circle"></i>
              REGON: 300953920
            </p>
            <p>
              <i className="contact-icon far fa-credit-card"></i>
              Konto bankowe: 36 1140 2004 0000 3502 8025 8272*

              <br/>
              
              <small>*UWAGA w tytule przelewu prosimy wpisać imię i nazwisko dziecka, nazwę grupy
              oraz termin zajęć.
              </small>
            </p>
            
          </div>
        </div>
      </div>
      
  </div>
);

export default ContactPage;
