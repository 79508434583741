import {handleError, handleResponse} from "./apiUtils";

const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

function getTeacher(id) {
  return fetch(`${baseUrl}teachers/${id}`)
    .then(handleResponse)
    .catch(handleError);
}

function getTeachers() {
  return fetch(`${baseUrl}teachers`)
    .then(handleResponse)
    .catch(handleError);
}

function saveTeacher(teacher) {
  return fetch(`${baseUrl}teachers/${teacher.id || ""}`, {
    method: teacher.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(teacher),
  })
    .then(handleResponse)
    .catch(handleError);
}


export default {
  getTeacher,
  getTeachers,
  saveTeacher,
};
