/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import {STATUS} from "../common/Const";
import {calculateDisplayedErrors} from "../common/Helpers";

const StudentForm = ({firstName, lastName, additionalInfo, touched, errors, onBlur, onChange, onSubmit, onCancel, status, client, onViewParent}) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  const disabled = status === STATUS.SUBMITTING;
  const clientName = `${client.firstName || ''} ${client.lastName || ''}`;
  return (
    <div>
      <h2 className="manage-title">Uczeń</h2>
      <form id="student-form" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-4 mb-1">
            <Input readOnly name="client" value={clientName} error={err.clientName} placeholder="Klient" />
          </div>
          <div className="col-md-4  mt-1 mb-1">
            <button type="button" className="btn manage-button manage-teal-button col-12" disabled={disabled} onClick={() => onViewParent(client)}>Przejdz do klienta</button>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-2 mb-1  ">
            <Input label="Imię" onBlur={onBlur} onChange={onChange} name="firstName" value={firstName} error={err.firstName} placeholder="Imię" />
          </div>
          <div className="col-md-2 mb-1  ">
            <Input label="Nazwisko" onBlur={onBlur} onChange={onChange} name="lastName" value={lastName} error={err.lastName} placeholder="Nazwisko" />
          </div>
          <div className="col-md-8 mb-1  ">
            <Input label="Informacje dodatkowe" onBlur={onBlur} onChange={onChange} name="additionalInfo" value={additionalInfo} error={err.additionalInfo} placeholder="Informacje dodatkowe" />
          </div>
        </div>

        <div className="mt-2 row mx-0">
          <div className="col-block mr-2 ml-auto" >
            <button type="submit" className="btn manage-button manage-red-button " disabled={disabled}>Zapisz</button>
          </div>
          <div className="col-block" >
            <button type="button" className="btn manage-button manage-teal-button" disabled={disabled} onClick={onCancel}>Powrót</button>
          </div>
        </div>
      </form>

    </div>
  );
};

StudentForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  additionalInfo: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  client: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
};

StudentForm.defaultProps = {
  firstName: "",
  lastName: "",
  additionalInfo: "",
  client: {},
};

export default StudentForm;
