import React from 'react';
import PropTypes from 'prop-types';
import Question from "./Question";

const Quiz = ({ step, questions, totalQuestions, selectedAnswer, onAnswerSelected, readOnly }) => {
  const question = questions[step - 1];

  return (
    <div className="wrapper" id="quiz">
      <header className="col text-center align-middle ">
        <div className="question-count">
          <h2>{`Pytanie ${step} z ${totalQuestions}`}</h2>
        </div>
      </header>

      <div className="questions">
        <Question
          question={question.question}
          answers={question.answers}
          correctAnswer={question.correctAnswer}
          selectedAnswer={selectedAnswer}
          onAnswerSelected={onAnswerSelected}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

Quiz.propTypes = {
  step: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.string),
    correctAnswer: PropTypes.number
  })).isRequired,
  totalQuestions: PropTypes.number.isRequired,
  selectedAnswer: PropTypes.number,
  onAnswerSelected: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

Quiz.defaultProps = {
  selectedAnswer: null,
  readOnly: false
};

export default Quiz;
