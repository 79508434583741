/* eslint-disable react/jsx-pascal-case */
import React, { useState} from "react";
import OfferDataArray from "../../../data/OfferDataArray";
import OfferCard from "./OfferCard";

const OfferPageAccordion = () => {

  const [selectedGroup, setSelectedGroup] = useState(OfferDataArray[0].id);
  const [isMenuShowing, setisMenuShowing] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const [fade, setFade] = useState(false);

  const selectedGroupInfo = OfferDataArray.find(
    (group) => group.id === selectedGroup
  );

  const tabColors = [
    'offer-tab-colour-1', 'offer-tab-colour-2', 'offer-tab-colour-3', 
    'offer-tab-colour-4', 'offer-tab-colour-5', 'offer-tab-colour-6',
    'offer-tab-colour-7', 'offer-tab-colour-8', 'offer-tab-colour-9',
  ];

  const cardColors = [
    'offer-card-colour-1', 'offer-card-colour-2', 'offer-card-colour-3', 
    'offer-card-colour-4', 'offer-card-colour-5', 'offer-card-colour-6',
    'offer-card-colour-7', 'offer-card-colour-8', 'offer-card-colour-9',
  ];

  const handleReturn = () => {
    setCollapse(false)
    setTimeout(() => {
      setisMenuShowing(true)
    }, 100);
  };


  const handleGroupSelect = (group) => {
    setSelectedGroup(group.id);
    setCollapse(true)
    setTimeout(() => {
      setisMenuShowing(false)
    }, 300);
  };

  return (
    <div className="">
      <div className="col-12 m-0 p-0" >
        <div className={`offer-accordion-menu m-0 p-0 ${collapse ? "offer-collapse" : "offer-expand"} `}>
          {isMenuShowing ? 
            <div className="offer-tabs-column col-12 m-0 p-0">
              {OfferDataArray.map((group) => (
                <div
                  key={group.id}
                  className={`m-0 p-3 pr-4 offer-tab ${tabColors[group.id % tabColors.length]}`}
                  onClick={() => handleGroupSelect(group)}
                >
                  <p className={`offer-tab-title m-0 p-0 ${collapse ? "offer-collapse" : "offer-expand"} `}>{group.name}</p>
                </div>
              ))}
            </div>
            :
            <div className={`offer-return-column  ${tabColors[selectedGroup % tabColors.length]}`} onClick={handleReturn} >
              <i className="offer-return-icon fas fa-chevron-circle-right fa-lg"></i>
            </div>
          }
        </div>


        {selectedGroupInfo && (
          <div className={`offer-card m-0 ${cardColors[selectedGroup % cardColors.length]}`}>
              <OfferCard
                id={selectedGroupInfo.id}
                name={selectedGroupInfo.name}
                imgURL={selectedGroupInfo.imgURL}
                groupDescription={selectedGroupInfo.groupDescription}
                
              />
          </div>
        )}
      </div>
    </div>
  );
  
};


export default OfferPageAccordion;
