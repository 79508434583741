const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

async function getStudent(id) {
  const response = await fetch(`${baseUrl}students/${id}`);
  if (response.ok) return response.json();
  throw response;
}

async function getStudents() {
  const response = await fetch(`${baseUrl}students`);
  if (response.ok) return response.json();
  throw response;
}

async function getStudentsForClient(clientId) {
  const response = await fetch(`${baseUrl}students?clientId=${clientId}`);
  if (response.ok) return response.json();
  throw response;
}

async function saveStudent(student) {
  const response = await fetch(`${baseUrl}students/${student.id || ""}`, {
    method: student.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(student),
  });
  if (response.ok) return response.json();
  throw response;
}

export default {
  getStudent,
  getStudentsForClient,
  getStudents,
  saveStudent,
};
