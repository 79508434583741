/* eslint-disable no-console */
import React, {Component, Fragment} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";
import Quiz from "./Quiz";
import Results from "./Results";
import shuffleQuestions from "../../helpers/shuffleQuestions";
import QUESTION_DATA from "../../data/QuizData";

class QuizApp extends Component {
  static propTypes = {
    totalQuestions: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const {totalQuestions} = this.props;
    const numberOfQuestions = Math.min(totalQuestions, QUESTION_DATA.length);
    const questions = shuffleQuestions(QUESTION_DATA).slice(0, numberOfQuestions);
    this.state = {
      questions,
      totalQuestions: numberOfQuestions,
      userAnswers: questions.map(() => ({tries: 0})),
      step: 1,
      readOnly: false,
      selectedAnswer: null
    };
  }

  onCompleteAction = () => {
    const {questions, userAnswers} = this.state;
    const {email} = this.props;
    const questionsAndAnswers = _.merge(questions, userAnswers);
    console.log("Wiadomość przygotowana do wysłania", questionsAndAnswers);
    const desc = this.formatMessageForEmail(questionsAndAnswers);
    const data = {email, desc};
    console.log("Quiz ukończony. Wysyłanie wiadomości", data);
    axios({
      method: "post",
      url: "https://8uyipnjdk3.execute-api.eu-west-1.amazonaws.com/v1/contact-us",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data,
    })
      .then((response) => {
        console.log("Wiadomość wysłana", response);
      })
      .catch(error => console.log("Błąd wysyłania wiadomości", error));
  };

  onAnswerSelected = (answerIndex) => {
    const {questions, step, userAnswers} = this.state;
    const currentQuestion = questions[step - 1];
    const selectedAnswer = answerIndex + 1;
    const isCorrect = currentQuestion.correctAnswer === selectedAnswer;

    userAnswers[step - 1] = {
      tries: currentQuestion.tries + 1,
      isCorrect,
      selectedAnswer
    };

    this.setState({
      userAnswers,
      selectedAnswer,
      readOnly: true
    });

    setTimeout(this.nextStep, 750);
  };

  nextStep = () => {
    const {step, totalQuestions} = this.state;

    const isQuizCompleted = step === totalQuestions;
    if (isQuizCompleted) {
      this.onCompleteAction();
    }

    this.setState({
      step: step + 1,
      readOnly: false,
      selectedAnswer: null
    });
  };


  restartQuiz = () => {
    const {totalQuestions} = this.props;
    this.setState({
      ...this.getInitialState(totalQuestions)
    });
  };

  formatMessageForEmail(questionsAndAnswers) {
    const {totalQuestions, email} = this.props;
    const correctAnswers = questionsAndAnswers.filter(q => q.isCorrect);
    const formattedQuestions = _(questionsAndAnswers)
      .map((q, i) => (
        {
          index: i + 1,
          question: q.question,
          selectedAnswer: q.answers[q.selectedAnswer - 1],
          isCorrect: q.isCorrect,
          comment: q.isCorrect ? ' jest poprawna.' : `, błąd poprawna odpowiedź to: ${q.answers[q.correctAnswer - 1]}`
        }))
      .map(q => `Pytanie ${q.index}. ${q.question} (Wybrana odpowiedź: "${q.selectedAnswer}" ${q.comment})`)
      .join('\n');

    return `Użytkownik ${email} ukończył test odpowiadając poprawnie na ${correctAnswers.length} pytań z ${totalQuestions}.\n\n${formattedQuestions}`;
  }


  render() {
    const {step, questions, userAnswers, totalQuestions, readOnly, selectedAnswer} = this.state;
    const quizIsFinished = step > totalQuestions;
    return (
      <Fragment>
        {!quizIsFinished &&
        (
          <Quiz
            step={step}
            questions={questions}
            totalQuestions={totalQuestions}
            onAnswerSelected={this.onAnswerSelected}
            selectedAnswer={selectedAnswer}
            readOnly={readOnly}
          />
        )
        }
        {quizIsFinished &&
        (
          <Results
            restartQuiz={this.restartQuiz}
            questions={questions}
            userAnswers={userAnswers}
          />
        )
        }
      </Fragment>
    );
  }
}

export default QuizApp;
