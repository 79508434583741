export default {
  teachers: [],
  clients: [],
  students: [],
  schoolClasses: [],
  classStudents: [],
  lessonRegister: [],
  books: [],
  user: { id: "guest", role: "GUEST" },
  apiCallsInProgress: 0,
  settings: {
    showArchivedClients: false,
    schoolClassesFilters: {
      search: "",
      dayOfWeek: "",
      classTypeId: "",
      startTime: "",
      endTime: "",
      teacherId: "",
      showOnlyNotRepeating: false,
      showOnlyRepeating: false,
      fromDate: "",
      tillDate: "",
      comment: false,
    },
  },
};
