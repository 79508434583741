import * as types from "./actionTypes";
import API from "../../services/SchoolClassService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadSchoolClassesSuccess(schoolClasses) {
  return { type: types.LOAD_SCHOOL_CLASSES_SUCCESS, schoolClasses };
}

export function createSchoolClassSuccess(schoolClass) {
  return { type: types.CREATE_SCHOOL_CLASS_SUCCESS, schoolClass };
}

export function updateSchoolClassSuccess(schoolClass) {
  return { type: types.UPDATE_SCHOOL_CLASS_SUCCESS, schoolClass };
}

export function deleteSchoolClassOptimistic(schoolClass) {
  return { type: types.DELETE_SCHOOL_CLASS_OPTIMISTIC, schoolClass };
}


export function saveSchoolClass(schoolClass) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveSchoolClass'));
    return API.saveSchoolClass(schoolClass)
      .then((savedSchoolClass) => {
        dispatch(
          schoolClass.id ? updateSchoolClassSuccess(savedSchoolClass) : createSchoolClassSuccess(savedSchoolClass)
        );
        return savedSchoolClass;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSchoolClasses() {
  return (dispatch) => {
    dispatch(beginApiCall('getSchoolClasses'));
    return API.getSchoolClasses()
      .then((schoolClasses) => {
        dispatch(loadSchoolClassesSuccess(schoolClasses));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
