import {STATUS, WEEKDAYS} from "./Const";

export const getById = (items, id) => items.find(item => item.id === id) || null;

export const getStudentsByClientId = (students, clientId) => students.filter(student => student.clientId === clientId);

export const hasNoErrors = errors => Object.keys(errors).length === 0;

export const hasErrors = errors => Object.keys(errors).length > 0;

export const calculateDisplayedErrors = (errors, touched, status) => Object.keys(errors)
  .map((key) => {
    if (touched[key] || status === STATUS.SUBMITTED) {
      return {[key]: errors[key]};
    }
    return {};
  })
  .reduce((p, c) => ({...p, ...c}), {});

export const timeStringToMinutes = (timeString) => {
  const m = timeString.match(/([0-9]?[0-9]) *:? *([0-9][0-9])/);
  if (!m || !m[1] || !m[2]) return null;
  return m[1] * 60 + m[2] * 1;
};

export const minutesToTimeString = minutes => `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(minutes % 60).padStart(2, '0')}`;

export const strToDate = (date) => {
  if (typeof date == 'string') {
    return new Date(date);
  }
  if (typeof date == 'object' && date instanceof Date) {
    return date;
  }
  throw new Error(`Unsupported type of date ${typeof date} ${date}`);
};

export const formatDate = date => date.toISOString().slice(0, 10);

export const weekNumber = date => date.getWeek();


export const formatDayOfWeek = (date) => {
  const foundDay = WEEKDAYS.find(weekday => weekday.day === date.getDay());
  if (foundDay) return foundDay.name;
  return '';
};

export const strToDayOfWeek = (dayOfWeek) => {
  if (typeof dayOfWeek == 'string') {
    let day = WEEKDAYS.find(value => value.id === dayOfWeek);
    if (!day) {
      day = WEEKDAYS.find(value => value.name === dayOfWeek);
    }
    if (!day) {
      throw new Error(`Must be name of day of the week. Unable to find day of ${dayOfWeek}`);
    }
    return day.day;
  }
  if (typeof dayOfWeek == 'number') {
    if (dayOfWeek < 0 || dayOfWeek > 6) throw new Error("dayOfWeek must be a number 0-6");
    return dayOfWeek;
  }
  throw new Error(`Unsupported type of dayOfWeek ${typeof dayOfWeek}`);
};

export const findDayOfWeek = dayOfWeek => WEEKDAYS.find(d => d.id === dayOfWeek);

const findFirstDayOfTheWeek = (fromDate, dayOfWeek) => {
  if (typeof dayOfWeek != "number" || dayOfWeek < 0 || dayOfWeek > 6) throw new Error("dayOfWeek must be a number 0-6");
  const date = new Date(fromDate);
  while (date.getDay() !== dayOfWeek) {
    date.setDate(date.getDate() + 1);
  }
  return date;
};

export const calculateDatesBetween = (from, till, dayOfWeek) => {
  const fromDate = strToDate(from);
  const tillDate = strToDate(till);
  const day = strToDayOfWeek(dayOfWeek);

  const firstDate = findFirstDayOfTheWeek(fromDate, day);
  const dates = [];

  let date = firstDate;
  while (date <= tillDate) {
    dates.push(date);
    date = new Date(date.getTime());
    date.setDate(date.getDate() + 7);
  }
  return dates;
};

const containsAllTokens = (item, tokens) => {
  const itemText = JSON.stringify(item).toLowerCase();
  const foundTokens = tokens.filter(token => itemText.includes(token));
  return foundTokens.length === tokens.length;
};

export const filterArray = (searchText, items) => {
  const cleanSearchText = searchText.trim().replace(/ +/gi, " ").toLowerCase();
  if (!cleanSearchText) return items;
  const tokens = cleanSearchText.split(" ");
  return items.filter(item => containsAllTokens(item, tokens));
};

/**
 * Returns the week number for the date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com
 * @param date the date to calculate week
 * @return int
 */
export const getWeek = (date) => {
  const dowOffset = 1; // week starts on Monday
  const newYear = new Date(date.getFullYear(), 0, 1);
  let day = newYear.getDay() - dowOffset; // the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  const daynum = Math.floor((date.getTime() - newYear.getTime() -
      (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
  let weeknum;
  // if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      const nYear = new Date(date.getFullYear() + 1, 0, 1);
      let nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      // if the next year starts before the middle of the week, it is week #1 of that year
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;
};


export const getStudentsInThisClass = (students, classStudents, schoolClassId) => {
  const classStudentsInThisClass = classStudents.filter(
    cs => cs.schoolClassId === schoolClassId
  );
  const studentIds = classStudentsInThisClass.map(cs => cs.studentId);
  return students
    .filter(s => studentIds.includes(s.id))
    .map(s => ({
      ...s,
      classStudent: classStudentsInThisClass.find(
        cs => cs.studentId === s.id
      ),
    }));
};
