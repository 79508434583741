const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

async function getSchoolClass(id) {
  const response = await fetch(`${baseUrl}classes/${id}`);
  if (response.ok) return response.json();
  throw response;
}

async function getSchoolClasses() {
  const response = await fetch(`${baseUrl}classes`);
  if (response.ok) return response.json();
  throw response;
}

async function saveSchoolClass(schoolClass) {
  const response = await fetch(`${baseUrl}classes/${schoolClass.id || ""}`, {
    method: schoolClass.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(schoolClass),
  });
  if (response.ok) return response.json();
  throw response;
}


async function getClassStudents() {
  const response = await fetch(`${baseUrl}classstudents`);
  if (response.ok) return response.json();
  throw response;
}

async function saveClassStudent(classStudent) {
  const response = await fetch(`${baseUrl}classstudents/${classStudent.id || ""}`, {
    method: classStudent.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(classStudent),
  });
  if (response.ok) return response.json();
  throw response;
}

async function deleteClassStudent(classStudent) {
  const response = await fetch(`${baseUrl}classstudents/${classStudent.id || ""}`, {
    method: "DELETE",
    headers: { "content-type": "application/json" },
  });
  if (response.ok) return response.json();
  throw response;
}

export default {
  getSchoolClass,
  getSchoolClasses,
  saveSchoolClass,
  getClassStudents,
  saveClassStudent,
  deleteClassStudent,
};
