export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_OUT = "SIGN_OUT";
export const CHECK_TOKEN = "CHECK_TOKEN";


export const LOAD_TEACHERS_SUCCESS = "LOAD_TEACHERS_SUCCESS";
export const CREATE_TEACHER_SUCCESS = "CREATE_TEACHER_SUCCESS";
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS";
export const DELETE_TEACHER_OPTIMISTIC = "DELETE_TEACHER_OPTIMISTIC";

export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const ARCHIVE_CLIENT_OPTIMISTIC = "ARCHIVE_CLIENT_OPTIMISTIC";
export const DELETE_CLIENT_OPTIMISTIC = "DELETE_CLIENT_OPTIMISTIC";

export const TOGGLE_CLIENT_LIST = "TOGGLE_CLIENT_LIST";
export const UPDATE_SCHOOL_CLASSES_FILTERS = "UPDATE_SCHOOL_CLASSES_FILTERS";

export const LOAD_STUDENTS_SUCCESS = "LOAD_STUDENTS_SUCCESS";
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const DELETE_STUDENT_OPTIMISTIC = "DELETE_STUDENT_OPTIMISTIC";

export const LOAD_SCHOOL_CLASSES_SUCCESS = "LOAD_SCHOOL_CLASSES_SUCCESS";
export const CREATE_SCHOOL_CLASS_SUCCESS = "CREATE_SCHOOL_CLASS_SUCCESS";
export const UPDATE_SCHOOL_CLASS_SUCCESS = "UPDATE_SCHOOL_CLASS_SUCCESS";
export const DELETE_SCHOOL_CLASS_OPTIMISTIC = "DELETE_SCHOOL_CLASS_OPTIMISTIC";

export const LOAD_LESSON_REGISTER_SUCCESS = "LOAD_LESSON_REGISTER_SUCCESS";
export const CREATE_LESSON_REGISTER_SUCCESS = "CREATE_LESSON_REGISTER_SUCCESS";
export const UPDATE_LESSON_REGISTER_SUCCESS = "UPDATE_LESSON_REGISTER_SUCCESS";
export const DELETE_LESSON_REGISTER_OPTIMISTIC = "DELETE_LESSON_REGISTER_OPTIMISTIC";

export const LOAD_BOOKS_SUCCESS = "LOAD_BOOKS_SUCCESS";
export const CREATE_BOOK_SUCCESS = "CREATE_BOOK_SUCCESS";
export const UPDATE_BOOK_SUCCESS = "UPDATE_BOOK_SUCCESS";
export const DELETE_BOOK_OPTIMISTIC = "DELETE_BOOK_OPTIMISTIC";

export const LOAD_CLASS_STUDENTS_SUCCESS = "LOAD_CLASS_STUDENTS_SUCCESS";
export const ADD_CLASS_STUDENT_SUCCESS = "ADD_CLASS_STUDENT_SUCCESS";
export const REMOVE_CLASS_STUDENT_SUCCESS = "REMOVE_CLASS_STUDENT_SUCCESS";
