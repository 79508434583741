import React from "react";
import { Helmet } from "react-helmet";
import "./quiz.scss";
import QuizMail from "./QuizMail";

const totalQuestions = 18;

class QuizPage extends React.Component {
  constructor() {
    super();
    this.state = {
      hideQuiz: true
    };
    this.onStart = this.onStart.bind(this);
  }

  onStart() {
    this.setState({ hideQuiz: false });
  }

  render() {
    const { hideQuiz } = this.state;
    return (
      <div id="parent" className="quiz-page row m-0 p-0 d-flex justify-content-center align-items-center">
        <Helmet>
          <title>Extra English - test</title>
        </Helmet>
        {hideQuiz ? (
          <div className="quiz-container col-lg-6 col-md-8 col-11 p-4 text-center">
            <div>
              <h1 className="quiz-title">Sprawdź swoją wiedzę</h1>
              <p>{totalQuestions} pytań z języka angielskiego</p>
              <div>
                <button type="button" className="quiz-button px-4 py-3" onClick={this.onStart}>
                  START
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-10">
            <div>
              <QuizMail totalQuestions={totalQuestions} />
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default QuizPage;
