import React from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactForm from "./ContactForm";

class ContactFormStateful extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      desc: "",
      errors: {},
      disabled: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    const { name, value } = event.target;
    const newState = { ...this.state };
    newState[name] = value;
    this.setState(newState);
  }

  onSubmit(event) {
    event.preventDefault();
    const { email, desc } = this.state;
    const data = { email, desc };
    if (!this.formIsValid()) {
      return;
    }
    this.state.disabled = true;
    axios({
      method: "post",
      url: "https://8uyipnjdk3.execute-api.eu-west-1.amazonaws.com/v1/contact-us",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data,
    })
      .then(() => {
        toast(`Wiadomość wysłana`);
        this.setState({ email: "", desc: "", disabled: false });
        this.state.desc = "";
        document.getElementById("contact-form").reset();
      })
      .catch(error => (toast.error(`Wiadomość nie wysłana: ${error}`)));
  }


  formIsValid() {
    let formIsValid = true;
    const errors = {};

    const { email, desc } = this.state;
    if (desc === "" || desc == null) {
      errors.desc = "Treść wiadomości nie może być pusta.";
      formIsValid = false;
    }
    if (email == null || email === "") {
      errors.email = "Adres email nie może być pusty.";
      formIsValid = false;
    } else if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      errors.email = "Nieprawidłowy adres email.";
      formIsValid = false;
    }

    this.setState({ errors });
    return formIsValid;
  }

  render() {
    const {
      desc, email, errors, disabled
    } = this.state;
    return (
      <div className="pt-4 pr-4 pl-4 pr-lg-2 pl-lg-4">
        <ContactForm
          desc={desc}
          email={email}
          errors={errors}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          disabled={disabled}
        />
      </div>
    );
  }
}


export default ContactFormStateful;
