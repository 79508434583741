import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import { history } from "../../redux/store/configureStore";

function isActive(route) {
  return route === history.location.pathname ? " active" : "";
}

const ManageNav = ({ items, signOut }) => {
  return (
    <> 
      <Navbar collapseOnSelect expand="lg" id="nav" className='manage-custom-nav fixed-top px-4 py-1 fixed-top shadow-sm '>
        <Navbar.Brand as={Link} to='/' className="p-1">
          <img
            src="../../resources/app/manage-logo.png"
            width="52px"
            height="52px"
            className="manage-custom-nav-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='mr-auto'>
            {items.map(item => (
              <Nav.Link as={Link} eventKey={item.name} key={item.name} to={item.route}  className='manage-custom-nav-link ml-1 my-0'>
                {item.name}
              </Nav.Link>
            ))}
          </Nav>
          <Nav>
            <Nav.Link onClick={signOut} className='manage-custom-nav-link ml-1 my-0'>
              <i className="fas fa-sign-in-alt fa-lg" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

ManageNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selected: PropTypes.bool,
    route: PropTypes.string,
    onClick: PropTypes.func,
  })).isRequired,
};

ManageNav.defaultProps = {
};

export default ManageNav;
