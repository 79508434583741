/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */

import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./AppliedRoute";
import HomePage from "../homepage/HomePage";
import NewsPage from "../NewsPage";
import NotFoundPage from "../NotFoundPage";
import QuizPage from "../quiz/QuizPage";
import LoginPage from "../LoginPage";
import SignupPage from "../signup/SignupPage";
import GalleryPage from "../GalleryPage";
import ManagementPage from "../manage/ManagementPage";

class Routes extends React.Component {
  render() {
    const { childProps } = this.props;
    return (
      <div>
        <Switch>
          <AppliedRoute
            path="/"
            component={HomePage}
            exact
            props={childProps}
          />
          <AppliedRoute
            path="/login"
            component={LoginPage}
            exact
            props={childProps}
          />
          <AppliedRoute
            path="/news"
            component={NewsPage}
            exact
            props={childProps}
          />
          <AppliedRoute
            path="/quiz"
            component={QuizPage}
            exact
            props={childProps}
          />
          <AppliedRoute
            path="/signup"
            component={SignupPage}
            exact
            props={childProps}
          />
          <AppliedRoute
            path="/gallery"
            component={GalleryPage}
            exact
            props={childProps}
          />
          <Route path="/manage" component={ManagementPage} props={childProps} />
          <Route component={NotFoundPage} exact props={childProps} />
        </Switch>
      </div>
    );
  }
}

Routes.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default Routes;
