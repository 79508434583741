
export const STATUS = {
  VIEW: "VIEW",
  IDLE: "IDLE",
  SUBMITTED: "SUBMITTED",
  SUBMITTING: "SUBMITTING",
  COMPLETED: "COMPLETED",
};

export const ROLES = {
  GUEST: "GUEST",
  TEACHER: "TEACHER",
  MANAGER: "MANAGER",
  ADMIN: "ADMIN",
  STUDENT: "STUDENT",
  CLIENT: "CLIENT",
};

export const CLASS_TYPES = [
  {
    id: "G45",
    name: "Grupowe 45 min",
    length: 45,
    minStudents: 3,
    maxStudents: 6,
  },
  {
    id: "G60",
    name: "Grupowe 60 min",
    length: 60,
    minStudents: 3,
    maxStudents: 6,
  },
  {
    id: "I45",
    name: "Indywidualne 45 min",
    length: 45,
    minStudents: 1,
    maxStudents: 1,
  },
  {
    id: "I60",
    name: "Indywidualne 60 min",
    length: 60,
    minStudents: 1,
    maxStudents: 1,
  },
  {
    id: "D45",
    name: "Dwuosobowe 45 min",
    length: 45,
    minStudents: 2,
    maxStudents: 2,
  },
  {
    id: "D60",
    name: "Dwuosobowe 60 min",
    length: 60,
    minStudents: 2,
    maxStudents: 2,
  },
  {
    id: "O45",
    name: "Online 45 min",
    length: 45,
    minStudents: 1,
    maxStudents: 1,
  },
  {
    id: "O60",
    name: "Online 60 min",
    length: 60,
    minStudents: 1,
    maxStudents: 1,
  },
];

export const WEEKDAYS = [
  {
    id: "MON",
    name: "Poniedziałek",
    short: "Pon.",
    day: 1,
  },
  {
    id: "TUE",
    name: "Wtorek",
    short: "Wto.",
    day: 2,
  },
  {
    id: "WED",
    name: "Środa",
    short: "Śr.",
    day: 3,
  },
  {
    id: "THU",
    name: "Czwartek",
    short: "Cz.",
    day: 4,
  },
  {
    id: "FRI",
    name: "Piątek",
    short: "Pt.",
    day: 5,
  },
  {
    id: "SAT",
    name: "Sobota",
    short: "Sob.",
    day: 6,
  },
  {
    id: "SUN",
    name: "Niedziela",
    short: "Nie.",
    day: 0,
  },
];

export const JWT_PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCUR/OJgMUbtHCmecnJZHpJ45Vf
FTFmZR5+Lc4osQeDBurySk8VG+TYBDiX5I7QDooeZoJZxAOuYEJznER3Up0CQZv+
fTJa0DF44qv3vgeSnoDcqnLb3FldcomrDJjPSRGi+91s2UoHZEdBoh0alApGa8nI
rOHFW4RClOZIJBqifQIDAQAB
-----END PUBLIC KEY-----
`;
