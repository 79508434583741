/* eslint-disable react/prefer-stateless-function */
import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import "../styles/styles.scss";
import PropTypes from 'prop-types';

class EventPopup extends React.Component {
  render() {
    //console.log(this.props.childProps.showAlert)
    return (
      <div>
        {this.props.childProps.showAlert && (
          <div id="overlay">
            <div className="card mb-3">
              <div className="card-header">
                <div className="redLine">
                  <div className="title">
                    <h2>Uwaga</h2>
                  </div>
                  <div className="line" style={{ marginTop: "15px", marginLeft: "2px", marginRight: "4px" }} />
                  <img
                    src="../../resources/offer/timetable/cross.ico"
                    title="angielski chyby angielski przeźmierowo angielski rokietnica angielski sady angielski smochowice angielski baranowo angielski kiekrz nauka angielskiego chyby nauka angielskiego przeźmierowo nauka angielskiego rokietnica nauka angielskiego sady nauka angielskiego smochowice nauka angielskiego baranowo nauka angielskiego kiekrz szkoła językowa chyby szkoła językowa przeźmierowo szkoła językowa rokietnica szkoła językowa sady szkoła językowa smochowice szkoła językowa baranowo szkoła językowa kiekrz"
                    alt="angielski chyby angielski  przeźmierowo angielski rokietnica angielski sady angielski smochowice angielski baranowo angielski kiekrz nauka angielskiego chyby nauka angielskiego przeźmierowo nauka angielskiego rokietnica nauka angielskiego sady nauka angielskiego smochowice nauka angielskiego baranowo nauka angielskiego kiekrz szkoła językowa chyby szkoła językowa przeźmierowo szkoła językowa rokietnica szkoła językowa sady szkoła językowa smochowice szkoła językowa baranowo szkoła językowa kiekrz"
                    onClick={() => { this.props.childProps.toggleAlert(); }}
                    className="deleteIcon float-right"
                    width="30"
                    height="30"
                    style={{ marginBottom: "5px" }}
                  />
                </div>
              </div>
              <div className="card-body" style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }}>
                <div style={{ textAlign: "justify" }}>
                  Uwaga! Od 1.9.2020 płatności przelewem proszimy kierować na nowe <Link onClick={() => { this.props.childProps.toggleAlert(); }} to="/payment">konto bankowe</Link>.
              </div>
              </div>
              <div className="card-footer" />
            </div>
          </div>
        )
        }
      </div>
    );
  }
}


export default EventPopup;

EventPopup.propTypes = {
  childProps: PropTypes.object.isRequired
};
