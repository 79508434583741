import * as types from "./actionTypes";
import API from "../../services/LessonRegisterService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadLessonRegistersSuccess(lessonRegisters) {
  return { type: types.LOAD_LESSON_REGISTER_SUCCESS, lessonRegisters };
}

export function createLessonRegisterSuccess(lessonRegister) {
  return { type: types.CREATE_LESSON_REGISTER_SUCCESS, lessonRegister };
}

export function updateLessonRegisterSuccess(lessonRegister) {
  return { type: types.UPDATE_LESSON_REGISTER_SUCCESS, lessonRegister };
}

export function deleteLessonRegisterOptimistic(lessonRegister) {
  return { type: types.DELETE_LESSON_REGISTER_OPTIMISTIC, lessonRegister };
}


export function saveLessonRegister(lessonRegister) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveLessonRegister'));
    return API.saveLessonRegister(lessonRegister)
      .then((savedLessonRegister) => {
        dispatch(
          lessonRegister.id ? updateLessonRegisterSuccess(savedLessonRegister) : createLessonRegisterSuccess(savedLessonRegister)
        );
        return savedLessonRegister;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadLessonRegisters() {
  return (dispatch) => {
    dispatch(beginApiCall('getLessonRegisters'));
    return API.getLessonRegisters()
      .then((lessonRegisters) => {
        dispatch(loadLessonRegistersSuccess(lessonRegisters));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteLessonRegister(lessonRegister) {
  return (dispatch) => {
    dispatch(beginApiCall('deleteLessonRegister'));
    return API.deleteLessonRegister(lessonRegister.id)
      .then(() => {
        dispatch(deleteLessonRegisterOptimistic(lessonRegister));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
