/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable quotes */
import React from "react";
import { Helmet } from "react-helmet";

const siteViewportHeight = window.innerHeight - 110;

const NewsPage = () => (
  <div className="blurred-background">
    <Helmet>
      <title>Extra English - newsy</title>
    </Helmet>
    <div className="container-fluid" width="100%" height="100%">
      <div className="pt-2 row content">
        <div className="col-xl-2" />
        <div className="col-xl-8">
          <div className="content-heading" align="center">
            {/*<div className="elfsight-app-26c6cf7d-f56c-4e25-aa59-a661b2c0d7d7"></div>*/}
            <iframe src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSuperExtraEnglish%2F&tabs=timeline&width=500&height=1024&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=341936085941438`}
                    width="500" height={`${(siteViewportHeight).toString()}px`} style={{ display: "block", margin: "0" }} scrolling="no" frameBorder="0" allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
            {/*<iframe*/}
            {/*  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSuperExtraEnglish&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"*/}
            {/*  width="800" height="5000" style="border:none;overflow:hidden" scrolling="no" frameBorder="0"*/}
            {/*  allowFullScreen="true"*/}
            {/*  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"*/}
            {/*/>*/}
            {/*<iframe src="https://www.juicer.io/api/feeds/extra-english/iframe" frameBorder="0" width="100%" height={`${siteViewportHeight.toString()}px`} style={{ display: "block", margin: "0" }} />*/}
          </div>
        </div>
        <div className="col-xl-2" />
      </div>
    </div>
  </div>
);
export default NewsPage;
