/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import ListItemSelector from "../common/ListItemSelector";
import Checkbox from "../common/Checkbox";
import SelectInput from "../common/SelectInput";
import Input from "../common/Input";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { ListGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LessonModal = ({
  index,
  lesson,
  display,
  lessonDetailsEx,
  studentsEx,
  studentsNotInThisClassEx,
  teachers,
  students,
  onDateChange,
  weekdays,
  getDayOfWeek,
  getNameById,
  onAddStudent,
  onUndoChanges,
  onDetailsChange,
  onAttendanceChange,
  onSubmit,
  onDelete,
  showModal,
  showLessonDetailsMenu,
  showAddStudentMenu,
  setShowModal,
  setShowLessonDetailsMenu,
  setShowAddStudentMenu,
}) => {
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowLessonDetailsMenu(false);
    setShowAddStudentMenu(false);
  };

  return (
    <>
      {display === "table" ? (
        <div className={`table-row row m-0 d-flex manage-text text-center cursor-pointer ${index % 2 !== 0 ? 'table-row-alternate' : ''} ${lesson.lessonRegister ? "visible-border-left" : "transparent-border-left"}`}>
          <div className='col-10 col-sm-11'>
            <div className='row d-flex align-items-center' onClick={handleShowModal}>
              <div className="col-sm-3 table-cell">{lessonDetailsEx.date}</div>
              <div className="col-sm-3 table-cell">{getDayOfWeek(lessonDetailsEx.dayOfWeek)}</div>
              <div className="col-sm-3 table-cell">{lessonDetailsEx.startTime}</div>
              <div className="col-sm-3 table-cell">{getNameById(teachers, lessonDetailsEx.teacherId)}</div>
            </div>
          </div>
          <div className="col-2 col-sm-1 p-0 d-flex align-items-center justify-content-center table-cell">
            {lessonDetailsEx.comment && (
              <OverlayTrigger overlay={<Tooltip>{lessonDetailsEx.comment}</Tooltip>}>
                <i className="fas fa-flag fa-lg" />
              </OverlayTrigger>
            )}
          </div>
        </div>
      ) : (
        <div className={`display-block cursor-pointer ${lesson.lessonRegister ? "dark-background" : "light-background"}`} onClick={handleShowModal}>
          {studentsEx.map(student => (
            <div key={student.id} className="student-name-display-block">{getNameById(students, student.id)}</div>
          ))}
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          {lesson.defaultLessonDetails.isRecurring && (
            <Button
              onClick={() => {
                setShowLessonDetailsMenu(!showLessonDetailsMenu);
                setShowAddStudentMenu(false);
              }}
              aria-expanded={showLessonDetailsMenu}
              className="col-12 mb-3"
              variant="outline-dark"
            >
              Zmień dane zajęć
            </Button>
          )}
          <Collapse in={showLessonDetailsMenu}>
            <div id="">
              <form id="group-form">
                <div className="form-row">
                  <div className="col-sm-7 col-6">
                    <SelectInput
                      label="Dzień tygodnia"
                      name="dayOfWeek"
                      value={lessonDetailsEx.dayOfWeek}
                      defaultOption="Wybierz dzień tygodnia"
                      options={weekdays.map(d => ({
                        value: d.id,
                        text: d.name,
                      }))}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-sm-5 col-6">
                    <Input type="date" label="Data" onBlur={() => { }} onChange={(e) => onDateChange(e)} name="date" value={lessonDetailsEx.date} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-3 col-4">
                    <Input label="Godzina" onBlur={() => { }} type="time" onChange={onDetailsChange} name="startTime" value={lessonDetailsEx.startTime} placeholder="Początek zajęć" />
                  </div>
                  <div className="col-sm-9 col-8">
                    <SelectInput
                      label="Nauczyciel"
                      onBlur={() => { }}
                      onChange={onDetailsChange}
                      name="teacherId"
                      value={lessonDetailsEx.teacherId}
                      defaultOption="Wybierz nauczyciela"
                      options={teachers.map(t => ({
                        value: t.id,
                        text: `${t.firstName} ${t.lastName}`,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <Input label="Uwagi" type="textarea" onBlur={() => { }} onChange={onDetailsChange} name="comment" value={lessonDetailsEx.comment} placeholder="Uwagi" />
                  </div>
                </div>
              </form>
            </div>
          </Collapse>
          {lesson.defaultLessonDetails.isRecurring && (
            <Button
              onClick={() => {
                setShowAddStudentMenu(!showAddStudentMenu);
                setShowLessonDetailsMenu(false);
              }}
              aria-controls="student-list"
              aria-expanded={showAddStudentMenu}
              className="col-12 mb-3"
              variant="outline-danger"
            >
              Dodaj ucznia
            </Button>
          )}
          <Collapse in={showAddStudentMenu}>
            <div id="student-list">
              <ListItemSelector label="" onSelect={onAddStudent} options={studentsNotInThisClassEx} />
            </div>
          </Collapse>
          <div className='modal-title'>Lista obecności</div>
          <ListGroup className="row d-flex" variant="horizontal">
            {studentsEx.map(student => (
              <ListGroup.Item key={student.id} className=' border-0 pb-0 col-sm-6'>
                <div >
                  <Checkbox name="isPresent" label={getNameById(students, student.id)} onChange={(e) => onAttendanceChange({ ...e, studentId: student.id })} isChecked={student.isPresent} onBlur={() => { }} />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn manage-button btn-success mr-2" onClick={onUndoChanges}><i className="fas fa-undo" /></button>
          <button type="button" className="btn manage-button btn-dark mr-auto ml-0" onClick={onDelete}><i className="fas fa-trash-alt fa-lg"></i></button>
          <div className="row d-flex">
            <button type="button" className="btn manage-button manage-teal-button mr-2" onClick={handleCloseModal}>Powrót</button>
            <button type="button" className="btn manage-button manage-red-button mr-2" onClick={onSubmit}>Zapisz</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

LessonModal.propTypes = {
  lesson: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  lessonDetailsEx: PropTypes.object.isRequired,
  studentsEx: PropTypes.array.isRequired,
  studentsNotInThisClassEx: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  weekdays: PropTypes.array.isRequired,
  getDayOfWeek: PropTypes.func.isRequired,
  getNameById: PropTypes.func.isRequired,
  onAddStudent: PropTypes.func.isRequired,
  onUndoChanges: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  onAttendanceChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  showLessonDetailsMenu: PropTypes.bool.isRequired,
  showAddStudentMenu: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowLessonDetailsMenu: PropTypes.func.isRequired,
  setShowAddStudentMenu: PropTypes.func.isRequired,
};

export default LessonModal;
