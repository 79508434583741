import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {history} from "../../redux/store/configureStore";
import ClientForm from "./ClientForm";
import ClientModalDelete from "./ClientModalDelete";
import {STATUS} from "../common/Const";
import {validatePerson} from "../common/Validations";
import StudentsList from "./StudentsList";
import * as clientActions from "../../redux/actions/clientActions";
import * as studentActions from "../../redux/actions/studentActions";

import {getById, getStudentsByClientId, hasErrors, hasNoErrors} from "../common/Helpers";

const Client = ({clients, loadClients, students, studentsOfClient, loadStudents, saveClient, archiveClient, deleteClient, ...props}) => {
  const [client, setClient] = useState({ ...props.client });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [status, setStatus] = useState(STATUS.VIEW);
  const [showInvoiceData, setShowInvoiceData] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    if (clients.length === 0) {
      loadClients().catch((error) => {
        alert(`Loading clients failed${error}`);
      });
    }
  }, []);

  useEffect(() => {
    setClient({...props.client});
    if (props.client.id) {
      setStatus(STATUS.VIEW);
    } else {
      setStatus(STATUS.IDLE);
    }
    setShowInvoiceData(!!props.client.invoiceData);
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.client]);

  useEffect(() => {
    if (students.length === 0) {
      loadStudents().catch((error) => {
        alert(`Loading students failed${error}`);
      });
    }
  }, []);

  const validate = () => {
    // eslint-disable-next-line no-shadow
    const errors = validatePerson(client);

    setErrors(errors);
    return hasNoErrors(errors);
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleCancel = () => {
    history.push('/manage/clients');    
  };

  const handleArchive = () => {
    archiveClient(client);
    setFormDisabled(!formDisabled);
    disabled = formDisabled
  }

  const handleDelete = () => {
    deleteClient(client);
    history.goBack();
  }

  const handleShowInvoiceClick = () => {
    setShowInvoiceData(true);
  };

  const handleEditStudent = (student) => {
    history.push(`/manage/student/${student.id}`);
  };

  const handleAddStudent = (client) => {
    history.push(`/manage/student/new?clientId=${client.id}`);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClient(prevClient => ({...prevClient, [name]: value}));
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    const isValid = validate();

    if (isValid) {
      saveClient(client)
        .then((savedClient) => {
          setStatus(STATUS.VIEW);
          setTouched({});
          if (!client.id) {
            history.push(`/manage/client/${savedClient.id}`);
          }
        })
        .catch((error) => {
          setErrors({...errors, saveError: error.message});
          setStatus(STATUS.SUBMITTED);
        });
    } else {
      setStatus(STATUS.SUBMITTED);
    }
  };

  const disabled = status === STATUS.SUBMITTING;
  const isModified = Object.keys(touched).filter(value => value).length > 0;
  return (
    <div className="mb-5">
      { hasErrors(errors) && status === STATUS.SUBMITTED && (
        <div role="alert" className="alert alert-danger">
          {Object.keys(errors).map(key => <div key={key}>{errors[key]}</div>)}
        </div>
      )}
      <div className="row m-0 p-0">
        <div className="col-12 p-0">
          <ClientForm
            firstName={client.firstName}
            lastName={client.lastName}
            email={client.email}
            mobile={client.mobile}
            dataProcessingAgreement={client.dataProcessingAgreement}
            sendNotifications={client.sendNotifications}
            hasContract={client.hasContract}
            invoiceData={client.invoiceData}
            address={client.address}
            contractDate={client.contractDate}
            notes={client.notes}
            touched={touched}
            errors={errors}
            onBlur={handleBlur}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onShowInvoiceClick={handleShowInvoiceClick}
            status={status}
            disabled={disabled}
            showInvoiceData={showInvoiceData}
            archived= {client.archived}
          />
        </div>
        <div className="col-lg-6 p-0">
        </div>
      </div>

      {client.id && (
        <React.Fragment>
          <div className="pt-3 row content">
            <div className="col-md-12" >
              <StudentsList students={studentsOfClient} onViewParent={() => {}} onEdit={handleEditStudent} />
            </div>
          </div>
        </React.Fragment>
      )}

      <div className="row content mt-3">
        {isModified && !client.archived && (
          <div className="col-md-block mb-2" >
            <button type="button" className="btn manage-button manage-red-button ml-2 " disabled={disabled} onClick={handleSubmit}>Zapisz</button>
          </div>
        )}
        {!isModified && client.id && !client.archived && (
          <div className="col-md-block mb-2">
            <button type="button" className="btn manage-button manage-red-button ml-2" onClick={() => handleAddStudent(client)}>Dodaj nowego ucznia</button>
          </div>
        )}
        <div className="col-md-block mr-auto pl-2 mb-2" >
          <button type="button" className="btn manage-button manage-teal-button"  onClick={handleCancel}>Powrót</button>
        </div>
        {
          client.archived
          ? <div className="col-md-block pr-2 mb-2">
              <button type="button" className="btn manage-button manage-red-button ml-2" onClick={() => setModalShow(true)}>Usuń</button>
              <button type="button" className="btn manage-button btn-success ml-2"  onClick={handleArchive}>Przywróć</button>
              <ClientModalDelete
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleClick={() => handleDelete()}
              />
            </div>
          : <button type="button" className="btn manage-button btn-success ml-2 mr-2 mb-2"  onClick={handleArchive}>Zarchiwizuj</button>
        }
      </div>
    </div>
  );
};

Client.propTypes = {
  clients: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  studentsOfClient: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  saveClient: PropTypes.func.isRequired,
  students: PropTypes.array.isRequired,
  loadStudents: PropTypes.func.isRequired,
};

const newClient = {};

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  let client =
    id === "new" || state.clients.length === 0
      ? newClient
      : getById(state.clients, id);
    if (!client) {
      client = newClient;
    }
    const studentsOfClient =
      !client.id || state.students.length === 0
      ? []
      : getStudentsByClientId(state.students, id);
  return {
    client,
    clients: state.clients,
    students: state.students,
    studentsOfClient,
  }; 
};

const mapDispatchToProps = dispatch => ({
  loadClients: () => dispatch(clientActions.loadClients()),
  saveClient: client => dispatch(clientActions.saveClient(client)),
  archiveClient: client => dispatch(clientActions.archiveClient(client)),
  deleteClient: client => dispatch(clientActions.deleteClient(client)),
  loadStudents: () => dispatch(studentActions.loadStudents()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Client);
