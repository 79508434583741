import React from "react";
import { hot } from "react-hot-loader";
import ReactGA from "react-ga";
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Helmet } from "react-helmet";
import Cookies from 'universal-cookie';
import HeaderNav from "./HeaderNav";
import PrivacyModal from "./privacypolicy/PrivacyModal";
import PrivacyPopup from "./privacypolicy/PrivacyPopup";
import EventPopup from "./EventPopup";
import Routes from "./routes/Routes";
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/scss/bootstrap.scss";
import {history} from "../redux/store/configureStore";
import {withRouter} from "react-router";
import * as teacherActions from "../redux/actions/teacherActions";
import {connect} from "react-redux";



const cookies = new Cookies();

class App extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      modalShow: false,
      showHeader: true,
      isOnMainPage: false,
      isOnManagementPage: false,
      showAlert: false,//!cookies.get('wasAlertRead'),
      isTokenAuthentic: false
    };
    this.logUserOut = this.logUserOut.bind(this);
    this.verifyGoogleToken = this.verifyGoogleToken.bind(this);
    this.verifyLogin = this.verifyLogin.bind(this);
    this.toggleHeader = this.toggleHeader.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.showPrivacyModal = this.showPrivacyModal.bind(this);
  }

  /*
  initializeReactGA() {
    ReactGA.initialize("UA-138348230-1");
    ReactGA.pageview("/");
  }
  */

  componentDidMount() {
    this.verifyLogin();
    this.setIsOnMainPage()
    this.setIsOnManagementPage()
    this.unlisten = history.listen( location =>  {
      this.setIsOnManagementPage()
      this.setIsOnMainPage()
    });
  }

  setIsOnMainPage() {
    const isOnMainPage = history.location.pathname === "/"
    this.setState({isOnMainPage});
  }

  setIsOnManagementPage() {
    const isOnManagementPage = history.location.pathname.startsWith("/manage")
    this.setState({ isOnManagementPage });
  }

  verifyLogin() {
    const GoogleLoginToken = cookies.get('GoogleLoginToken');
    const FacebookLoginToken = cookies.get('FacebookLoginToken');
    const { isTokenAuthentic } = this.state;
    if (!GoogleLoginToken == false || !FacebookLoginToken == false) {
      if (isTokenAuthentic == false) {
        switch (!!GoogleLoginToken) {
          case true:
            try {
              this.verifyGoogleToken();
            } catch (error) {
              toast.error(`Wystąpił błąd podczas logowania przez konto Google: ${error}`);
            }
            break;
          default:
            switch (!!FacebookLoginToken) {
              case true:
                try {
                  // this.verifyFacebookToken(FacebookLoginToken);
                } catch (error) {
                  toast.error(`Wystąpił błąd podczas logowania przez konto Facebook: ${error}`);
                  break;
                }
                break;
              default:
                break;
            }
        }
      }
    }
  }

  verifyGoogleToken() {
    const GoogleLoginToken = cookies.get('GoogleLoginToken');
    const data = { token: GoogleLoginToken };
    axios({
      method: "post",
      url: "https://vz1jynlncl.execute-api.eu-west-1.amazonaws.com/v2/VerifyJWToken",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data
    })
      .then((resp) => {
        const isTokenAuthentic = resp.data.authenticated;
        const userEmail = resp.data.email;
        this.setState({ isTokenAuthentic });
        if (isTokenAuthentic) {
          switch (userEmail) {
            case "marciniak.miko@gmail.com":
              toast("Witaj Mikołaj");
              cookies.set('userEmail', userEmail, { path: '/', maxAge: 3540 });
              break;
            case "marciniak.marek@gmail.com":
              cookies.set('userEmail', userEmail, { path: '/', maxAge: 3540 });
              toast("Witaj Marek");
              break;
            case "marciniak.alicja@gmail.com":
              toast("Witaj Alicja");
              cookies.set('userEmail', userEmail, { path: '/', maxAge: 3540 });
              break;
            default:
              // toast("Zostałeś zalogowany");
              break;
          }
        }
      })
      .catch(error => (toast.error(`Wystąpił błąd podczas logowania: ${error}`)));
  }

  logUserOut() {
    const { isTokenAuthentic } = this.state;
    if (isTokenAuthentic) {
      try {
        cookies.remove("GoogleLoginToken");
        cookies.remove("FacebookLoginToken");
        cookies.remove('userEmail');
        this.setState({ isTokenAuthentic: false });
        toast.success("Wylogowano");
      } catch (error) {
        toast.error(`Wystąpił błąd podczas wylogowania: ${(error)}`);
      }
    }
  }

  toggleHeader() {
    const { showHeader } = this.state;
    this.setState({ showHeader: !showHeader });
  }

  toggleAlert() {
    const { showAlert } = this.state;
    cookies.set('wasAlertRead', showAlert, { path: '/', maxAge: 3540 });
    this.setState({ showAlert: !showAlert });
  }

  showPrivacyModal() {
    this.setState({
      modalShow: true
    });
  }

  render() {
    const { modalShow, isTokenAuthentic, userEmail, showHeader, isOnMainPage, isOnManagementPage, showAlert } = this.state;
    const showHeaderApp = !isOnMainPage && showHeader;
    const childProps = {
      showAlert,
      isTokenAuthentic,
      userEmail,
      logUserOut: this.logUserOut,
      verifyLogin: this.verifyLogin,
      toggleHeader: this.toggleHeader,
      toggleAlert: this.toggleAlert
    };
    const modalClose = () => {
      this.setState({ modalShow: false });
    };
    return (
      <div>
        {modalShow && (
          <div id="myModal" className="modal fade" style={{ display: "none" }}>
            <div className="modalContainer">
              <PrivacyModal show={modalShow} onHide={modalClose} />
            </div>
          </div>
        )}
        <ToastContainer />
        {<PrivacyPopup showPrivacyModal={this.showPrivacyModal} />}
        {<EventPopup childProps={childProps} />}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="google-site-verification" content="XesrX7WoWYcb_oLanTXBlWcGeyNMt9b2gMHFB7Dep7E" />
          <meta name="viewport" content="width=device-width" maximum-scale="1" />
          <title>Extra English</title>
          <meta name="description" content="Najlepsza szkoła języka angielskiego w Baranowie, Extra English zaprasza na kursy nauki języka angielskiego." />
        </Helmet>
        <script src="https://unpkg.com/axios/dist/axios.min.js" />
        <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.2.1/js/bootstrap.min.js" />
        {showHeaderApp && !isOnManagementPage && <HeaderNav childProps={childProps} />}        
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default hot(module)(App);
