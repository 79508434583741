/* eslint-disable max-len */
import React from "react";

export const HomePageMusicNote = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="2.0"
    viewBox="0 0 192 192"
    width="90px"
    height="90px"
    x="1440"
    y="855"
  >
    <g id="original-icon" fill="#000000" stroke="none" strokeWidth="1.03">
      <g id="surface1">
        <path
          d="M157.5,16l-88,12c-8,0.8125 -13.5,8 -13.5,16v82.25c-3.6875,-0.65625 -7.6875,-0.65625 -12,0.25c-15.1875,4 -28,17.40625 -28,31c0,13.59375 12.8125,21.75 28,17.75c15.1875,-4 28,-17.65625 28,-31.25v-68.75l88,-11.75v54.25c-3.6875,-0.65625 -7.6875,-0.375 -12,0.75c-15.1875,4 -28,17.40625 -28,31c0,13.59375 12.8125,21.75 28,17.75c15.1875,-4 28,-17.65625 28,-31.25v-104c0,-9.59375 -8.90625,-16.8125 -18.5,-16z"
        />
      </g>
    </g>
  </svg>
);

export const HomePageMusicNoteHover = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="2.0"
    viewBox="0 0 192 192"
    width="90px"
    height="90px"
    x="1440"
    y="855"
  >
    <g
      fill="#000000"
      stroke="none"
      strokeWidth="1"
    >
      <path
        transform=" scale(1 1)"
        d="M35.12,182.045v3.91h-23.71v-3.91zM59.03,182.045v3.91h-23.7v-3.91zM82.94,182.045v3.91h-23.7v-3.91zM106.85,182.045v3.91h-23.7v-3.91zM130.77,182.045v3.91h-23.71v-3.91zM154.68,182.045v3.91h-23.71v-3.91zM178.59,182.045v3.91h-23.7v-3.91z"
      />
      
    </g>
  </svg>
);
