import * as types from "./actionTypes";
import API from "../../services/SchoolClassService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadClassStudentsSuccess(classStudents) {
  return { type: types.LOAD_CLASS_STUDENTS_SUCCESS, classStudents };
}

export function addClassStudentSuccess(classStudent) {
  return { type: types.ADD_CLASS_STUDENT_SUCCESS, classStudent };
}

export function removeClassStudentSuccess(classStudent) {
  return { type: types.REMOVE_CLASS_STUDENT_SUCCESS, classStudent };
}


export function loadClassStudents() {
  return (dispatch) => {
    dispatch(beginApiCall('getClassStudents'));
    return API.getClassStudents()
      .then((classStudents) => {
        dispatch(loadClassStudentsSuccess(classStudents));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function addClassStudent({schoolClassId, studentId, ...attr}) {
  const classStudent = {nid: `${schoolClassId}-${studentId}`, schoolClassId, studentId, ...attr};
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall());
    return API.saveClassStudent(classStudent)
      .then((savedClassStudent) => {
        dispatch(addClassStudentSuccess(savedClassStudent));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function removeClassStudent(classStudent) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('deleteClassStudent'));
    return API.deleteClassStudent(classStudent)
      .then(() => {
        dispatch(removeClassStudentSuccess(classStudent));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
