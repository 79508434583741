import * as types from "./actionTypes";

// eslint-disable-next-line import/prefer-default-export
export function toggleClientList() {
  return { type: types.TOGGLE_CLIENT_LIST};
}

export function updateSchoolClassesFilters(filters) {
  return { type: types.UPDATE_SCHOOL_CLASSES_FILTERS, payload: filters };
}
