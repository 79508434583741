import React from "react";
import PropTypes from "prop-types";
import Lesson from "./Lesson";

const LessonsList = ({
  scheduledLessons,
  students,
  teachers,
  onSubmit,
  deleteLessonRegister,
}) => {
  return (
    <div>
      <div className="row d-flex align-items-center mx-0 my-3">
        <h2 className="manage-title pb-0">Planowane zajęcia</h2>
        <div className="col-block col-md-auto ml-md-auto px-0"></div>
      </div>

      <div className="">
        <div className="row table-header manage-table-title text-center mx-0 d-flex align-items-center">
          <div className="col-10 col-sm-11">
            <div className="row d-flex align-items-center">
              <div className="col-sm-3 pl-sm-4 table-cell">Data</div>
              <div className="col-sm-3 table-cell">Dzień tygodnia</div>
              <div className="col-sm-3 table-cell">Godzina</div>
              <div className="col-sm-3 table-cell">Nauczyciel</div>
            </div>
          </div>
          <div className="col-2 col-sm-1 p-0 d-flex align-items-center justify-content-center">
            <i className="fas fa-flag fa-lg" />
          </div>
        </div>
        <div className="">
          {scheduledLessons.map((lesson, index) => (
            <div key={lesson.id}>
              <Lesson
                index={index}
                lesson={lesson}
                display={"table"}
                students={students}
                teachers={teachers}
                onSubmit={onSubmit}
                deleteLessonRegister={deleteLessonRegister}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

LessonsList.propTypes = {
  scheduledLessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string,
      schoolClassId: PropTypes.string,
      lessonRegister: PropTypes.object,
    })
  ).isRequired,
  students: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
};

export default LessonsList;
