/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import {STATUS} from "../common/Const";
import {calculateDisplayedErrors} from "../common/Helpers";

const BookForm = ({title, touched, errors, onBlur, onChange, onSubmit, onCancel,status}) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  const disabled = status === STATUS.SUBMITTING;
  return (
    <div>
      <h2 className="manage-title">Książka</h2>
      <form id="teacher-form" onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-md-12 mb-1  ">
            <Input label="Tytuł" onBlur={onBlur} onChange={onChange} name="title" value={title} error={err.title} placeholder="Tytuł" />
          </div>
        </div>

        <div className="pt-4 row mx-0">
          <div className="ml-auto col-md-block" >
            <button type="submit" className="btn manage-button manage-red-button btn-block " disabled={disabled}>{disabled ? 'Zapisywanie...' : 'Zapisz'}</button>
          </div>
          <div className="col-md-block pl-2 pr-2" >
            <button type="button" className="btn manage-button manage-teal-button btn-block " disabled={disabled} onClick={onCancel}>Powrót</button>
          </div>
        </div>
      </form>

    </div>
  );
};

BookForm.propTypes = {
  title: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

BookForm.defaultProps = {
  title: "",
};

export default BookForm;
