import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const teachersReducer = (state = initialState.teachers, action) => {
  switch (action.type) {
    case types.CREATE_TEACHER_SUCCESS:
      return [...state, { ...action.teacher }];
    case types.UPDATE_TEACHER_SUCCESS:
      return state.map(teacher => (teacher.id === action.teacher.id ? action.teacher : teacher));
    case types.LOAD_TEACHERS_SUCCESS:
      return action.teachers;
    case types.DELETE_TEACHER_OPTIMISTIC:
      return state.filter(teacher => teacher.id !== action.teacher.id);
    default:
      return state;
  }
};

export default teachersReducer;
