import React from "react";
import OfferDataPrices from "../../../data/OfferDataPrices";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const OfferPriceGrid = () => (
  <div className="row d-flex p-0 pl-1 m-0 justify-content-center align-items-center">
    <p className="page-title col-12 text-center">Nasza Oferta</p>
    {OfferDataPrices.map(({ title, prices, tooltipText }) => {
      const words = title.split(" ");
      const secondWord = words[1];
      return (
        <div key={title} className="col-sm-6 p-2">
          <div className="offer-price-panel p-4 d-flex flex-column justify-content-center align-items-center">
            <div className="row justify-content-center mb-3">
              <span className="offer-price-title text-center">
                <span>{words[0]}</span>
                {" "}
                {tooltipText && (
                  <OverlayTrigger
                    placement="top"
                    trigger={['hover', 'click']}
                    overlay={<Tooltip id="tooltip">{tooltipText}</Tooltip>}
                  >
                    <span className="tool-tip-trigger">{secondWord}</span>
                  </OverlayTrigger>
                )}
                {!tooltipText && <span>{secondWord}</span>}
              </span>
            </div>
            <div className="row justify-content-center">
              {prices.map(({ duration, price }, index) => (
                <div key={`${title}-${duration}-${price}`} className="col-12 row justify-content-around m-0 p-0 mt-2">
                  <div className="offer-price-text p-0 col-6 d-flex justify-content-center">
                    {duration.replace(/ /g, "\u00A0")}
                  </div>
                  <div className="offer-price-text p-0 col-6 d-flex justify-content-center">
                    {price.replace(/ /g, "\u00A0")}
                  </div>
                </div>
              ))}
              {prices.length === 1 && (
                <div className="col-12 row justify-content-around m-0 p-0 mt-2">
                  <div className="col-6 empty-row"></div> {/* Empty column */}
                  <div className="col-6 empty-row"></div> {/* Empty column */}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    })}
  </div>
);

export default OfferPriceGrid;
