/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

class AppliedRoute extends React.Component {
  render() {
    const { path } = this.props;
    const Rcomponent = this.props.component;
    const cProps = this.props.props;
    return (
      <div>
        <Route
          path={path}
          exact
          render={props => <Rcomponent {...props} {...cProps} />}
        />
      </div>
    );
  }
}

export default AppliedRoute;


AppliedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired
};
