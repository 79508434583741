/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";

const ContactForm = ({
  email, desc, errors, onSubmit, onChange, disabled
}) => {
  const isValid = error => (error ? "is-invalid" : "");
  return (
    <div>
      <h2 className="contact-page-title">Masz pytanie?</h2>
      <p>Zostaw wiadomość. Zawsze chętnie odpowiemy na wszelkie pytania.</p>
      <form id="contact-form" onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-lg-12 mb-1  ">
            <div className="input-group">
              <input
                type="text"
                name="email"
                className={`form-control ${isValid(errors.email)}`}
                value={email}
                onChange={onChange}
                id="email-input"
                placeholder="Adres email"
              />
              <div className="invalid-feedback">{errors.email}</div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12">
            <div className="form-group">
              <textarea
                type="text"
                name="desc"
                autoComplete="off"
                value={desc}
                onChange={onChange}
                id="description-input"
                rows="3"
                placeholder="Treść wiadomości"
                className={`form-control ${isValid(errors.desc)}`}
              />
              <div className="invalid-feedback">{errors.desc}</div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-danger font-weight-bold btn-block" style={{ background: "#c91f27" }} disabled={disabled}>Wyślij wiadomość</button>
      </form>
    </div>
  );
};

ContactForm.propTypes = {
  email: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};


export default ContactForm;
