import React from "react";
import Modal from 'react-bootstrap/Modal';

const ClientModalDelete = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-2">
        <div className="modal-title px-2 pt-2">Czy na pewno chcesz usunąć klienta?</div> 
        <div className="modal-body ">Ta akcja spowoduje że  dane klienta będą usunięte. Dane nie mogą być przywrócone.</div>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <button type="button" className="btn manage-button manage-red-button" onClick={props.handleClick}>Usuń</button>
        <button type="button" className="btn manage-button manage-teal-button" onClick={props.onHide}>Powrót</button>
      </Modal.Footer>
    </Modal>
  );
}
export default ClientModalDelete
