import React from 'react';
import PropTypes from 'prop-types';

const Input = ({ label, name, placeholder, error, value, type, className, readOnly, onBlur, onChange }) => {
  const isValid = e => (e ? "is-invalid" : "");
  return (
    <div className={`form-group ${className}`}>
      <span><strong>{label}</strong></span>
      <div className="input-group mt-1">
        {type === 'textarea' && (
          <textarea
            name={name}
            className={`form-control ${isValid(error)}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        )}
        {type !== 'textarea' && (
          <input
            type={type}
            name={name}
            className={`form-control ${isValid(error)}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
          />
        )}
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  label: "",
  placeholder: "",
  error: undefined,
  value: "",
  type: "text",
  className: "",
  readOnly: false,
  onBlur: () => {},
  onChange: () => {},
};

export default Input;
