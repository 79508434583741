import React, {useEffect, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import BookForm from "./BookForm";
import {STATUS} from "../common/Const";
import { history } from "../../redux/store/configureStore";
import * as bookActions from "../../redux/actions/bookActions";
import {mandatory, validateValues} from "../common/Validations";
import {hasErrors, hasNoErrors} from "../common/Helpers";

const validationConfig = {
  title: {
    label: "Tytuł",
    validate: [mandatory],
  },
};

function Book({books, loadBooks, saveBook, ...props}) {
  const [book, setBook] = useState({ ...props.book });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    if (books.length === 0) {
      loadBooks().catch((error) => {
        alert(`Loading books failed ${error}`);
      });
    } else {
      setBook({...props.book});
    }
  }, [props.book]);

  const validate = () => {
    // eslint-disable-next-line no-shadow
    const errors = validateValues(book, validationConfig);
    setErrors(errors);
    return hasNoErrors(errors);
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validate();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBook(prevBook => ({...prevBook, [name]: value,}));
    validate();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatus(STATUS.SUBMITTING);
    const isValid = validate();

    if (isValid) {
      saveBook(book)
        .then(() => {
          setStatus(STATUS.COMPLETED);
          history.goBack();
        })
        .catch((error) => {
          setErrors({...errors, saveError: error.message});
          setStatus(STATUS.SUBMITTED);
        });
    } else {
      setStatus(STATUS.SUBMITTED);
    }
  };

  return (
    <div className="mb-5">
      { hasErrors(errors) && status === STATUS.SUBMITTED && (
        <div role="alert" className="alert alert-danger">
            {Object.keys(errors).map(key => <div key={key}>{errors[key]}</div>)}
        </div>
      )}
      <BookForm
        title={book.title}
        touched={touched}
        errors={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        status={status}
      />
    </div>
  );
}

Book.propTypes = {
  books: PropTypes.array.isRequired,
  book: PropTypes.object.isRequired,
  loadBooks: PropTypes.func.isRequired,
  saveBook: PropTypes.func.isRequired,
};

const newBook = {};

const getBookById = (books, id) => books.find(book => book.id === id) || null;

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  const book =
    id === "new" || state.books.length === 0
      ? newBook
      : getBookById(state.books, id);

  return {
    book,
    books: state.books,
  };
};

const mapDispatchToProps = dispatch => ({
  loadBooks: () => dispatch(bookActions.loadBooks()),
  saveBook: book => dispatch(bookActions.saveBook(book)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Book);
