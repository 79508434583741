import * as types from "./actionTypes";
import API from "../../services/StudentService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadStudentsSuccess(students) {
  return { type: types.LOAD_STUDENTS_SUCCESS, students };
}

export function createStudentSuccess(student) {
  return { type: types.CREATE_STUDENT_SUCCESS, student };
}

export function updateStudentSuccess(student) {
  return { type: types.UPDATE_STUDENT_SUCCESS, student };
}

export function deleteStudentOptimistic(student) {
  return { type: types.DELETE_STUDENT_OPTIMISTIC, student };
}


export function saveStudent(student) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveStudent'));
    return API.saveStudent(student)
      .then((savedStudent) => {
        dispatch(
          student.id ? updateStudentSuccess(savedStudent) : createStudentSuccess(savedStudent)
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function loadStudents() {
  return (dispatch) => {
    dispatch(beginApiCall('getStudents'));
    return API.getStudents()
      .then((students) => {
        dispatch(loadStudentsSuccess(students));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
