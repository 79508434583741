/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SchoolClassesList = ({ schoolClasses, onEdit }) => {
  const [numToShow, setNumToShow] = useState(25);

  const handleShowMore = () => {
    setNumToShow(numToShow + 25);
  };

  const handleShowAll = () => {
    setNumToShow(schoolClasses.length);
  };

  return (
    <div>
      <div className="">
        <div className="row table-header manage-table-title text-center mx-0">
          <div className="col-10 col-md-11">
            <div className="row d-flex">
              <div className="col-md-3 table-cell">Nauczyciel</div>
              <div className="col-md-3 table-cell">Zazwyczaj</div>
              <div className="col-md-3  table-cell">Typ</div>
              <div className="col-md-3 table-cell">Zakres dat</div>
            </div>
          </div>
          <div className="col-2 col-md-1 p-0 d-flex align-items-center justify-content-center">
            <i className="fas fa-flag fa-lg" />
          </div>
        </div>

        <div className="">
          {schoolClasses.slice(0, numToShow).map((schoolClass, index) => (
            <div
              key={schoolClass.id}
              className={`table-row row d-flex manage-text text-center cursor-pointer mx-0 ${
                index % 2 !== 0 ? "table-row-alternate" : ""
              }`}
              onClick={() => onEdit(schoolClass)}
            >
              <div className="col-10 col-md-11">
                <div className="row d-flex">
                  <div className="col-md-3 table-cell">
                    {schoolClass.teacher}
                  </div>
                  <div className="col-md-3 table-cell">{schoolClass.when}</div>
                  <div className="col-md-3 table-cell">
                    {schoolClass.classType}
                  </div>
                  <div className="col-md-3 table-cell">
                    {schoolClass.dateRange}
                  </div>
                </div>
              </div>
              <div className="col-2 col-md-1 p-0 d-flex align-items-center justify-content-center table-cell">
                {schoolClass.comment && (
                  <OverlayTrigger
                    overlay={<Tooltip>{schoolClass.comment}</Tooltip>}
                  >
                    <i className="fas fa-flag fa-lg" />
                  </OverlayTrigger>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 row mx-0 d-flex justify-content-center">
        {numToShow < schoolClasses.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button manage-red-button btn-block"
              onClick={handleShowMore}
            >
              Pokaż więcej
            </button>
          </div>
        )}

        {numToShow < schoolClasses.length && (
            <div className="col-md-3 form-group">
              <button
                type="button"
                className="btn manage-button btn-dark btn-block"
                onClick={handleShowAll}
              >
                Pokaż wszystkie
              </button>
            </div>
        )}
      </div>
    </div>
)};

SchoolClassesList.propTypes = {
  schoolClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      classType: PropTypes.string,
      when: PropTypes.string,
      teacher: PropTypes.string,
      remarks: PropTypes.number,
    })
  ).isRequired,
  title: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default SchoolClassesList;
