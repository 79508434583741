/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

const TeachersList = ({teachers, onAdd, onEdit}) => {
  const [numToShow, setNumToShow] = useState(25);

  const handleShowMore = () => {
    setNumToShow(numToShow + 25);
  };

  const handleShowAll = () => {
    setNumToShow(teachers.length);
  };

  return (
    <div>
      <div className="row d-flex align-items-center  mx-0">
        <div className="manage-title mb-3 pb-0">Nauczyciele</div>
        <div className="col-md-5 col-lg-4 col-xl-3 ml-auto px-0 mb-3" >
          <button type="button" className="manage-button btn-success btn btn-block" onClick={onAdd}>Dodaj nowego nauczyciela</button>
        </div>
      </div>
      <div className="">
        <div className="row table-header manage-table-title text-center m-0">
          <div className="col-6 col-md-5">
            <div className="row d-flex">
              <div className="col-md-5 pr-0 pr-md-3 table-cell">Imie</div>
              <div className="col-md-7 pr-0 pr-md-3 table-cell">Nazwisko</div>
            </div>
          </div>
          <div className="col-6 col-md-7">
            <div className="row d-flex">
              <div className="col-md-6 table-cell">Telefon</div>
              <div className="col-md-6 table-cell">Email</div>
            </div>
          </div>

        </div>
        <div className="">
          {teachers.slice(0, numToShow).map((teacher, index) => (
            <div key={teacher.id} className={`table-row row m-0 d-flex manage-text text-center cursor-pointer ${index % 2 !== 0 ? 'table-row-alternate' : ''}`} onClick={() => onEdit(teacher)}>


              <div className="col-6 col-md-5">
                <div className="row d-flex">
                  <div className="col-md-5 table-cell">{teacher.firstName}</div>
                  <div className="col-md-7 table-cell">{teacher.lastName}</div>
                </div>
              </div>
              <div className="col-6 col-md-7">
                <div className="row d-flex">
                  <div className="col-md-6 table-cell">{teacher.mobile}</div>
                  <div className="col-md-6 table-cell">{teacher.email}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 row mx-0 d-flex justify-content-center">
        {numToShow < teachers.length && (
          <div className="col-md-3 form-group">
            <button
              type="button"
              className="btn manage-button manage-red-button btn-block"
              onClick={handleShowMore}
            >
              Pokaż więcej
            </button>
          </div>
        )}

        {numToShow < teachers.length && (
            <div className="col-md-3 form-group">
              <button
                type="button"
                className="btn manage-button btn-dark btn-block"
                onClick={handleShowAll}
              >
                Pokaż wszystkich
              </button>
            </div>
        )}
      </div>
    </div>
  );
};

TeachersList.propTypes = {
  teachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      mobile: PropTypes.string
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default TeachersList;
