import { combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import teachers from "./teachersReducer";
import clients from "./clientsReducer";
import students from "./studentsReducer";
import schoolClasses from "./schoolClassesReducer";
import classStudents from "./classStudentsReducer";
import books from "./booksReducer";
import settings from "./settingsReducer";
import user from "./userReducer";
import apiCallsInProgress from "./apiStatusReducer";
import lessonRegister from "./lessonRegisterReducer";

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  teachers,
  clients,
  students,
  schoolClasses,
  classStudents,
  lessonRegister,
  books,
  user,
  settings,
  apiCallsInProgress,
});

export default rootReducer;
