const quizQuestions = [{
  question: 'Oxford ___ a beautiful city.',
  answers: [
    'be',
    'are',
    'is',
  ],
  correctAnswer: 3,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'My brother’s  ___  accountant.',
  answers: [
    'any',
    'an',
    'a',
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},

{
  question: 'Dan ___  in a supermarket.',
  answers: [
    'work',
    'works',
    'is work',
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},

{
  question: 'I can  ___ Italian.',
  answers: [
    'speaking',
    'to speak',
    'speak',
  ],
  correctAnswer: 3,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},


{
  question: 'Jeff and Nancy ___  to watch a film tonight.',
  answers: [
    'are going',
    'is going',
    'going',
  ],
  correctAnswer: 1,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},


{
  question: 'Waiter: Anything else, sir? Customer: ___  ',
  answers: [
    'That’s all, thanks',
    'Here you are',
    'No, it isn’t',
    'You’re welcome',
  ],
  correctAnswer: 1,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},


{
  question: 'Jim ___  got a car.',
  questionType: 'text',
  answers: [
    "doesn't",
    "hasn't",
    'isn’t',
    "haven't",
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'What ___  Simon for his birthday?',
  questionType: 'text',
  answers: [
    'about buying',
    'shall we buy',
    'to buy',
    'let’s buy',
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'Excuse me. ___  got the time, please?',
  questionType: 'text',
  answers: [
    'You have',
    'Are you',
    'Do you have',
    'Have you',
  ],
  correctAnswer: 4,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: '___  on the internet is easy.',
  questionType: 'text',
  answers: [
    'Shop',
    'To shop',
    'For shopping',
    'Shopping',
  ],
  correctAnswer: 4,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'Alex loves giving presents. He’s very ___.',
  questionType: 'text',
  answers: [
    'selfish',
    'shy',
    'lazy',
    'generous',
  ],
  correctAnswer: 4,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'What’s ___ ? You look upset.',
  questionType: 'text',
  answers: [
    'bad',
    'wrong',
    'happen',
    'matter',
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'If you want to get fit, you ___  do more sport.',
  questionType: 'text',
  answers: [
    'will',
    'would',
    'should',
    'have',
  ],
  correctAnswer: 3,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'Let’s ___  our friends  today.',
  questionType: 'text',
  answers: [
    'visit',
    'to visit',
    'visiting',
  ],
  correctAnswer: 1,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'What time ___  get up in the morning?',
  questionType: 'text',
  answers: [
    'he',
    'do he',
    'does he',
  ],
  correctAnswer: 3,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'I ___  to Italy for my holiday last year.',
  questionType: 'text',
  answers: [
    'went',
    'go',
    'was',
  ],
  correctAnswer: 1,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: 'My grandfather was born eighty years ___ .',
  questionType: 'text',
  answers: [
    'since',
    'last',
    'ago',
    'when',
  ],
  correctAnswer: 3,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
{
  question: '___ is this T-shirt?',
  questionType: 'text',
  answers: [
    'How many',
    'How much',
    'How price',
  ],
  correctAnswer: 2,
  messageForCorrectAnswer: 'Poprawna odpowiedź.',
  messageForIncorrectAnswer: 'Błędna odpowiedź.',
  explanation: '[Wyjaśnienie]',
},
];


export default quizQuestions;
