import React, {useEffect} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import { history } from "../../redux/store/configureStore";
import TeachersList from "./TeachersList";
import * as teacherActions from "../../redux/actions/teacherActions";

function Teachers({ teachers, loadTeachers, ...props }) {

  useEffect(() => {
    if (teachers.length === 0) {
      loadTeachers()
        .catch((error) => {
          alert(`Loading teachers failed ${error}`);
        });
    }
  }, []);


  const handleEdit = (teacher) => {
    history.push(`/manage/teacher/${teacher.id}`);
  };

  const handleAdd = () => {
    history.push(`/manage/teacher/new`);
  };

  return (
    <div className="mb-5">
      <TeachersList
        teachers={teachers}
        onAdd={handleAdd}
        onEdit={handleEdit}
      />
    </div>
  );
}

Teachers.propTypes = {
  teachers: PropTypes.array.isRequired,
  loadTeachers: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return ({
    teachers: state.teachers,
  });
};

const mapDispatchToProps = dispatch => ({
  loadTeachers: () => dispatch(teacherActions.loadTeachers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
