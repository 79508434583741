import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS:
      return { ...action.user };
    case types.SIGN_OUT:
      return { ...initialState.user };
    default:
      return state;
  }
};

export default userReducer;
