const SchoolMethodContent = [
  {
    id: `OurMehtod`,
    title: `Nasza Metoda`,
    mainText: `Nauka przez zabawę jest doskonałym sposobem przyswajania
    nowych słów oraz zwrotów językowych. Piosenki, rymowanki i prace plastyczne to skuteczny
    sposób na naturalne uczenie języka, tak jak to robią rówieśnicy w krajach anglojęzycznych.`,
    bulletPoints: [
      `Szeroki wachlarz ćwiczeń uwzględnia wszystkie style uczenia się.`,
      `Program nauczania zawiera elementy edukacji międzykulturowej i obywatelskiej.`,
      `Sekcje międzyprzedmiotowe CLIL  w każdym rozdziale,
      oraz plakaty łączą naukę angielskiego z elementami innych przedmiotów.`,
    ],
    readMoreText: `Czytaj dalej`,
  },
  {
    id: `BritishCouncilPartnership`,
    title: `Partnerstwo z British Council`,
    mainText: `Miło nam poinformować, że nasza szkoła dołączyła do programu British Council Advantage.
    British Council jest instytucją reprezentującą Wielką Brytanię
    w zakresie współpracy kulturalnej i edukacyjnej. Organizuje brytyjskie egzaminy dla ponad
    trzech milionów ludzi na całym świecie, pomagając im uzyskać dostęp do niezawodnych kwalifikacji
    wspierających ich perspektywy zawodowe i edukacyjne.`,
    bulletPoints: [
      `Zestawy testów egzaminacyjnych z poprzednich lat`,
      `Dla kandydatów przystępujących do egzaminów B2 First, B2 First for Schools i C1 Advanced - bezpłatny dostęp do egzaminacyjnej platformy internetowej`,
      `Dla kandydatów przystępujących do IELTS – bezpłatny dostęp do kursu on-line`,
    ],
    readMoreText: `Powróć`,
  },
];

export default SchoolMethodContent;
