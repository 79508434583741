/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";
import { FacebookProvider, LoginButton } from 'react-facebook';
import GoogleLogin from 'react-google-login';
import Cookies from 'universal-cookie';
import jwt from 'jwt-simple';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cookies = new Cookies();

class LoginPage extends React.Component {
  handleResponse = (response) => {
    cookies.set('FacebookLoginToken', "response", { path: '/', maxAge: 3540 });
    // verifyLogin();
    console.log(response);
  }

  handleError = (error) => {
    console.log(response);
    //this.setState({ error });
  }
  render() {
    const { isTokenAuthentic, userEmail, verifyLogin, logUserOut } = this.props;

    const responseGoogleSuccess = (response) => {
      cookies.set('GoogleLoginToken', response.tokenId, { path: '/', maxAge: 3540 });
      verifyLogin();
    };

    const responseGoogleFail = (response) => {
      toast.error(response);
    };
    return (
      <div className="LoginContainer" style={{ paddingTop: "100px" }}>
        <div className="row">
          <div className="col-xl-3" />
          <div className="col-xl-6">
            <h2>Login</h2>
            {!isTokenAuthentic && <p>Nie jesteś zalogowany</p>}
            {isTokenAuthentic && <p>Jesteś już zalogowany</p>}

            <FacebookProvider appId="678151179319177">
              <LoginButton
                scope="email"
                onCompleted={this.handleResponse}
                onError={this.handleError}
              >
                <span>Login via Facebook</span>
              </LoginButton>
            </FacebookProvider>


            {/*
            <FacebookLogin
              appId="678151179319177"
              fields="name,email,picture"
              callback={responseFacebook}
            />
            */}
            <br />
            <br />
            <GoogleLogin
              clientId="360335876406-5groo79g0itt7jed7ai1tqu5fl08g73d.apps.googleusercontent.com"
              buttonText="Google"
              onSuccess={responseGoogleSuccess}
              onFailure={responseGoogleFail}
            />
            <br />
            <br />
            {isTokenAuthentic
              && (
                <button type="button" className="btn btn-danger" onClick={logUserOut}>Wyloguj</button>
              )
            }
          </div>
          <div className="col-xl-3" />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  isTokenAuthentic: PropTypes.bool.isRequired,
  logUserOut: PropTypes.func.isRequired,
  verifyLogin: PropTypes.func.isRequired
};

export default LoginPage;
