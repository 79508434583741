import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const lessonRegisterReducer = (state = initialState.lessonRegister, action) => {
  switch (action.type) {
    case types.CREATE_LESSON_REGISTER_SUCCESS:
      return [...state, { ...action.lessonRegister }];
    case types.UPDATE_LESSON_REGISTER_SUCCESS:
      return state.map(lessonRegister => (lessonRegister.id === action.lessonRegister.id ? action.lessonRegister : lessonRegister));
    case types.LOAD_LESSON_REGISTER_SUCCESS:
      return action.lessonRegisters;
    case types.DELETE_LESSON_REGISTER_OPTIMISTIC:
      return state.filter(lessonRegister => lessonRegister.id !== action.lessonRegister.id);
    default:
      return state;
  }
};

export default lessonRegisterReducer;
