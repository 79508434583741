import * as types from "./actionTypes";
import API from "../../services/BookService";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function loadBooksSuccess(books) {
  return { type: types.LOAD_BOOKS_SUCCESS, books };
}

export function createBookSuccess(book) {
  return { type: types.CREATE_BOOK_SUCCESS, book };
}

export function updateBookSuccess(book) {
  return { type: types.UPDATE_BOOK_SUCCESS, book };
}

export function deleteBookOptimistic(book) {
  return { type: types.DELETE_BOOK_OPTIMISTIC, book };
}


export function saveBook(book) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    dispatch(beginApiCall('saveBook'));
    return API.saveBook(book)
      .then((savedBook) => {
        dispatch(
          book.id ? updateBookSuccess(savedBook) : createBookSuccess(savedBook)
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}


export function loadBooks() {
  return (dispatch) => {
    dispatch(beginApiCall('getBooks'));
    return API.getBooks()
      .then((books) => {
        dispatch(loadBooksSuccess(books));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
