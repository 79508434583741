/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import Checkbox from "../common/Checkbox";
import {calculateDisplayedErrors} from "../common/Helpers";

const ClientForm = ({firstName, lastName, email, mobile, dataProcessingAgreement, sendNotifications, hasContract, invoiceData, touched, errors,
  showInvoiceData, contractDate, address, notes, onShowInvoiceClick, onBlur, onChange, onSubmit, disabled, status, archived }) => {
  const err = calculateDisplayedErrors(errors, touched, status);
  return (
    <div>
      <h2 className="manage-title">Klient</h2>
      <form id="client-form" onSubmit={onSubmit}>
        <fieldset disabled={archived}>
          <div className="form-row">
            <div className="col-md-6">
              <Input label="Imię" onBlur={onBlur} onChange={onChange} name="firstName" value={firstName} error={err.firstName} placeholder="Imię" />
            </div>
            <div className="col-md-6">
              <Input label="Nazwisko" onBlur={onBlur} onChange={onChange} name="lastName" value={lastName} error={err.lastName} placeholder="Nazwisko" />
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-6">
              <Input label="Email" onBlur={onBlur} onChange={onChange} name="email" value={email} error={err.email} placeholder="Email" />
            </div>
            <div className="col-md-6">
              <Input label="Nr telefonu" onBlur={onBlur} onChange={onChange} name="mobile" value={mobile} error={err.mobile} placeholder="Nr telefonu" />
            </div>
          </div>

          <div className="form-row  ">
            <div className="col-md-6">
              <Checkbox label="Zgoda RODO" onBlur={onBlur} onChange={onChange} name="dataProcessingAgreement" isChecked={dataProcessingAgreement} error={err.dataProcessingAgreement}/>
            </div>
            <div className="col-md-6">
              <Checkbox label="Wysyłaj powiadomienia" onBlur={onBlur} onChange={onChange} name="sendNotifications" isChecked={sendNotifications} error={err.sendNotifications}/>
            </div>
          </div>

          <div className="form-row  d-flex align-items-center">
            <div className="col-md-6">
              <Checkbox label="Podpisana umowa" onBlur={onBlur} onChange={onChange} name="hasContract" isChecked={hasContract} error={err.hasContract}/>
            </div>
            <div className="col-md-6">
              <Input type="date" label="Data umowy" onBlur={onBlur} onChange={onChange} name="contractDate" value={contractDate} error={err.contractDate} placeholder="Data umowy" />
            </div>
          </div>

          <div className="form-row ">
            <div className="col-md-12">
              <Input label="Adres" onBlur={onBlur} onChange={onChange} name="address" value={address} error={err.address} placeholder="Adres" />
            </div>
          </div>

          {!showInvoiceData && (
            <div className="row ">
              <div className="col-md-block ">
                <button type="button" className="btn btn-sm btn-link btn-block pl-3" disabled={disabled} onClick={onShowInvoiceClick}>Dodaj dane do faktury</button>
              </div>
            </div>
          )}
          {showInvoiceData && (
            <div className="form-row ">
              <div className="col-md-12 " style={{minheight: "5em"}}>
                <Input label="Dane do faktury" type="textarea" onBlur={onBlur} onChange={onChange} name="invoiceData" value={invoiceData} error={err.invoiceData} placeholder="Dane do faktury" />
              </div>
            </div>
          )}

          <div className="form-row ">
            <div className="col-md-12">
              <Input label="Uwagi" onBlur={onBlur} onChange={onChange} name="notes" value={notes} error={err.notes} placeholder="Uwagi" />
            </div>
          </div>
        </fieldset>
      </form>


    </div>
  );
};

ClientForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
  dataProcessingAgreement: PropTypes.bool,
  hasContract: PropTypes.bool,
  sendNotifications: PropTypes.bool,
  contractDate: PropTypes.string,
  address: PropTypes.string,
  notes: PropTypes.string,
  invoiceData: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onShowInvoiceClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  showInvoiceData: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

ClientForm.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  dataProcessingAgreement: false,
  hasContract: false,
  sendNotifications: false,
  contractDate: "",
  address: "",
  notes: "",
  invoiceData: "",
  disabled: false,
  archived: false
};

export default ClientForm;
