import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {history} from "../../redux/store/configureStore";
import LoginForm from "./LoginForm";
import {STATUS} from "../common/Const";
import {email, mandatory, validateValues} from "../common/Validations";
import * as userActions from "../../redux/actions/usersActions";
import {hasNoErrors} from "../common/Helpers";

const validationConfig = {
  username: {
    label: "Email",
    validate: [mandatory, email],
  },
  password: {
    label: "Hasło",
    validate: [mandatory],
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: { username: '', password: '' },
      errors: {},
      touched: {},
      status: STATUS.IDLE,
      saveError: null,
    };
    this.isCancelled = false;
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handleBlur = (event) => {
    const { name } = event.target;
    const { touched } = this.state;
    this.setState({ touched: { ...touched, [name]: true } });
    this.validate();
  }

  handleCancel = () => {
    history.goBack();
  }

  handleChange = (event) => {
    const {credentials} = this.state;
    const { name, value } = event.target;
    credentials[name] = value;
    this.setState({credentials});
    this.validate();
  }

  handleSubmit = (event) => {
    if (event) event.preventDefault();
    this.setState({ status: STATUS.SUBMITTING });
    const { credentials } = this.state;
    const { signIn } = this.props;
    const isValid = this.validate();
    if (isValid) {
      signIn({ id: credentials.username, password: credentials.password})
        .then(() => {
          if (!this.isCancelled) {
            this.setState({ status: STATUS.COMPLETED });
          }
        })
        .catch((error) => {
          this.setState({ errors: {saveError: error.message}, status: STATUS.SUBMITTED});
        });
    } else {
      this.setState({ status: STATUS.SUBMITTED });
    }
  }

  validate() {
    const { credentials } = this.state;
    const errors = validateValues(credentials, validationConfig);
    this.setState({ errors });
    return hasNoErrors(errors);
  }

  render() {
    const {credentials, touched, errors, status,saveError} = this.state;
    const isValid = hasNoErrors(errors);
    if (saveError) throw saveError;
    const disabled = status === STATUS.SUBMITTING;

    return (
      <div>
        { !isValid && status === STATUS.SUBMITTED && (
          <div role="alert" className="alert alert-danger">
            <p>Błędy na stronie:</p>
            <ul>
              {Object.keys(errors).map(key => <li key={key}>{errors[key]}</li>)}
            </ul>
          </div>
        )}
        <LoginForm
          username={credentials.username}
          password={credentials.password}
          touched={touched}
          errors={errors}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          status={status}
          disabled={disabled}
        />

      </div>
    );
  }
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  signIn: userActions.signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
