/* eslint-disable max-len */
import React, {  useState } from "react";
import SchoolMethodContent from "../../../data/SchoolMethodContent";
import seo_keywords from "../../../data/seo";


function SchoolPage() {
  const [readMore, setReadMore] = useState(false);
  const [fade, setFade] = useState(false);

  
  const handleToggle = () => {
    setFade(true)
    setTimeout(() => {
      setFade(false)
      setReadMore(!readMore);
    }, 500);
  };

  return (
    <>
      <div className="school-container py-3 p-sm-4 mx-md-2 my-4 mx-3">
        <div className="row d-flex justify-content-around align-items-center m-0 ">
          <div className="col-xl-5 col-11 p-0">
            {SchoolMethodContent.map((content) => (
              <div className={`school-card-text ${fade ? "fade-out" : "fade-in"}`} key={content.id}>
                {readMore ? (
                  content.id === 'BritishCouncilPartnership' && (
                    <>
                      <p className="panel-title">{content.title}</p>
                      <p className="">{content.mainText}</p>
                      <ul>
                        {content.bulletPoints.map((text, index) => (
                          <li key={`mc${index}`}>{text}</li>
                        ))}
                      </ul>
                      <p className="read-more-text" onClick={handleToggle}>
                        {content.readMoreText}
                      </p>
                    </>
                  )
                ) : (
                  content.id === 'OurMehtod' && (
                    <>
                      <p className="panel-title">{content.title}</p>
                      <p className="">{content.mainText}</p>
                      <ul>
                        {content.bulletPoints.map((text, index) => (
                          <li key={`mc${index}`}>{text}</li>
                        ))}
                      </ul>
                      <p className="read-more-text" onClick={handleToggle}>
                        {content.readMoreText}
                      </p>
                    </>
                  )
                )
              }
            </div>))}
          </div>
          
          <div className="col-xl-6 p-0 pt-2 pt-xl-0">
            <div className="row d-flex justify-content-center justify-content-xl-start m-0 p-0 pb-md-3">
              <img
                  src={"../../../resources/school/ourschool/dzieci_z_tablica.jpg"}
                  className={`col-md-5 col-sm-6 school-gallery-img img-fluid px-2 pb-2 p-sm-1 p-md-0 mr-md-3`}
                  draggable="false"
                  alt={seo_keywords}
                />
                <img
                  src={"../../../resources/school/ourschool/dziecko_z_pedzlem.jpg"}
                  className={`col-md-5 col-sm-6 school-gallery-img img-fluid px-2 pb-2 p-sm-1 p-md-0`}
                  draggable="false"
                  alt={seo_keywords}
                />
            </div>
            <div className="row d-flex justify-content-center justify-content-xl-end m-0 p-0">
              <img
                  src={"../../../resources/school/ourschool/mama_i_dzieci.jpg"}
                  className={`col-md-5 col-sm-6 school-gallery-img img-fluid px-2 pb-2 mr-md-3 p-sm-1 p-md-0`}
                  draggable="false"
                  alt={seo_keywords}
                />
                <img
                  src={"../../../resources/school/ourschool/dzieci_z_dyplomami.jpg"}
                  className={`col-md-5 col-sm-6 school-gallery-img img-fluid px-2 pb-2 p-sm-1 p-md-0`}
                  draggable="false"
                  alt={seo_keywords}
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default SchoolPage;