import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LessonWrapper from './LessonWrapper';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/sass/styles.scss';

const localizer = momentLocalizer(moment.tz.setDefault('Europe/Warsaw'));

const DashboardCalendar = ({
  scheduledClasses,
  students,
  teachers,
  onSubmit,
  deleteLessonRegister,
}) => {
  const events = scheduledClasses.flatMap((scheduledClass) => {
    return scheduledClass.scheduledLessons.map((scheduledLesson) => {
      const duration = parseInt(scheduledLesson.defaultLessonDetails.classTypeId.slice(1));
      const start = moment(
        scheduledLesson.lessonRegister
          ? `${scheduledLesson.lessonRegister.lessonDetails.date} ${scheduledLesson.lessonRegister.lessonDetails.startTime}`
          : `${scheduledLesson.defaultLessonDetails.date} ${scheduledLesson.defaultLessonDetails.startTime}`,
        'YYYY-MM-DD HH:mm'
      ).toDate();
      const end = moment(start).add(duration, 'minutes').toDate();
      return {
        id: scheduledLesson.id,
        title: `${scheduledLesson.defaultLessonDetails.classTypeId}`,
        start,
        end,
        scheduledLesson,
      };
    });
  });

  const handleResize = () => {
    if (window.innerWidth < 768) {
      return 'day';
    } else {
      return 'week';
    }
  };

  const currentRoundedTime = () => {
    const currentTime = new Date();
    const minutes = currentTime.getMinutes();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    currentTime.setMinutes(roundedMinutes);
    return currentTime;
  };

  const dayStart = moment().hours(8).minutes(0).toDate();
  const dayEnd = moment().hours(20).minutes(0).toDate();

  return (
    <div className="my-calendar">
      <Calendar
        localizer={localizer}
        events={events}
        views={['month', 'week', 'day']}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 750 }}
        min={dayStart}
        max={dayEnd}
        step={20}
        timeslots={3}
        defaultView={handleResize()}
        scrollToTime={currentRoundedTime()}
        components={{
          event: ({ event }) => (
            <LessonWrapper
              event={event}
              display="calendar-event"
              students={students}
              teachers={teachers}
              onSubmit={onSubmit}
              deleteLessonRegister={deleteLessonRegister}
            />
          ),
        }}
      />
    </div>
  );
};

DashboardCalendar.propTypes = {
  scheduledClasses: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  teachers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deleteLessonRegister: PropTypes.func.isRequired,
};

export default DashboardCalendar;
