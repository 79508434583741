const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

async function getLessonRegister(id) {
  const response = await fetch(`${baseUrl}lessonregister/${id}`);
  if (response.ok) return response.json();
  throw response;
}

async function getLessonRegisters() {
  const response = await fetch(`${baseUrl}lessonregister/`);
  if (response.ok) return response.json();
  throw response;
}

async function saveLessonRegister(lessonRegister) {
  const response = await fetch(`${baseUrl}lessonregister/${lessonRegister.id || ""}`, {
    method: lessonRegister.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify({...lessonRegister, id: lessonRegister.lessonId}),
  });
  if (response.ok) return response.json();
  throw response;
}

async function deleteLessonRegister(id) {
  const response = await fetch(`${baseUrl}lessonregister/${id}`, {
    method: 'DELETE',
    headers: { 'content-type': 'application/json' },
  });
  if (response.ok) return response.json();
  throw response;
}

export default {
  getLessonRegister,
  getLessonRegisters,
  saveLessonRegister,
  deleteLessonRegister,
};
