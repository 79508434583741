/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable quotes */
import React from "react";
import "../styles/styles.scss";
import { Helmet } from "react-helmet";

const NotFoundPage = () => (
  <div id="parent" className="blurred-background">
    <Helmet>
      <title>Extra English - błąd 404</title>
    </Helmet>
    <h1>
        Błąd 404 strona nie została znaleziona
    </h1>
  </div>
);

export default NotFoundPage;
