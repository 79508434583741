import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import seo_keywords from "../data/seo";

function HeaderNav({}) {
  const menuItems = [
    { name: "Szkoła", route: "/#SchoolPage" },
    { name: "Oferta", route: "/#OfferPage" },
    { name: "Kontakt i Płatność", route: "/#ContactPage" },
    { name: "Zapisy", route: "/signup" },
    { name: "Galeria", route: "/gallery" },
    { name: "Quiz English", route: "/quiz" },
    { name: "Newsy", route: "/news" },
  ];

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      id="nav"
      className="custom-nav  fixed-top px-4 py-1"
    >
      <Navbar.Brand as={Link} to="/" className="py-0">
        <img
          src="../resources/app/logo.ico"
          alt={seo_keywords}
          width="60px"
          height="60px"
          className="custom-nav-logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {menuItems.map((item) => (
            <Nav.Link
              as={Link}
              eventKey={item.name}
              key={item.name}
              to={item.route}
              className="custom-nav-link ml-1 my-0"
            >
              {item.name}
            </Nav.Link>
          ))}
        </Nav>
        <Nav>
          <Nav.Link
            as={Link}
            eventKey="1"
            to="/manage"
            className="custom-nav-link ml-1 mb-0"
          >
            <i className="fas fa-sign-in-alt fa-lg" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

HeaderNav.propTypes = {
  childProps: PropTypes.PropTypes.shape({
    isTokenAuthentic: PropTypes.bool.isRequired,
    logUserOut: PropTypes.func.isRequired,
  }),
};

export default HeaderNav;
