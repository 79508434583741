import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const settingsReducer = (state = initialState.settings, action) => {
  switch (action.type) {
    case types.TOGGLE_CLIENT_LIST:
      return { ...state, showArchivedClients: !state.showArchivedClients };
    case types.UPDATE_SCHOOL_CLASSES_FILTERS:
      return { ...state, schoolClassesFilters: action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
