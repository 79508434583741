import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { history } from "../../redux/store/configureStore";
import StudentsList from "./StudentsList";
import * as clientActions from "../../redux/actions/clientActions";
import * as studentActions from "../../redux/actions/studentActions";
import { getById, filterArray } from "../common/Helpers";
import Checkbox from "../common/Checkbox";

function Students({ clients, students, loadClients, loadStudents }) {
  const [search, setSearch] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [showArchivedStudents, setShowArchivedStudents] = useState(false);

  useEffect(() => {
    if (clients.length === 0) {
      loadClients().catch((error) => {
        alert(`Loading clients failed${error}`);
      });
    }

    if (students.length === 0) {
      loadStudents().catch((error) => {
        alert(`Loading students failed${error}`);
      });
    }
  }, []);

  useEffect(() => {
    const filtered = students.filter((student) => {
      const shouldShowArchived = showArchivedStudents ? student.archived : !student.archived;
      return !student.deleted && shouldShowArchived;
    });
    setFilteredStudents(filtered);
  }, [students, showArchivedStudents]);
  
  const handleEdit = (student) => {
    history.push(`/manage/student/${student.id}`);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleShowArchivedStudents = () => {
    setShowArchivedStudents(!showArchivedStudents);
  };

  const filteredStudentsArray = filterArray(search, filteredStudents);

  return (
    <div className="mb-5">
      <h2 className="manage-title col-block mb-0">Lista studentów</h2>
      <div className="row d-flex align-items-center mx-0">
        <input
          name="search"
          className="form-control form-group col-md-9 col-8 pr-2"
          value={search}
          onChange={handleSearchChange}
          placeholder="szukaj"
        />
        <div className="col-md-3 col-4">
          <Checkbox
            label="Archiwum"
            onChange={handleShowArchivedStudents}
            name="showArchivedStudents"
            isChecked={showArchivedStudents}
            textPosition="rightOnLarge"
          />
        </div>
      </div>
      <StudentsList
        students={filteredStudentsArray}
        onEdit={handleEdit}
      />
    </div>
  );
}

Students.propTypes = {
  clients: PropTypes.array.isRequired,
  students: PropTypes.array.isRequired,
  loadClients: PropTypes.func.isRequired,
  loadStudents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  students: state.students.filter((student) => !student.deleted),
});

const mapDispatchToProps = (dispatch) => ({
  loadClients: () => dispatch(clientActions.loadClients()),
  loadStudents: () => dispatch(studentActions.loadStudents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Students);
