/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useState } from "react";
import PropTypes from "prop-types";

const StudentsList = ({ students, onEdit, onRemove, onAddStudent }) => {
  const nameSurnameColClassname = onAddStudent ? "col-4 pt-1" : "col-6";
  const [numToShow, setNumToShow] = useState(25);

  const handleShowMore = () => {
    setNumToShow(numToShow + 25);
  };

  const handleShowAll = () => {
    setNumToShow(students.length);
  };

  return (
    <div>
      <div className="row table-header manage-table-title text-center mx-0">
        {onAddStudent && (
          <button type="button" className="btn btn-link col-2 pr-0" onClick={onAddStudent}>
            <i className="fas fa-plus fa-lg white-font" />
          </button>
        )}
        <div className={nameSurnameColClassname}>
          <div className="row">
            <div className="col-md-5 pr-0 pr-md-3 table-cell">Imie</div>
            <div className="col-md-7 pr-0 pr-md-3 table-cell">Nazwisko</div>
          </div>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-center table-cell">Informacje dodatkowe</div>
      </div>
      <div className="">
        {students.slice(0, numToShow).map((student, index) => (
          <div
            key={student.id}
            className={`table-row row d-flex manage-text text-center mx-0 ${onEdit && "cursor-pointer"} ${index % 2 !== 0 ? "table-row-alternate" : ""}`}
            onClick={onEdit ? () => onEdit(student) : null}
          >
            {onRemove && (
              <button type="button" className="btn btn-link col-2 pr-0" onClick={() => onRemove(student)}>
                <i className="fas fa-minus fa-lg teal-font" />
              </button>
            )}
            <div className={nameSurnameColClassname}>
              <div className="row d-flex">
                <div className="col-md-5 pr-0 pr-md-3 table-cell">{student.firstName}</div>
                <div className="col-md-7 pr-0 pr-md-3 table-cell">{student.lastName}</div>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center table-cell">{student.additionalInfo}</div>
          </div>
        ))}
      </div>

      <div className="pt-3 row mx-0 d-flex justify-content-center">
        {numToShow < students.length && (
          <div className="col-md-3 form-group">
            <button type="button" className="btn manage-button manage-red-button btn-block" onClick={handleShowMore}>
              Pokaż więcej
            </button>
          </div>
        )}

        {numToShow < students.length && (
          <div className="col-md-3 form-group">
            <button type="button" className="btn manage-button btn-dark btn-block" onClick={handleShowAll}>
              Pokaż wszystkich
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

StudentsList.propTypes = {
  title: PropTypes.string,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      clientId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      additionalInfo: PropTypes.string,
    })
  ).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onAddStudent: PropTypes.func,
};

StudentsList.defaultProps = {
  onEdit: null,
  onRemove: null,
  onAddStudent: null,
};

export default StudentsList;
