/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ClientsList = ({ clients, onEdit, showArchivedClients }) => {
  const [numToShow, setNumToShow] = useState(25);

  const handleShowMore = () => {
    setNumToShow(numToShow + 25);
  };

  const handleShowAll = () => {
    setNumToShow(clients.length);
  };

  const visibleClients = showArchivedClients ? clients : clients.filter((client) => !client.archived);

  return (
    <div>
      <div className="">
        <div className="row table-header manage-table-title text-center mx-0">
          <div className="col-5">
            <div className="row d-flex">
              <div className="col-md-5 pr-0 pr-md-3 table-cell">Imie</div>
              <div className="col-md-7 pr-0 pr-md-3 table-cell">Nazwisko</div>
            </div>
          </div>
          <div className="col-5 col-md-6">
            <div className="row d-flex">
              <div className="col-md-6 table-cell">Telefon</div>
              <div className="col-md-6 table-cell">Email</div>
            </div>
          </div>
          <div className="col-2 col-md-1 p-0 d-flex align-items-center justify-content-center">
            <i className="fas fa-flag fa-lg" />
          </div>
        </div>
        <div className="">
          {visibleClients.slice(0, numToShow).map((client, index) => (
            <div key={client.id}>
              <div
                className={`table-row row d-flex manage-text text-center cursor-pointer mx-0 ${
                  index % 2 !== 0 ? "table-row-alternate" : ""
                }`}
                onClick={() => onEdit(client)}
              >
                <div className="col-5">
                  <div className="row d-flex">
                    <div className="col-md-5 pr-0 pr-md-3 table-cell">
                      {client.firstName}
                    </div>
                    <div className="col-md-7 pr-0 pr-md-3 table-cell">
                      {client.lastName}
                    </div>
                  </div>
                </div>
                <div className="col-5 col-md-6">
                  <div className="row d-flex">
                    <div className="col-md-6 table-cell">{client.mobile}</div>
                    <div className="col-md-6 table-cell">{client.email}</div>
                  </div>
                </div>
                <div className="col-2 col-md-1 p-0 d-flex align-items-center justify-content-center table-cell">
                  {client.notes && (
                    <OverlayTrigger
                      overlay={<Tooltip>{client.notes}</Tooltip>}
                    >
                      <i className="fas fa-flag fa-lg" />
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-3 row d-flex justify-content-center mx-0">

          {numToShow < visibleClients.length && (
            <div className="col-md-3 form-group">
              <button
                type="button"
                className="btn manage-button manage-red-button btn-block"
                onClick={handleShowMore}
              >
                Pokaż więcej
              </button>
            </div>
          )}
          {numToShow < visibleClients.length && (
            <div className="col-md-3 form-group">
              <button
                type="button"
                className="btn manage-button btn-dark btn-block"
                onClick={handleShowAll}
              >
                Pokaż wszystkich
              </button>
            </div>
          )}
      </div>
    </div>

    
  );
};

ClientsList.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      mobile: PropTypes.string,
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ClientsList;
