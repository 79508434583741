const baseUrl = process.env.NODE_ENV === "production"
  ? "https://uyygv3wln6.execute-api.eu-west-1.amazonaws.com/default/"
  : "http://localhost:3001/";

async function getClient(id) {
  const response = await fetch(`${baseUrl}clients/${id}`);
  if (response.ok) return response.json();
  throw response;
}

async function getClients() {
  const response = await fetch(`${baseUrl}clients`);
  if (response.ok) return response.json();
  throw response;
}

async function saveClient(client) {
  const response = await fetch(`${baseUrl}clients/${client.id || ""}`, {
    method: client.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(client),
  });
  if (response.ok) return response.json();
  throw response;
}

export default {
  getClient,
  getClients,
  saveClient,
};
