const TeachersArray = [
  {
    id: 0,
    name: "Alicja Marciniak",
    imgURL: "../resources/school/portraits/AlicjaMarciniak2.jpg",
    aboutMeText: `Ukończyłam podypolomowe studia Dydaktyki i Nauczania Języka Angielskiego przy Wyższej Szkole
    Języków Obcych im.Samuela Linde w Poznaniu.

    Po studiach wyjechalam do Wielkiej Brytanii gdzie pracowalam poczatkowo jako pomocnik nauczyciela oraz w
    fundacji Aiming High, gdzie pomagalam dzieciom niepełnosprawnym fizycznie lub umysłowo. Ewentualnie zdobyłam
    kwalifikacje CELTA (angielski ekwiwalent nauczyciela dyplomowanego) zaoferowano mi pozycję EAL - tutor.

    W Wielkiej Brytanii zdobyłam równiez praktykę w tłumaczeniach pisemnych i ustnych z języka angielskiego na polski
    i vice versa; pracowałam dla policji, NHS (odpowiednik polskiego NFZ) i służb socjalnych; zdobyłam uprawnienia community
    language interpreter(tłumacz społecznościowy).

    Przez kolejne kilka lat pracowałam jako nauczyciel indywidualny wspierający naukę angielskiego dzieci i młodzieży z
    różnych krajów świata. W 2018 roku wróciłam do Polski i rozpoczęłam szkołę Extra English.`,
  },
  {
    id: 7,
    name: "Joy Emezuo",
    imgURL: "../../resources/school/portraits/JoyEmezuo.jpg",
    aboutMeText: `Nazywam się Joy Emezuo, jestem zmotywowaną, entuzjastyczną, zaradną i oddaną nauczycielką języka angielskiego oraz
    solidnie zaangażowaną w rozwój społeczny i akademicki każdego dziecka. Mam doświadczenie w nauczaniu indywidualnym / grupowym, a moim
    celem jest zachęcanie do twórczej ekspresji, rozwiązywania problemów i budowania poczucia własnej wartości uczniów za pomocą różnych stylów uczenia.

    My name is Joy Emezuo, I am a highly motivated, enthusiastic, resourceful and dedicated English Language instructor with a solid
    commitment to the social and academic growth and development of every child. I am experienced in one-on-one tutoring/ group instruction
    and my goal driven towards encouraging creative expression, problem solving and building of student self esteem with diverse learning styles.`,
  },
  {
    id: 1,
    name: "Jan Pęchorzewski",
    imgURL: "../../resources/school/portraits/JanekPechorzewskiSquare.jpg",
    aboutMeText: `Mam na imię Janek i jestem osobą przyjazną oraz kreatywną, posiadam także doświadczenie w pracy z dziećmi.

    Język angielski towarzyszy mi od zawsze. Pochłonął mnie on jednak podczas pierwszej wycieczki do Wielkiej Brytanii.
    Po tej wycieczce postanowiłem zagłębić się zarówno w kulturę oraz język tego kraju. Stawiam na rozwój zarówno moich podopiecznych jak i siebie.`,
  },
  {
    id: 2,
    name: "Wiktoria Cebulska",
    imgURL: "../../resources/school/portraits/WiktoriaCebulska.jpg",
    aboutMeText: `Jestem osoba pełna zaangażowania i uwielbiam próbować nowych rzeczy. Jestem niczym człowiek orkiestra -
    języki, taniec, podróżowanie - staram się łączyć wszystkie moje pasje ze sobą.

    Poprzez pracę w Extra English realizuje jedna z moich pasji i mogę przekazywać ją dalej. Naukę angielskiego rozpoczęłam
    od najmłodszych lat i został ze mną do teraz. Aktualnie studiuję etnolingwistyke, gdzie nie tylko szlifuje moje umiejętności w
    zakresie języka angielskiego, ale również uczę się chińskiego. Dzięki zdobytym umiejętnościom pokazuje dzieciom, że
    nauka angielskiego to nie tylko uczenie się na pamięć z podręcznika, ale przede wszystkim dobra zabawa.`,
  },
  {
    id: 3,
    name: "Kinga Świtała",
    imgURL: "../../resources/school/portraits/KingaSwitala.png",
    aboutMeText: `Jestem osobą otwartą na nowe znajomości oraz wyzwania, jakie przynosi każdy dzień. Jestem cierpliwa oraz
    wytrwała w dążeniu do zaplanowanych celów. Kocham podróżować, robić zdjęcia, poznawać nowe kultury, jedzenie oraz języki.

    Pierwsze zajęcia języka angielskiego miałam już w przedszkolu, i tak został ze mną do teraz. Brałam udział w programie
    Erasmus +, podczas którego studiowałam we Włoszech, zarówno w języku włoskim, jak i angielskim.

    Lubię pracować i otaczać się innymi ludźmi, ponieważ każdy z nas jest inny i od każdego można się czegoś nauczyć.`,
  },
  {
    id: 4,
    name: "Wiktoria Napierała",
    imgURL: "../../resources/school/portraits/WiktoriaNapierala1.jpg",
    aboutMeText: `Jestem osobą optymistyczną i lubiącą wyzwania. Uwielbiam pracować z dziećmi i do każdego z uczniów staram
    się podejść indywidualnie i dostosować się do ich potrzeb. Mam rodzeństwo w wieku przedszkolnym i szkolnym, więc kontakt
    z dziećmi to u mnie codziennośc.

    Naukę języka angielskiego rozpoczęłam już w pszedszkolu i kontynuuję ją cały czas, przecież człowiek uczy się całe życie.
    Aktualnie uczęszczam na studia filologiczne gdzie kontynuuję moją przygodę z językami. Uczę się również języka rosyjskiego.
    Praca w Extra English pozwala mi na łączenie przyjemnego z pożytecznym oraz nie tylko na naukę najmłodszych a również uczenie
    się czegoś od nich.`,
  },
];

export const ArchivedTeachers = [
  {
    id: 5,
    name: "Ania Mąkowska",
    imgURL: "../../resources/school/portraits/AniaMakowska.png",
    aboutMeText: `Jestem bardzo przyjazną i energetyczną osobą. Uwielbiam spędzać czas z dziećmi i przekazywać im swoją wiedzę.

    Języka angielskiego (poza edukacją w szkole i na prywatnych lekcjach) uczyłam się podczas wielu wyjazdów, wymian uczniowskich
    czy spotkań z osobami zza granicy. Nauka tego języka zawsze sprawiała mi przyjemność.

    Swoje doświadczenia przekładam na tworzenie zajęć w Extra English. Bardzo lubię wymyślać zabawy i gry, dzięki czemu lekcje są
    merytoryczne ale zarazem ciekawe.`,
  },
  {
    id: 6,
    name: "Faith Ndebelle",
    imgURL: "../../resources/school/portraits/FaithNdebelle.jpg",
    aboutMeText: `My name is Faith, and I come from Africa. I'm a native English speaker, love children, enjoy spending time with
    them, teaching them is a joy for me. I am very patient, and always eager to meet new people, learn more about their culture and
    make them feel loved and appreciated. I enjoy learning and teaching.

    Nazywam się Faith i pochodzę z Afryki. Jestem native speakerem języka angielskiego, kocham dzieci, lubię spędzać z nimi czas, uczenie
    ich jest dla mnie radością. Jestem bardzo cierpliwa i zawsze chętna do poznawania nowych ludzi, poznawania ich kultury i sprawiania,
    by czuli się kochani i doceniani. Lubię się uczyć i uczyć innych.`,
  },
  {
    id: 8,
    name: "Ashlyn Martin",
    imgURL: "../../resources/school/portraits/AshlynMartin.jpg",
    aboutMeText: `Cześć, nazywam się Ashlyn Martin i jestem native speakerką języka angielskiego oraz doświadczonym instruktorem TEFL (Teaching English as a Foreign Language).
    Skupiam się na interaktywnym nauczaniu skoncentrowanym na uczniu i pracuję z uczniami z całego świata, aby rozwijać ich umiejętności językowe, poprawiać
    umiejętności komunikacyjne i budować ich pewność siebie. Wykorzystuję kombinację angażujących ćwiczeń, rzeczywistych przykładów i spersonalizowanej informacji
    zwrotnej, pomagając moim uczniom osiągnąć ich cele językowe i odnieść sukces w dzisiejszym zglobalizowanym świecie.

    Hi there, my name is Ashlyn Martin, and I'm a native English speaker and experienced TEFL (Teaching English as a Foreign Language)
    instructor. With a focus on interactive, student-centered teaching, I work with students from all over the world to develop their language skills,
    improve their communication abilities, and build their confidence. Using a combination of engaging activities, real-world examples, and personalized
    feedback, I help my students to achieve their language goals and succeed in today's globalized world.`,
  },
];

export default TeachersArray;
