/* eslint-disable react/prefer-stateless-function */
import React from "react";
import OfferPriceGrid from "./OfferPriceGrid";
import OfferPageAccordion from "./OfferPageAccordion";

const OfferSection = () => (
  <div className="offer-block">
    <div className="row m-0 d-flex justify-content-center align-items-center py-4">
      <div className="col-xl-4 col-md-11 px-0 ml-xl-3 m-2">
        <OfferPriceGrid />
      </div>
      <div className="offer-accordion col-xl-6 col-md-11 mr-xl-3 m-2 mb-3">
        <OfferPageAccordion />
      </div>
    </div>
    <div className="row m-0 d-flex justify-content-center align-items-center py-4">
      <div className="col-xl-12"></div>
      <a href="/signup">
        <button
          className="btn btn-danger font-weight-bold btn-block"
          style={{ background: "#c91f27", fontSize: "1.5rem" }}
        >
          Zapisz się na zajęcia już dziś!
        </button>
      </a>
    </div>
  </div>
);

export default OfferSection;
