import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const schoolClassesReducer = (state = initialState.classStudents, action) => {
  switch (action.type) {
    case types.ADD_CLASS_STUDENT_SUCCESS:
      return [...state, { ...action.classStudent }];
    case types.LOAD_CLASS_STUDENTS_SUCCESS:
      return action.classStudents;
    case types.REMOVE_CLASS_STUDENT_SUCCESS:
      return state.filter(classStudents => classStudents.id !== action.classStudent.id);
    default:
      return state;
  }
};

export default schoolClassesReducer;
